import {
  Command,
  EditorState,
  TextSelection,
  Transaction,
} from "prosemirror-state";
import { schema } from "../../schema";
import { getParentNote } from "../../utils/find";
import getNode from "../../utils/getNode";

/** Indent or dedent a paragraph */
function dentParagraph(tr: Transaction, pos: number, n = 1) {
  const node = getNode(tr.doc, pos, schema.nodes.paragraph);
  if (n > 0) {
    tr.insertText("\t".repeat(n), pos + 1);
  } else if (n < 0 && node.textContent.startsWith("\t")) {
    const ntabs = (node.textContent.match(/^(\t*)/)?.[0] || []).length;
    const ntabsToDedent = Math.min(-n, ntabs);
    tr.delete(pos + 1, pos + 1 + ntabsToDedent);
  }
}

/**
 * Indent or dedent inside codeblock
 *
 * @param tr Transaction
 * @param selStart Selection start in codeblock
 * @param selEnd Selection end in codeblock
 * @param dir Whether to indent or dedent
 */
function dentCodeBlock(
  tr: Transaction,
  selStart: number,
  selEnd: number,
  n = 1,
) {
  if (n === 0) return;
  const rpos = tr.doc.resolve(selStart);
  const codeBlock = rpos.parent;
  if (codeBlock.type !== schema.nodes.codeblock) {
    throw new Error("Not a codeblock");
  }
  if (codeBlock !== tr.doc.resolve(selEnd).parent) {
    throw new Error("Selection not in same codeblock");
  }
  let offset = 0;
  let lineStart = rpos.before() + 1;
  codeBlock.textContent.split("\n").forEach((line) => {
    const lineEnd = lineStart + line.length + 1;
    if (
      (selStart >= lineStart && selStart < lineEnd) || // first line
      (lineStart >= selStart && lineStart <= selEnd) || // between line
      (selEnd >= lineStart && selEnd < lineEnd) // last lines
    ) {
      if (n > 0) {
        tr.insertText("\t".repeat(n), lineStart + offset);
        offset += n;
      } else if (line.startsWith("\t")) {
        // count number of tabs
        const ntabs = (line.match(/^(\t*)/)?.[0] || []).length;
        const ntabsToDedent = Math.min(-n, ntabs);
        tr.delete(lineStart + offset, lineStart + offset + ntabsToDedent);
        offset -= ntabsToDedent;
      }
    }
    lineStart = lineEnd;
  });
}

/** Indent or dedent a list item */
function dentListItem(tr: Transaction, pos: number, n = 1) {
  const node = getNode(tr.doc, pos, schema.nodes.listItem);
  if (node.attrs.depth === 0 && n < 0) return;
  tr.setNodeMarkup(pos, undefined, {
    ...node.attrs,
    depth: Math.max(0, node.attrs.depth + n),
  });
}

/** Create transaction which indents or dedents selection */
function trDent(state: EditorState, n = 1): Transaction | null {
  const tr = state.tr;
  const { $from, $to, $anchor, $head } = tr.selection;
  const [, notePos] = getParentNote(tr.doc, $from.pos);
  if (notePos === null) return null;
  let multipleNotes = false;
  tr.doc.nodesBetween($from.pos, $to.pos, (node, posOld) => {
    const pos = tr.mapping.map(posOld);
    // Skip nodes until we're inside the note the selection is in
    if (pos <= notePos) return;
    // Skip all notes after the first one
    if (multipleNotes || node.type === schema.nodes.note) {
      multipleNotes = true;
      return false;
    }
    // Skip expanded references
    if (node.type === schema.nodes.expandedReference) {
      return false;
    }
    // Indent/dedent the node
    if (node.type === schema.nodes.listItem) {
      dentListItem(tr, pos, n);
      // don't descend because we don't want to indent/dedent it's children
      return false;
    } else if (node.type === schema.nodes.paragraph) {
      dentParagraph(tr, pos, n);
    } else if (node.type === schema.nodes.codeblock) {
      const posStart = Math.max($from.pos, pos + 1);
      const posEnd = Math.min($to.pos, pos + node.nodeSize - 1);
      dentCodeBlock(tr, posStart, posEnd, n);
    }
  });
  // If the selection is across multiple notes, do nothing
  if (multipleNotes) return null;
  const sel = new TextSelection(
    tr.doc.resolve(tr.mapping.map($anchor.pos)),
    tr.doc.resolve(tr.mapping.map($head.pos)),
  );
  tr.setSelection(sel);
  return tr;
}

/**
 * Indent selection.
 * Skips content inside expanded references.
 * If the selection is across multiple notes, do nothing.
 */
export const indent: Command = (state, dispatch) => {
  if (!dispatch) return false;
  const tr = trDent(state, 1);
  if (!tr) return false;
  dispatch(tr);
  return true;
};

/**
 * Dedent selection.
 * Skips content inside expanded references.
 * If the selection is across multiple notes, do nothing.
 */
export const dedent: Command = (state, dispatch) => {
  if (!dispatch) return false;
  const tr = trDent(state, -1);
  if (!tr) return false;
  dispatch(tr);
  return true;
};
