import { EditorState, Transaction } from "prosemirror-state";
import { isDev } from "../utils/environment";

const transactionToStack = new WeakMap<Transaction, Error>();

export function getTransactionOrigin(tr: Transaction) {
  return transactionToStack.get(tr);
}

if (isDev) {
  const oldProto = EditorState.prototype;
  const desc = Object.getOwnPropertyDescriptor(oldProto, "tr");

  Object.defineProperty(EditorState.prototype, "tr", {
    get: function (...args) {
      const newTransaction = desc?.get?.call(this, ...args);
      transactionToStack.set(newTransaction, new Error());
      return newTransaction;
    },
  });
}
