import { Note } from "../../model/types";

/**
 * A note that can be mass imported needs to follow this interface
 *
 * Source is the file format, or the application such as Salesforce.
 * Batch is the file name, or the account number.
 * ForeignId is the primary key of the foreign system. It needs to be as stable as possible.
 *
 */
export type ImportableNote = Partial<Note> & {
  importSource: string;
  importBatch: string;
  importForeignId: string;
};

/**
 * Checks if a list of notes already contains an importable note.
 */
function containsNote(importableNote: ImportableNote, notes: Note[]) {
  return notes.some(
    (note) =>
      note.importSource === importableNote.importSource &&
      // note.importBatch === importableNote.importBatch &&
      note.importForeignId === importableNote.importForeignId,
  );
}

/**
 * Deduplicates some importable notes against a reference list of notes.
 *
 * It doesnt deduplicate the importable notes between themselves.
 *
 * @param notesToDeduplicate
 * @param referenceNotes
 * @returns
 */
export function deduplicate(
  notesToDeduplicate: ImportableNote[],
  referenceNotes: Note[],
): ImportableNote[] {
  return notesToDeduplicate.filter(
    (note) => !containsNote(note, referenceNotes),
  );
}
