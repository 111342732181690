import { DOMParser, ResolvedPos, Schema } from "prosemirror-model";

/** A function to parse text from the clipboard into a document slice.
 * Called after transformPastedText. The default behavior is to split the
 * text into lines, wrap them in <p> tags, and call clipboardParser on it.
 * The plain flag will be true when the text is pasted as plain text.
 */
export const clipboardTextParser =
  (schema: Schema) => (text: string, $context: ResolvedPos, plain: boolean) => {
    const dom = document.createElement("div");
    // encode all html elements
    const encodedText = text.replace(/[\u00A0-\u9999<>&]/gim, (i) => {
      return "&#" + i.charCodeAt(0) + ";";
    });
    const linebreakRegExp = /(\r\n|\n){2}/g;
    const brokenUpText = encodedText.replace(linebreakRegExp, "</p><p>");
    // replace all new lines with line breaks
    dom.innerHTML = brokenUpText.replace(/(\r\n|\n)/g, "<br />");
    dom.innerHTML = dom.innerHTML.replace(/<br ?\/?>/g, "<p>");
    const parser: DOMParser = DOMParser.fromSchema(schema);
    const parsed = parser.parseSlice(dom, {
      preserveWhitespace: true,
      context: $context,
    });
    return parsed;
  };
