import { Command } from "prosemirror-state";
import { getParentNote } from "../../utils/find";

/**
 * Delete the top note on backspace if it's empty.
 */
export const deleteEmptyTopNoteOnBackspace: Command = (state, dispatch) => {
  if (!dispatch) return false;
  const [note, pos] = getParentNote(state.doc, state.selection.from);
  const firstNote = state.doc.child(0);
  if (!note || note !== firstNote) return false;
  if (note.textContent.length > 0) return false;
  dispatch(state.tr.deleteRange(pos, pos + note.nodeSize));
  return true;
};
