import { folderList, cache, noteList } from "../services";
import { loadModel } from "./loadModel";
import {
  ClientToSWMessage,
  SWToClientMessage,
} from "../../service-worker/message/swMessage";
import { debug } from "debug";
import type { DebouncedFunc } from "lodash";
import throttle from "lodash.throttle";
import { Message } from "../../modal/messageAtom";
import { OtherSyncStats } from "./syncStatus/SyncStatus";
import { displayToUser, isErrorCode } from "../../utils/error";
import * as Sentry from "@sentry/nextjs";
import { SyncErrors } from "../../service-worker/message/errorCodes";

type Checkpoint = number;

export const loadFromCache = async (): Promise<Checkpoint> => {
  const cachedData = await cache.load();
  if (cachedData.isFresh) {
    const notes = Object.values(cachedData.notes.data);
    const folders = Object.values(cachedData.folders.data);
    debug("sync-main")("importing data from cache...", {
      notes: notes.length,
      folders: folders.length,
    });
    loadModel(notes, folders);
  }
  return cachedData?.metadata?.data?.checkpoint ?? 0;
};

export const handleSwMessage =
  (
    refreshEditor: DebouncedFunc<() => void>,
    setErrorMessage: (errorMessage: Message) => void,
    markDataAsLoaded: () => void,
    refreshAccessToken: () => Promise<void>,
    setOtherSyncStatus: (s: OtherSyncStats) => void,
  ) =>
  async (e: MessageEvent<SWToClientMessage>) => {
    if ((e.data as any).meta === "workbox-broadcast-update") {
      setErrorMessage({
        type: "ok",
        content:
          "A new version of the app is available, <a onclick='window.reload()'>please reload</a>",
      });
    }

    if (e.data.type === "get-status-response") {
      setOtherSyncStatus(e.data.syncStats);
    }
    if (e.data.type === "upsert") {
      const { mapType } = e.data;
      e.data.elements.forEach((swElement) => {
        const localElement = noteList.get(swElement.id);
        const isDirty =
          localElement && localElement.masterRev !== localElement.localRev;
        if (!localElement || !isDirty) {
          // we accept the edit from the sw,
          // and cancel replace the content in the tab
          if (mapType === "note") {
            noteList.upsert({
              ...swElement,
              masterRev: swElement.localRev,
            });
          } else {
            folderList.upsert(swElement, false);
          }
          refreshEditor();
        } else {
          // acknowledgement, we only update the master rev, as this is our edit
          // that the service worker is sending back to us
          //
          // /!\ we are optimistic in the sense that:
          // if we have a conflict between 2 tabs,
          // we may be receiving a masterRev for the note in a different state than ours, and we will cancel and replace
          // this edit later, instead of failing.
          if (mapType === "note") {
            noteList.ack(swElement.id, swElement.localRev);
          } else {
            folderList.ack(swElement.id, swElement.localRev);
          }
        }
      });
      markDataAsLoaded();
      return;
    }
    if (e.data.type === "error") {
      console.error(JSON.stringify(e.data));
      console.error(e.data.error.extra.stack);
      // Error handling
      if (e.data.error.type === SyncErrors.ApiOffline) {
        // no op
      }
      // move service worker side
      else if (
        ([SyncErrors.ApiJWTExpired, SyncErrors.ApiNoJWT] as string[]).includes(
          e.data.error.type,
        )
      ) {
        refreshAccessToken();
      } else if (
        isErrorCode(e.data.error.type) &&
        e.data.error.type === SyncErrors.ApiCannotSaveSomeNotes
      ) {
        console.log(e.data.error.extra);
      } else if (isErrorCode(e.data.error.type)) {
        // errors we anticipated
        setErrorMessage({
          content: displayToUser(e.data.error),
          type: "error",
        });
      } else {
        // errors we didnt anticipate
        console.warn(e.data);
        setErrorMessage({
          content: SyncErrors.ApiReadUnhandled + e.data.error.message,
          type: "error",
        });
      }
    }
  };

// The `self` can be typed as either `Window` or `WorkerGlobalScope` (in jest tests)
// To simulate the jest environment add `declare let self: WorkerGlobalScope`
declare let self: Window | WorkerGlobalScope;
function getServiceWorkerContainer(): ServiceWorkerContainer | null {
  if (self instanceof Window) {
    // When the code is running in the testcafe test environment the serviceWorker is missing
    return self.navigator.serviceWorker ?? null;
  } else {
    return null;
  }
}

export const sendMessageToSW = (message: ClientToSWMessage) => {
  // When the code is running in the testcafe test environment the serviceWorker is missing
  const serviceWorker = getServiceWorkerContainer();
  if (!serviceWorker) return;

  // in unit tests, navigator is set to WorkerNavigator
  if (!serviceWorker.controller) {
    //console.warn("controller not ready or not available");
    askServiceWorkerToClaimClients();
    return false;
  } else {
    serviceWorker.controller.postMessage(message);
    return true;
  }
};

// After a "hard reload" in Chrome, the service worker registration exists but that
// service worker is not controlling the hard reloaded page. To fix that we
// send the message to the service worker asking it to claim all clients. See https://linear.app/ideaflow/issue/ENT-1696
async function askServiceWorkerToClaimClients() {
  // When the code is running in the testcafe test environment the serviceWorker is missing
  const serviceWorker = getServiceWorkerContainer();
  if (!serviceWorker) return;

  let registration = await serviceWorker.getRegistration();
  if (!registration) {
    registration = await serviceWorker.ready;
  }
  registration.active?.postMessage({
    type: "claim-clients",
  } as ClientToSWMessage);
  reportDisconnectedServiceWorkerToSentry();
}

const reportDisconnectedServiceWorkerToSentry = throttle(() => {
  Sentry.captureMessage("disconnected-service-worker-detected", {
    level: "warning",
    tags: { "service-worker": true },
  });
}, 15_000);

function waitForSW() {
  // When the code is running in the testcafe test environment the serviceWorker is missing
  const serviceWorker = getServiceWorkerContainer();
  if (!serviceWorker) return;

  return new Promise<void>((res) => {
    serviceWorker.addEventListener("controllerchange", (e: any) => {
      res();
    });
  });
}

export async function sendMessageToSWWhenReady(message: ClientToSWMessage) {
  while (!sendMessageToSW(message)) {
    // wait for the SW to be available
    await waitForSW();
  }
}

// enable or disable logs in the app
(globalThis as any).enableDebug = (namespaces: string) => {
  localStorage.setItem("debug", namespaces);
  debug.enable(namespaces);
  sendMessageToSW({ type: "debug", namespaces });
};

(globalThis as any).manualSync = () => {
  sendMessageToSW({ type: "manual-sync" });
};
