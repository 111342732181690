import { useMemo } from "react";
import { atom, useAtomValue, useSetAtom } from "jotai";

const sidebarUpdate = atom(0);

/**
 * Notifies the sidebar it needs to refresh.
 */
export const useNotifySidebarUpdate = () => {
  const set = useSetAtom(sidebarUpdate);
  return useMemo(() => () => set(new Date().getTime()), [set]);
};

/**
 * Refreshes when the sidebar refresh.
 *
 * As the notes and metadata in the sidebar can change from a variety of sources, it is fairly hard to build a change detector.
 * We prefer to notify explicitely in the right spot that new data is available for the sidebar to process.
 */
export const useRefreshWithSidebarUpdate = () => {
  return useAtomValue(sidebarUpdate);
};
