const SECOND = 1000;
const MINUTE = 60 * SECOND;
const HOUR = 60 * MINUTE;
const DAY = 24 * HOUR;
const WEEK = 7 * DAY;

const prevNextSameDayFormatter = {
  threshold: 2 * DAY,
  format: (d: number, input: number) => {
    const date = new Date(input);
    return isToday(date)
      ? "Today"
      : isTomorrow(date)
      ? "Tomorrow"
      : isYesterday(date)
      ? "Yesterday"
      : null;
  },
};

const isToday = (someDate: Date) => {
  const today = new Date();
  return (
    someDate.getDate() === today.getDate() &&
    someDate.getMonth() === today.getMonth() &&
    someDate.getFullYear() === today.getFullYear()
  );
};

const isYesterday = (someDate: Date) => {
  const someDatePlusOneDay = new Date(someDate.getTime() + DAY);
  return isToday(someDatePlusOneDay);
};

const isTomorrow = (someDate: Date) => {
  const someDateMinusOneDay = new Date(someDate.getTime() - DAY);
  return isToday(someDateMinusOneDay);
};

const defaultFormats: {
  threshold: number;
  format: (delta: number, input: number) => string | null;
}[] = [
  { threshold: 0.7 * MINUTE, format: () => "Just now" },
  { threshold: 1.5 * MINUTE, format: () => "A minute ago" },
  {
    threshold: 60 * MINUTE,
    format: (d) =>
      `${Math.abs(Math.round(d / MINUTE))} minutes ${
        d > 0 ? "ago" : "from now"
      }`,
  },
  { threshold: 1.5 * HOUR, format: () => "An hour ago" },
  {
    threshold: DAY,
    format: (d) =>
      `${Math.abs(Math.round(d / HOUR))} hours ${d > 0 ? "ago" : "from now"}`,
  },
  prevNextSameDayFormatter,
  {
    threshold: 6 * WEEK,
    format: (d: number) => {
      return `${Math.abs(Math.round(d / DAY))} days ${
        d > 0 ? "ago" : "from now"
      }`;
    },
  },
  {
    threshold: Number.MAX_VALUE,
    format: (_: number, input: number) => {
      const date = new Date(input);
      const formatter = new Intl.DateTimeFormat([], {
        weekday: "short",
        day: "numeric",
        month: "numeric",
        year: "2-digit",
      });
      return `${formatter.format(date)}`;
    },
  },
];

export const dayResolutionFormats = [
  prevNextSameDayFormatter,
  {
    threshold: 7 * DAY,
    format: (d: number, input: number) => {
      const date = new Date(input);
      const formatter = new Intl.DateTimeFormat([], {
        weekday: "short",
        day: "numeric",
        month: "numeric",
      });
      return `${formatter.format(date)} (${Math.abs(
        Math.round(d / DAY),
      )} days ${d > 0 ? "ago" : "from now"})`;
    },
  },
  {
    threshold: Number.MAX_VALUE,
    format: (_: any, input: number) => {
      const date = new Date(input);
      const formatter = new Intl.DateTimeFormat([], {
        day: "numeric",
        month: "numeric",
        year: "2-digit",
      });
      return formatter.format(date);
    },
  },
];

export const formatDate = (
  inputDate: Date,
  formats = defaultFormats,
): string => {
  const reference = new Date().getTime();
  const input = inputDate.getTime();
  const delta = reference - input;

  for (let i = 0; i < formats.length; i++) {
    const { threshold, format } = formats[i];
    if (Math.abs(delta) <= threshold) {
      const result = format(delta, input);
      if (result !== null) return result;
    }
  }

  return inputDate.toLocaleString();
};
