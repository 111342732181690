import { InputRule, inputRules } from "prosemirror-inputrules";
import { trackEvent } from "../../../analytics/analyticsHandlers";
import { SearchQuery } from "../../../search/SearchQuery";
import { schema } from "../../schema";
import { getParentNote } from "../../utils/find";
import getNode from "../../utils/getNode";
import { trSplitNote } from "./splitNoteCommand";

const getSplitNoteOnTripleDashPlugin = (searchQuery: SearchQuery) => {
  return inputRules({
    rules: [
      new InputRule(/(—-|—–|–—)$/, (state) => {
        const tr = state.tr;

        // Confirm we're in a paragraph of a top level note
        const rpos = tr.selection.$from;
        const [paragraph, paragraphPos] = [rpos.node(), rpos.before()];
        const note = rpos.node(-1);
        if (paragraph.type !== schema.nodes.paragraph) return null;
        if (note.type !== schema.nodes.note || note.attrs.depth > 0) {
          return null;
        }
        // and that the paragraph only contains dashes
        if (paragraph.textContent.match(/^(-|—)+$/) === null) {
          return null;
        }

        // Split the note
        const folderId =
          searchQuery.type === "folder" ? searchQuery.q : undefined;
        if (!trSplitNote(state, tr, folderId)) return null;

        // Delete the paragraph with dashes
        tr.delete(
          paragraphPos,
          paragraphPos +
            getNode(tr.doc, paragraphPos, schema.nodes.paragraph).nodeSize,
        );

        // If the note being split had content below the dashes, the new note
        // will have an empty paragraph at the top, followed by the content.
        // If that's the case, delete the empty paragraph.
        const [node, pos] = [
          tr.selection.$from.node(),
          tr.selection.$from.before(),
        ];
        const childCount = getParentNote(tr.doc, pos)[0]?.childCount || 0;
        if (
          childCount > 1 &&
          node.type === schema.nodes.paragraph &&
          node.textContent === ""
        ) {
          tr.delete(pos, pos + node.nodeSize);
        }

        trackEvent([
          "NOTE",
          folderId ? "SPLIT_WITH_DASHES_FROM_FOLDER" : "SPLIT_WITH_DASHES",
        ]);
        return tr;
      }),
    ],
  });
};

export { getSplitNoteOnTripleDashPlugin };
