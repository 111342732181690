import { DecorationSet, Decoration } from "prosemirror-view";
import { PluginKey, Plugin, EditorState } from "prosemirror-state";
import { noteList } from "../../../model/services";
import { dayResolutionFormats, formatDate } from "../../../utils/date";

type NotesWithOffset = {
  offset: number;
  nodeId: string;
}[];

/** get all notes info with the offset in the doc */
const getNotesWithDividers = (doc: EditorState["doc"]): NotesWithOffset => {
  const nodes: NotesWithOffset = [];
  let previousInsertedAt = "";
  doc.forEach((node, offset) => {
    if (node.attrs.depth !== 0) return; // right now, we only display date dividers at the top level.
    const note = noteList.get(node.attrs.noteId)!;
    if (previousInsertedAt !== note.insertedAt) {
      nodes.push({
        nodeId: node.attrs.noteId,
        offset,
      });
      previousInsertedAt = note.insertedAt;
    }
  });
  return nodes;
};

const toDate = (inserted: string) => {
  const year = inserted.substr(0, 4);
  const month = inserted.substr(4, 2);
  const day = inserted.substr(6, 2);
  return new Date(`${year}/${month}/${day}`);
};

const createOrUpdateDivider = (nodeId: string) => {
  const note = noteList.get(nodeId)!;
  const id = `note-divider__${nodeId}`;
  let container = document.getElementById(id);
  if (container === null) {
    container = document.createElement("div");
    container.className = "note-divider";
    container.contentEditable = "false";
    container.id = id;
  } else {
    Array.from(container.childNodes).forEach((cN) => cN.remove());
  }
  if (note.insertedAt === "00000000") {
    const span = document.createElement("span");
    span.appendChild(document.createTextNode("Older Notes"));
    container.append(span);
    return container;
  }
  if (!note.insertedAt) {
    throw new Error("Missing insertedAt for note " + note.id);
  }
  const span = document.createElement("span");
  const insertedAt = toDate(note.insertedAt);
  span.appendChild(
    document.createTextNode(formatDate(insertedAt, dayResolutionFormats)),
  );
  container.append(span);
  return container;
};

export const dateDividerPlugin: Plugin<NotesWithOffset> = new Plugin({
  key: new PluginKey("showDateDividers"),
  state: {
    init: (_, state) => getNotesWithDividers(state.doc),
    apply: (tr, pluginState) =>
      tr.docChanged ? getNotesWithDividers(tr.doc) : pluginState,
  },
  props: {
    decorations(state) {
      const decorations = (this as Plugin<NotesWithOffset>)
        .getState(state)!
        .map(({ offset, nodeId }) => {
          return Decoration.widget(
            offset + 1,
            () => createOrUpdateDivider(nodeId),
            {
              side: -1,
              key: `${nodeId}-date-divider`,
            },
          );
        });
      return DecorationSet.create(state.doc, decorations);
    },
  },
});
// display it for all nodes first,
// then display it only for the ones that have a different date from the previous node.
