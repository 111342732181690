import { FolderId } from "./../model/types";
import { HashtagId, NoteId } from "../model/types";
import { ParsedUrlQuery } from "querystring";
import { assertUnreachable } from "../utils/assertUnreachable";
import { RoutesOption } from "../routes";

export enum SortBy {
  lastUpdated = "lastUpdated",
  relevance = "relevance",
  position = "position",
}
export const sortByDefault = SortBy.relevance;

export type SearchQuery = {
  limit?: number; // undefined means no limit.
  isCondensed: boolean; // denormalize
  isIncludingSubFolders: boolean; // denormalize
  sortBy: SortBy; // denormalize
} & (
  | AllQuery
  | ByHashtagQuery
  | ByPlainTextQuery
  | ByNoteTypeQuery
  | ByNoteIdQuery
  | ByDateQuery
  | ByFolderQuery
);

type ParsedUrlSearchQuery = { limit?: string } & (
  | ParsedUrlAllQuery
  | ParsedUrlByHashtagQuery
  | ParsedUrlByPlainTextQuery
  | ParsedUrlByNoteTypeQuery
  | ParsedUrlByNoteIdQuery
  | ParsedUrlByDateQuery
  | ParsedUrlByFolderQuery
);

export type SearchQuerySetter = (s: Partial<SearchQuery>) => void;

interface AllQuery {
  type: "*";
}
interface ParsedUrlAllQuery {
  limit?: string;
}

interface ByNoteIdQuery {
  type: "note";
  q: NoteId;
  asPage: boolean;
}
interface ParsedUrlByNoteIdQuery {
  type: "note";
  q: NoteId;
  asPage?: "1";
}

interface ByNoteTypeQuery {
  type: "is";
  q: NoteTypeFilter;
}
interface ParsedUrlByNoteTypeQuery {
  type: "is";
  q: NoteTypeFilter;
  sortBy: SortBy;
}

export type NoteTypeFilter = "todo" | "incomplete" | "complete" | "public";

export const isNoteFilter = (v: string): v is NoteTypeFilter =>
  ["complete", "incomplete", "todo", "public"].includes(v);

export interface ByPlainTextQuery {
  type: "text";
  q: string;
}
interface ParsedUrlByPlainTextQuery {
  type: "text";
  q: string;
  sortBy: SortBy;
}

export interface ByHashtagQuery {
  type: "hashtag";
  q: HashtagId;
}
interface ParsedUrlByHashtagQuery {
  type: "hashtag";
  q: HashtagId;
  sortBy: SortBy;
}

interface ByDateQuery {
  type: "date";
  q: Date;
}
interface ParsedUrlByDateQuery {
  type: "date";
  q: string;
  sortBy: SortBy;
}

interface ByFolderQuery {
  type: "folder";
  q: FolderId;
}
interface ParsedUrlByFolderQuery {
  type: "folder";
  q: FolderId;
  sortBy: SortBy;
}

export const isCondensable = (query: SearchQuery) => {
  if (query.type === "is" && query.q === "public") return false;
  return ["is", "text", "hashtag"].includes(query.type);
};

export const hasHeader = (query: SearchQuery) => {
  if (query.type === "is" && query.q === "public") return false;
  return ["is", "folder", "text", "hashtag"].includes(query.type);
};

export const partialToSearchQuery = (
  query: Partial<SearchQuery>,
): SearchQuery => {
  let common: any = query.limit === undefined ? {} : { limit: query.limit };
  common = {
    ...common,
    isCondensed: query.isCondensed ?? true,
    isIncludingSubFolders: query.isIncludingSubFolders ?? true,
    sortBy: query.sortBy ?? sortByDefault,
  };
  query.type = query.type ?? "*";
  switch (query.type) {
    case "is":
    case "date":
    case "text":
    case "hashtag":
    case "folder":
      return {
        ...common,
        type: query.type,
        q: query.q as string,
      };
    case "note":
      return {
        ...common,
        type: "note",
        q: query.q as NoteId,
        asPage: query.asPage || false,
      };
    case "*":
      return {
        ...common,
        type: "*",
      };
  }
};

const toUrlSearchQuery = (query: SearchQuery): ParsedUrlSearchQuery => {
  const common =
    query.limit === undefined ? {} : { limit: query.limit.toString() };
  switch (query.type) {
    case "is":
      return {
        ...common,
        type: query.type,
        q: query.q,
      };
    case "text":
    case "hashtag":
    case "folder":
      return {
        ...common,
        type: query.type,
        q: query.q,
        sortBy: query.sortBy,
      };
    case "note":
      return {
        type: query.type,
        q: query.q,
        asPage: query.asPage ? "1" : undefined,
      };
    case "date":
      return {
        type: query.type,
        q: midnightUTC(query.q),
        sortBy: query.sortBy,
      };
    case "*":
      return common;
  }
};

export const toParsedUrl = (query: SearchQuery): ParsedUrlQuery => {
  return { ...toUrlSearchQuery(query) };
};

export const fromParsedUrl = (
  searchParams: ParsedUrlQuery,
): Partial<SearchQuery> => {
  const common: Partial<SearchQuery> = {};
  if (isInt(searchParams.limit)) common.limit = parseInt(searchParams!.limit);
  if (searchParams == null || !isString(searchParams.type)) {
    return { ...common, type: "*" };
  }
  const sortBy = Object.values(SortBy).find((v) => v === searchParams.sortBy);
  if (sortBy) common.sortBy = sortBy;
  if (searchParams.asPage === "1") (common as any).asPage = true;
  if (
    ["is", "text", "folder", "note", "hashtag"].includes(searchParams.type) &&
    isString(searchParams.q)
  ) {
    return {
      ...common,
      type: searchParams.type as any,
      q: searchParams.q,
    };
  }

  if (searchParams.type === "date" && isString(searchParams?.q)) {
    const match = /^(\d{4})-(\d{2})-(\d{2})$/.exec(searchParams.q);
    if (!match) {
      // if invalid date, just show today
      return {
        ...common,
        type: "date",
        q: new Date(),
      };
    }
    const date = new Date();
    date.setHours(0, 0, 0, 0);
    date.setFullYear(parseInt(match[1]));
    date.setMonth(parseInt(match[2]) - 1);
    date.setDate(parseInt(match[3]));

    return {
      ...common,
      type: "date",
      q: date,
    };
  }

  return { type: "*" };
};
const isString = (a: any): a is string => a && typeof a === "string";
const isInt = (a: any): a is string => isString(a) && !isNaN(parseInt(a));

export const toUrl = (query: Partial<SearchQuery>) => {
  const url = new URL(RoutesOption.Home, window.location.href);
  const queryParams = toParsedUrl(partialToSearchQuery(query));
  for (const [key, value] of Object.entries(queryParams)) {
    url.searchParams.set(key, value as string);
  }
  return url;
};

export function midnightUTC(date: Date) {
  // Convert the date from local timezone midnight to UTC midnight
  const midnightInUTC = new Date(
    Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()),
  );
  return midnightInUTC.toISOString().substring(0, 10);
}

export type PageKey = string;
export const searchQueryToPage = (state: SearchQuery): PageKey => {
  switch (state.type) {
    case "text":
    case "hashtag":
    case "note":
    case "is":
    case "folder":
      return state.type + ":" + state.q;
    case "date":
      return state.type + ":" + state.q.toLocaleDateString();
    case "*":
      return state.type;
    default:
      assertUnreachable(state);
      return "";
  }
};
