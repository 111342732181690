import { InputRule, inputRules } from "prosemirror-inputrules";

/**
 * Create an InputRule that matches a sinple RegExp and does a simple string
 * replacement, so you could type e.g. '->' and get '→'
 *
 * @param match RegExp passed to InputRule that should match on any input to be
 * replaced by a string. e.g. /->$/g
 * @param rule The string that should replace the matched bit of input. e.g. '→'
 */
function createInputRule(match: RegExp, rule: string) {
  return new InputRule(match, (state, _match, start, end) => {
    const tr = state.tr.delete(start, end);
    tr.insertText(rule, start);
    return tr;
  });
}

type MatchMap = { [rule: string]: RegExp };

export function textExpanderPlugin(matchMap: MatchMap) {
  return inputRules({
    rules: Object.entries(matchMap).map(([rule, match]) =>
      createInputRule(match, rule),
    ),
  });
}
