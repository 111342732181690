import { DecorationSet, Decoration } from "prosemirror-view";
import { PluginKey, Plugin, EditorState } from "prosemirror-state";
import { NoteId } from "../../../model/types";
import { descendNotes } from "../../utils/descendNotes";

type State = {
  noteId: NoteId;
  offset: number;
}[];

/** get all notes info with the offset in the doc */
const getState = (doc: EditorState["doc"]): State => {
  const notes: State = [];
  descendNotes(doc, (note, offset) => {
    notes.push({ offset, noteId: note.attrs.noteId });
  });
  return notes;
};

const createDom = (noteId: NoteId, toggle: (id: string) => void) => () => {
  const domId = `note-menu-${noteId}`;
  let menu = document.getElementById(domId);

  if (menu === null) {
    menu = document.createElement("div");
    menu.className = "note-menu-mobile";

    const button = document.createElement("button");
    button.className = "note-menu-button-mobile";

    const ellipsis = document.createElement("div");
    ellipsis.innerHTML = "•••";
    ellipsis.style.fontSize = "12px";
    ellipsis.style.marginTop = "0px";
    ellipsis.style.fontWeight = "bold";

    button.appendChild(ellipsis);
    menu.appendChild(button);
    menu.onmousedown = (e) => {
      e.preventDefault();
      toggle(noteId);
      button.classList.toggle("active");
    };
  }
  return menu;
};

// We always add the menu, and we hide it with a media query.
export const getMobileNoteMenuPlugin: (
  toggle: (id: string) => void,
) => Plugin<State> = (toggle) => {
  const plugin: Plugin<State> = new Plugin({
    key: new PluginKey("jumpTo"),
    state: {
      init: (_, state) => getState(state.doc),
      apply: (tr, pluginState) =>
        tr.docChanged ? getState(tr.doc) : pluginState,
    },
    props: {
      decorations(state) {
        const s = (this as Plugin<State>).getState(state)!;
        const decorations = s.map(({ offset, noteId }) => {
          return Decoration.widget(offset + 1, createDom(noteId, toggle), {
            side: -1,
            key: `${noteId}-mobile-menu`,
          });
        });
        return DecorationSet.create(state.doc, decorations);
      },
    },
  });
  return plugin;
};
