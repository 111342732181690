import { joinBackward } from "prosemirror-commands";
import { ResolvedPos } from "prosemirror-model";
import { EditorState } from "prosemirror-state";
import { EditorView } from "prosemirror-view";
import { trackEvent } from "../../../analytics/analyticsHandlers";
import { schema } from "../../schema";
import { isNoteEmpty } from "./isNoteEmpty";

/** custom patch to merge the first paragraph of the current
 * node with the last paragraph of the previous node
 * many of the false cases are taken from `joinBackward`,
 * `deleteBarrier`, & `joinMaybeClear` in prosemirror-commands
 */
export const joinNotesOnBackspaceCommand = (
  state: EditorState,
  dispatch?: EditorView["dispatch"],
  view?: EditorView,
): boolean => {
  if (dispatch == null) return false;

  const { $anchor } = state.selection;
  if (
    !$anchor ||
    (view ? !view.endOfTextblock("backward", state) : $anchor.parentOffset > 0)
  ) {
    return false;
  }
  const $cut = findCutBefore($anchor);
  if ($cut == null) return false;

  const before = $cut.nodeBefore;
  const after = $cut.nodeAfter;
  // if we don't have a node before or after
  if (before == null || after == null) return false;
  // When enabled (default is false), the sides of nodes of this type count as
  // boundaries that regular editing operations,
  // like backspacing or lifting, won't cross
  if (before.type.spec.isolating || after.type.spec.isolating) return false;

  // if the node before is not compatible with the node after the position
  // typecast since compatibleContent is not a public API
  if (!(before.type as any).compatibleContent(after.type)) return false;

  const currentNoteIndex = $cut.index(0);
  const currentNote = state.doc.resolve($cut.posAtIndex(currentNoteIndex, 0));

  // if we are at the start of a line and deleting into the previous node
  if (currentNote.pos === $cut.pos) {
    // If previous note is empty, just delete the prev note. This behavior means
    // that spaceship refs are preserved when backspacing away empty notes,
    // which is a common interaction.
    const prevNote = $cut.nodeBefore;
    if (prevNote && isNoteEmpty(prevNote)) {
      dispatch(state.tr.deleteRange($cut.pos - prevNote.nodeSize, $cut.pos));
      return true;
    }

    const lastTopLevelNodeOfPrevNote = state.doc.resolve(
      currentNote.before(currentNote.depth + 1) - 1,
    );

    const firstTopLevelNodeOfCurrentNote = state.doc.resolve(
      currentNote.before(currentNote.depth + 1) + 1,
    );

    if (
      before.lastChild &&
      before.lastChild === lastTopLevelNodeOfPrevNote.nodeBefore &&
      after.firstChild === firstTopLevelNodeOfCurrentNote.nodeAfter
    ) {
      trackEvent(["NOTE", "MERGE"]);

      if (
        before.lastChild.type === schema.nodes.bulletList ||
        before.lastChild.type === schema.nodes.expandedReference ||
        before.lastChild.type === schema.nodes.backlinks
      ) {
        // note being merged into has a bullet list or expanded note at the end
        return joinBackward(state, dispatch);
      }

      dispatch(
        state.tr
          .clearIncompatible(
            $cut.pos,
            before.type,
            before.contentMatchAt(before.childCount),
          )
          // join the last paragraph of the previous note with the
          // first paragraph of the current note
          .join($cut.pos, 2),
      );
      return true;
    }
  }
  return false;
};

/** from https://github.com/ProseMirror/prosemirror-commands/blob/master/src/commands.js#L92-L98 */
function findCutBefore($cut: ResolvedPos) {
  if (!$cut.parent.type.spec.isolating)
    for (let i = $cut.depth - 1; i >= 0; i--) {
      if ($cut.index(i) > 0) return $cut.doc.resolve($cut.before(i + 1));
      if ($cut.node(i).type.spec.isolating) break;
    }
  return null;
}
