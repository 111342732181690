import { NodeSpec } from "prosemirror-model";
import { getDefaultAttrs } from "../../utils/attrs";

export const expandedReference: NodeSpec = {
  content: "note{0,1}",
  group: "topLevelNode blockNode",
  defining: true,
  selectable: false,
  draggable: false,
  isolating: true,
  attrs: {
    tokenId: { default: "" },
    referenceTokenId: { default: "" }, // id of a schema.nodes.reference node
    isHighlighted: { default: false },
    containedNoteId: { default: null },
    isBacklink: { default: false },
  },
  parseDOM: [
    {
      tag: "div.expandedNote",
      getAttrs: getDefaultAttrs([
        "containedNoteId",
        "referenceTokenId",
        "isHighlighted",
      ]),
    },
  ],
  toDOM(node) {
    return [
      "div",
      {
        class:
          "expandedNote" + (node.attrs.isHighlighted ? " highlighted" : ""),
        contenteditable: "false",
      },
      0,
    ];
  },
};
