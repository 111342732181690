import { LastUpdated } from "./ram/LastUpdated";
import { ManyToManyIndex } from "./ram/ManyToManyIndex";
import {
  NoteId,
  Note,
  Hashtag,
  IdeaPosition,
  BlockToken,
  masterRevInitial,
} from "./types";
import { NoteFormatter } from "./noteFormatter";
import { applyOnInline } from "../utils/tokenIterators/mapBlockTokens";
import { notePositions } from "./services";
import { isWorker } from "../utils/environment";
import { fixTopLevelTokens } from "../export/fixTokens";
import { FlatIndex } from "./ram/FlatIndex";
import { BlockText } from "../model/noteFormatter";
import { formatInsertedAt } from "../editorPage/utils/insertedAt";
import { ElementList } from "./ram/ElementList";
import { generateId } from "./generateId";

if (isWorker) {
  throw new Error("The worker needs to use the persisted note list");
}

export type NoteInsert = Partial<Omit<Note, "updatedAt">> & {
  strings?: string[];
};

// This type transforms the proviede type argument to one that requires the presence
// of the specified properties (they are no longer optional)
type RequireProp<T, P extends keyof T> = Omit<T, P> & { [K in P]-?: T[K] };

export type NoteCreate = NoteInsert;

export type NoteUpdates = {
  id: NoteId;
  tokens?: BlockToken[];
  position?: IdeaPosition;
  readAll?: boolean;
  folderId?: string | null;
  positionInPinned?: string | null;
  deletedAt?: Date | null;
};

// export type NoteUpsert = Partial<Note>;
// export type NoteUpsert = Partial<Note> & Persistable & { id: NoteId };
export type NoteUpsert = Partial<Note> & { id: NoteId };

/**
 * Orchestrates Notes CRUD operations.
 *
 * Creating a note require several side effects:
 * - storing it in RAM
 * - indexing it
 * - sending it to DB
 */
export class NoteList extends ElementList<NoteId, Note> {
  constructor(
    private notes: Map<NoteId, Note>,
    public noteFormatter: NoteFormatter,
    public hashtags: ManyToManyIndex,
    public backlinks: ManyToManyIndex,
    private lastUpdatedHashtags: LastUpdated,
    private textIndex: FlatIndex<NoteId, BlockText[]>,
  ) {
    super(notes);
  }

  #changeListeners = new Set<() => void>();
  #callAllChangeListeners() {
    this.#changeListeners.forEach((l) => l());
  }
  addChangeListener(listner: () => void) {
    this.#changeListeners.add(listner);
  }
  removeChangeListener(listner: () => void) {
    this.#changeListeners.delete(listner);
  }

  /**
   * Takes partial note and returns a note object with missing properties
   * added with default values.
   * The note object is not inserted into the list.
   * */
  private create(noteCreate: NoteCreate = {}): Note {
    const { strings, ...note } = noteCreate;
    const tokens = fixTopLevelTokens(
      note.tokens ||
        (strings || [""]).map((string) => ({
          type: "paragraph",
          tokenId: generateId(),
          content: [{ type: "text", marks: [], content: string }],
        })),
    );
    const start = new Date();
    return {
      id: note.id || generateId(),
      tokens,
      createdAt: note.createdAt || start,
      updatedAt: start,
      insertedAt:
        note.insertedAt || formatInsertedAt(note.createdAt || new Date()),
      position: note.position || notePositions.generateFirst()[0],
      folderId: note.folderId || null,
      deletedAt: note.deletedAt || null,
      readAll: note.readAll || false,
      localRev: note.localRev || generateId(),
      masterRev: note.masterRev || masterRevInitial,
    };
  }

  /**
   * Takes partial notes objects, each with an id of a note which already exists,
   * and updates the corresponding notes with the provided properties.
   */
  update(updateNotes: NoteUpdates[] | NoteUpdates) {
    const start = new Date();
    if (!Array.isArray(updateNotes)) updateNotes = [updateNotes];
    const { wasANoteUndeleted, notes } = this.computeMergedNotes(
      updateNotes.map((note) => {
        const oldNote = this.notes.get(note.id);
        if (!oldNote) throw new Error("cannot update a non existing note");

        if (note.tokens) {
          note.tokens = fixTopLevelTokens(note.tokens);
        }
        const nextLocalRev = generateId();
        return {
          ...note,
          updatedAt: start,
          localRev: nextLocalRev,
        };
      }),
      false,
    );
    this.set(notes);
    this.#callAllChangeListeners();
    if (wasANoteUndeleted) this.reindexAllNotes();
    return notes;
  }

  ack(id: NoteId, masterRev: string) {
    const oldNote = this.notes.get(id)!;
    // We **don't** call the `this.#callAllChangeListeners()` here since `ack` doesn't mark any notes as dirty
    this.set([{ ...oldNote, masterRev }]);
  }

  /** Takes partial note objects, fills with defaults, and inserts into the list.*/
  insert(insertNotes: NoteInsert[] | NoteInsert = {}) {
    const start = new Date();
    if (!Array.isArray(insertNotes)) insertNotes = [insertNotes];
    const notes: Note[] = insertNotes.map((note) => {
      if (note.id && this.notes.has(note.id)) {
        throw new Error(`Note with id ${note.id} already exists`);
      }
      return this.create({ ...note, createdAt: note.createdAt || start });
    });
    this.set(notes);
    this.#callAllChangeListeners();
    return notes;
  }

  /** Sets the deletedAt field to the current date */
  delete(ids: NoteId[]) {
    return ids.map((id) => this.update({ id, deletedAt: new Date() }));
  }

  /**
   * Updates or inserts into list with the given partial notes.
   *
   * Unlike insert and update methods, this method does not modify the given
   * notes in any way before inserting them. For example, it does not fix tokens,
   * set updateAt, or update the revs.
   *
   * @warning This method shouldn't only be used by sync.
   * Client features should use insert and update methods.
   */
  upsert(noteUpserts: NoteUpsert[] | NoteUpsert) {
    if (!Array.isArray(noteUpserts)) noteUpserts = [noteUpserts];
    const { wasANoteUndeleted, notes } = this.computeMergedNotes(
      noteUpserts.map((note) => {
        if (!note.id) {
          return { ...note, id: generateId() };
        } else {
          return note as RequireProp<Partial<Note>, "id">;
        }
      }),
      true,
    );
    this.set(notes);
    // We **don't** call the `this.#callAllChangeListeners()` here since `upsert` doesn't mark any notes as dirty (localRev and masterRev are just loaded)
    if (wasANoteUndeleted) this.reindexAllNotes();
    return notes;
  }

  private computeMergedNotes(
    updates: RequireProp<Partial<Note>, "id">[],
    allowNewNoteCreation: boolean,
  ) {
    let wasANoteUndeleted = false;

    const notes: Note[] = updates.map((note) => {
      const existingNote = this.notes.get(note.id);
      if (!existingNote) {
        if (allowNewNoteCreation) {
          return { ...this.create(note), ...note };
        } else {
          throw new Error(`Note with id ${note.id} does not exist`);
        }
      } else {
        wasANoteUndeleted =
          wasANoteUndeleted ||
          !!(existingNote.deletedAt && note.deletedAt === null);
        return { ...existingNote, ...note };
      }
    });
    return { wasANoteUndeleted, notes };
  }

  private set(notes: Note[]) {
    for (const note of notes) {
      this.notes.set(note.id, note);
    }
    // we batch all indexes after the note is correct in the main repo
    // this is due to dependencies such as + references.
    for (const note of notes) {
      this.upsertIndexes(note);
    }
  }

  clear() {
    this.notes.clear();
    this.hashtags.clear();
    this.backlinks.clear();
    this.lastUpdatedHashtags.clear();
  }

  getNoteIdByPositionInPinned(position: IdeaPosition): NoteId | undefined {
    for (const note of this.getAll()) {
      if (note.positionInPinned === position) return note.id;
    }
  }

  private reindexAllNotes() {
    for (const note of this.getAll()) {
      this.upsertIndexes(note);
    }
  }

  private upsertIndexes(note: Note) {
    if (note.deletedAt) {
      this.hashtags.delete(note.id, false);
      this.backlinks.delete(note.id, true);
      this.textIndex.delete(note.id);
      return;
    }
    const hashtagsInNote: Hashtag[] = [];
    const referencesInNote: string[] = [];
    if (note.tokens) {
      applyOnInline(note.tokens, (t) => {
        if (t.type === "hashtag") {
          hashtagsInNote.push({ content: t.content });
        } else if (t.type === "spaceship") {
          referencesInNote.push(t.linkedNoteId);
        }
      });
    }

    // hashtags
    const hashtagsNoLongerInDoc = this.hashtags.delete(note.id, false);
    const hashtagIdsInNote = hashtagsInNote.map((h) => h.content);
    this.hashtags.set(note.id, hashtagIdsInNote);

    // backlinks
    this.backlinks.delete(note.id, false); // this function is only called on upsert so don't need to check references
    this.backlinks.set(note.id, referencesInNote);

    // search index
    if (note.tokens && !note.deletedAt) {
      this.textIndex.set(
        note.id,
        this.noteFormatter.getNoteAsBlockTexts(note.tokens),
      );
    } else {
      this.textIndex.delete(note.id);
    }

    // last updated
    // @todo track it upstream, when we edit
    this.lastUpdatedHashtags.remove(hashtagsNoLongerInDoc);
    this.lastUpdatedHashtags.update(hashtagIdsInNote);
  }

  getLastUpdatedHashtags(noteId: NoteId): string[] {
    const currentNoteItems = this.hashtags.getItemsForNote(noteId);
    return this.lastUpdatedHashtags.lastUpdated
      .filter(
        (i) =>
          !currentNoteItems
            .map((h) => h.toLocaleLowerCase())
            .includes(i.toLocaleLowerCase()),
      )
      .reverse();
  }

  getNoteAsBlockTexts(noteId: NoteId) {
    return this.textIndex.get(noteId);
  }
}
