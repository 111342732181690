import { Node as ProsemirrorNode } from "prosemirror-model";
import { EditorView, NodeView } from "prosemirror-view";
import { noteList } from "../../../model/services";
import { domAttrs, entityInnerDOM } from "../../utils/attrs";
import {
  isCircularReference,
  toggleSpaceship,
} from "./referenceExpansionUtils";

function setAttributes(el: HTMLElement, attrs: any) {
  for (const key in attrs) {
    el.setAttribute(key, attrs[key]);
  }
}

export class ReferenceView implements NodeView {
  dom: HTMLElement;
  constructor(
    node: ProsemirrorNode,
    view: EditorView,
    getPos: () => number | undefined,
  ) {
    this.dom = document.createElement("span");
    this.dom.className = "spaceship embed clickable";

    setAttributes(this.dom, domAttrs(node, node.attrs));
    this.dom.appendChild(entityInnerDOM("", node.attrs.content));

    const pos = getPos();
    if (pos && isCircularReference(view.state.doc.resolve(pos))) {
      this.dom.classList.remove("clickable");
      this.dom.classList.add("not-clickable");
      setAttributes(this.dom, {
        role: "ios-tooltip tooltip",
        "aria-label": "Cannot expand circular reference",
        "data-microtip-position": "top",
      });
    }

    const onClick = (e: MouseEvent | TouchEvent) => {
      e.preventDefault();
      const note = noteList.get(node.attrs.linkedNoteId);
      // Do not expand deleted notes. We now keep the deleted notes in the noteList
      // so it's not enough to check if they are present
      if (!note || note.deletedAt !== null) {
        return;
      }
      toggleSpaceship(view.state, view.dispatch, getPos());
    };
    this.dom.onclick = onClick;
    this.dom.onmousedown = (e) => e.preventDefault();
  }
}
