import { EditorState, Command, Plugin } from "prosemirror-state";
import { keymap } from "prosemirror-keymap";
import { history, undo, redo } from "prosemirror-history";
import { dropCursor } from "prosemirror-dropcursor";

import { getHandleListActionsPlugin } from "./features/list/handleListActionsPlugin";
import { SearchQuery, SearchQuerySetter } from "../search/SearchQuery";
import { schema } from "./schema";
import { NoteId } from "../model/types";
import { autocompletePlugin } from "./features/autocomplete/autocompletePlugin";
import { arrowKeyFixPlugin } from "./fix/arrowKeyFixPlugin";
import { fixDragDropPlugin } from "./fix/fixDragDropPlugin";
import { ensureAtLeastOneNote } from "./features/doc/ensureAtLeastOneNote";

import {
  goToLineStart,
  goToLineEnd,
} from "./features/paragraph/goToParagraphCommands";
import {
  expandReferenceCommand,
  collapseReferenceCommand,
} from "./features/reference/toggleExpandedReferenceCommands";

import { toggleCheckbox } from "./features/checkbox/toggleCheckboxCommand";

import { shortcuts } from "../shortcuts/shortcuts";
import { linkPreviewPlugin } from "./features/link/linkPreview";
import { dateDividerPlugin } from "./features/dateDivider/dateDividerPlugin";
import { noteDebuggerPlugin } from "./features/note/noteDebuggerPlugin";
import { getSplitNoteCommand } from "./features/note/splitNoteCommand";
import { DispatchToModel } from "../editorPage/Editor";
import { checkboxPlugin } from "./features/checkbox/checkboxPlugin";
import { insertParagraphCommand } from "./features/paragraph/insertParagraphCommand";
import {
  baseKeymap,
  chainCommands,
  joinBackward,
  joinForward,
  selectNodeBackward,
  selectNodeForward,
} from "prosemirror-commands";

import { getMobileNoteMenuPlugin } from "./features/note/mobileNoteMenuPlugin";

import { codeblockInputPlugin } from "./features/codeblock/codeblockInputPlugin";
import { dedent, indent } from "./features/text/tabCommands";
import { insertBr } from "./features/text/insertBrCommand";
import { textExpanderPlugin } from "./features/text/textExpanderPlugin";
import {
  toggleAllowedMarks,
  clearAllowedMarks,
} from "./utils/mark/toggleAllowedMarks";
import { createNestedListPlugin } from "./features/list/createNestedListPlugin";
import { isProd, isTouchDevice } from "../utils/environment";
import { applyMarksPlugin } from "./utils/mark/applyMarksPlugin";
import { persistencePlugin } from "./utils/persistencePlugin";
import { backlinkTogglePlugin } from "./features/backlink/backlinkPlugin";
import { selectAll, selectAllPlugin } from "./features/note/SelectAllPlugin";
import { AutoCompleteState } from "./features/autocomplete/useAutocompleteState";
import { highlightPlugin } from "./features/highlight/highlightPlugin";
import { Node } from "prosemirror-model";
import { expansionEditMirrorPlugin } from "./features/reference/expansionEditMirrorPlugin/expansionEditMirrorPlugin";

import {
  goToNoteEnd,
  goToNoteStart,
  selectToNoteEnd,
  selectToNoteStart,
} from "./features/note/goToNoteStartEndCommand";
import { toggleEllipsisPlugin } from "./features/ellipsis/toggleEllipsisPlugin";
import { preventDeletionAcrossExpansionPlugin } from "./features/reference/preventDeletionAcrossExpansionPlugin";
import { fixDeletedExpandedNote } from "./features/reference/fixDeletedExpandedNote";
import { backspaceNoteCommand } from "./features/note/backSpaceNoteCommand";
import { joinNotesOnBackspaceCommand } from "./features/note/joinNotesOnBackspaceCommand";
import { removeEmptyCodeBlock } from "./features/codeblock/removeEmptyCodeBlockCommand";
import { collapsePrevExpandedNote } from "./features/reference/collapsePrevExpandedReference";
import { joinListOnBackspaceCommand } from "./features/list/joinListOnBackspaceCommand";
import { appendExpandedReferenceCommand } from "./features/reference/appendExpandedReferenceCommand";
import { deleteExpandedReferenceCommand } from "./features/reference/deleteExpandedReferenceCommand";
import {
  collapseEllipsisCommand,
  expandEllipsisCommand,
} from "./features/ellipsis/toggleEllipsisCommands";
import { backspaceEllipsisReveal } from "./features/note/backspaceEllipsisRevealCommand";
import { fixTokenIdsPlugin } from "./utils/fixTokenIdsPlugin";
import { getSearchForSelection } from "./features/search/searchForSelection";
import { getOpenNoteAsPage } from "./features/search/openNoteAsPage";
import { getShowNoteInTimeline } from "./features/search/showNoteInTimeline";
import { joinNotesOnDeleteCommand } from "./features/note/joinNotesOnDeleteCommand";
import { getSplitNoteOnTripleDashPlugin } from "./features/note/splitNoteOnTripleDash";
import { trackEvent } from "../analytics/analyticsHandlers";
import { Shortcut } from "../shortcuts/shortcuts";
import { deleteEmptyTopNoteOnBackspace } from "./features/note/deleteEmptyTopNoteOnBackspace";
import {
  collapseBacklinksCommand,
  expandBacklinksCommand,
} from "./features/backlink/toggleBacklinksCommands";
import { syncMarkPlugin } from "./features/sync-mark/syncMarkPlugin";

function keymapWithEventTracking(commands: Map<Shortcut, Command>): Plugin {
  const bindings: { [key: string]: Command } = {};
  commands.forEach((command, shortcut) => {
    bindings[shortcut.keys] = (state, dispatch, view) => {
      trackEvent(["KEYBOARD", "SHORTCUT", shortcut.id]);
      return command(state, dispatch, view);
    };
  });
  return keymap(bindings);
}

/** The configuration common to both createEditorState and createEditorView */
export interface EditorStateAndViewConfig {
  isReadOnly: boolean;
  dispatchToModel: DispatchToModel;
  setAutocompleteState: (
    func: (prevState: AutoCompleteState) => AutoCompleteState,
  ) => void;
  autocompleteKeyDownRef: React.RefObject<
    ((event: KeyboardEvent) => boolean) | null
  > | null;
  setSearchQuery: SearchQuerySetter;
  searchQuery: SearchQuery;
}

// createEditorState is exported for tests
export function createEditorState(
  editorStateAndViewConfig: EditorStateAndViewConfig,
  /** The configuration exclusive to createEditorState */
  editorStateExclusiveConfig: {
    isStartingWithNoResults: boolean;
    initialDoc: Node;
  },
): EditorState {
  const {
    isReadOnly,
    dispatchToModel,
    setAutocompleteState,
    autocompleteKeyDownRef,
    searchQuery,
    setSearchQuery,
  } = editorStateAndViewConfig;
  const { isStartingWithNoResults, initialDoc } = editorStateExclusiveConfig;
  const handleListActionsPlugin = getHandleListActionsPlugin((note) =>
    dispatchToModel({ type: "insert", payload: [note] }),
  );

  return EditorState.create({
    doc: initialDoc,
    plugins: isReadOnly
      ? []
      : [
          persistencePlugin(dispatchToModel, searchQuery), // must be 2nd because it updates the model that many of the other plugins rely on
          fixTokenIdsPlugin,
          dropCursor({}),
          // autocomplete plugin comes next so it can trap arrow keys
          autocompletePlugin(
            [schema.nodes.paragraph],
            setAutocompleteState,
            autocompleteKeyDownRef,
          ),
          syncMarkPlugin,
          checkboxPlugin,
          getSplitNoteOnTripleDashPlugin(searchQuery),
          createNestedListPlugin,
          textExpanderPlugin({
            "→": /->$/g,
            "←": /<-$/g,
            "—": /--$/g,
          }),
          handleListActionsPlugin,
          codeblockInputPlugin,
          arrowKeyFixPlugin,
          dateDividerPlugin,
          // add note debugger plugin in non-prod environments
          ...(!isProd ? [noteDebuggerPlugin] : []),
          ...(isTouchDevice
            ? [
                getMobileNoteMenuPlugin((id: NoteId) =>
                  dispatchToModel({ type: `toggle-menu`, payload: { id } }),
                ),
              ]
            : []),
          ensureAtLeastOneNote({ isStartingWithNoResults }),
          toggleEllipsisPlugin,
          applyMarksPlugin,
          expansionEditMirrorPlugin(searchQuery),
          backlinkTogglePlugin,
          preventDeletionAcrossExpansionPlugin,
          fixDeletedExpandedNote,
          linkPreviewPlugin,
          fixDragDropPlugin,
          selectAllPlugin,
          history({ newGroupDelay: 300 }),
          keymapWithEventTracking(
            new Map<Shortcut, Command>([
              [
                shortcuts.splitNote,
                chainCommands(
                  getSplitNoteCommand(searchQuery),
                  appendExpandedReferenceCommand,
                ),
              ],
              [
                shortcuts.splitNote,
                chainCommands(
                  getSplitNoteCommand(searchQuery),
                  appendExpandedReferenceCommand,
                ),
              ],
              [shortcuts.undo, undo],
              [shortcuts.redo, redo],
              [shortcuts.selectAll, selectAll],
              [shortcuts.goToLineStart, goToLineStart],
              [shortcuts.goToLineEnd, goToLineEnd],
              [shortcuts.goToNoteStart, goToNoteStart],
              [shortcuts.goToNoteEnd, goToNoteEnd],
              [
                shortcuts.expandReference,
                chainCommands(
                  expandReferenceCommand,
                  searchQuery.type === "text" && searchQuery.isCondensed
                    ? expandEllipsisCommand
                    : () => false,
                ),
              ],
              [
                shortcuts.collapseReference,
                chainCommands(
                  collapseReferenceCommand,
                  searchQuery.type === "text" && searchQuery.isCondensed
                    ? collapseEllipsisCommand
                    : () => false,
                ),
              ],
              [shortcuts.expandBacklinks, expandBacklinksCommand],
              [shortcuts.collapseBacklinks, collapseBacklinksCommand],
              [shortcuts.selectUntilNoteEnd, selectToNoteEnd],
              [shortcuts.selectUntilNoteStart, selectToNoteStart],
              [
                shortcuts.backspace,
                chainCommands(
                  backspaceEllipsisReveal,
                  backspaceNoteCommand,
                  removeEmptyCodeBlock,
                  joinNotesOnBackspaceCommand,
                  collapsePrevExpandedNote,
                  joinListOnBackspaceCommand,
                  joinBackward,
                  deleteExpandedReferenceCommand,
                  selectNodeBackward,
                  deleteEmptyTopNoteOnBackspace,
                ),
              ],
              [
                shortcuts.delete,
                chainCommands(
                  backspaceNoteCommand,
                  joinNotesOnDeleteCommand,
                  joinForward,
                  selectNodeForward,
                ),
              ],
              [shortcuts.tab, indent],
              [shortcuts.shiftTab, dedent],
              [shortcuts.insertBr, insertBr],
              [shortcuts.insertParagraph, insertParagraphCommand],
              [shortcuts.clearFormatting, clearAllowedMarks],
              [
                shortcuts.toggleItalics,
                toggleAllowedMarks(schema.marks.italic),
              ],
              [shortcuts.toggleBold, toggleAllowedMarks(schema.marks.bold)],
              [
                shortcuts.toggleUnderline,
                toggleAllowedMarks(schema.marks.underline),
              ],
              [
                shortcuts.toggleStrikethrough,
                toggleAllowedMarks(schema.marks.strikethrough),
              ],
              [shortcuts.toggleCheckbox, toggleCheckbox],
              [shortcuts.openCurrentNote, getOpenNoteAsPage(setSearchQuery)],
              [
                shortcuts.searchForSelection,
                getSearchForSelection(setSearchQuery),
              ],
              [shortcuts.showInTimeline, getShowNoteInTimeline(setSearchQuery)],
            ]),
          ),
          keymap({
            "Meta-i": () => true,
          }),
          keymap(baseKeymap),
          highlightPlugin(searchQuery), // must appear at the end, because it depends on all the other plugins modifying the content.
        ],
  });
}
