import { Node } from "prosemirror-model";
import { schema } from "../schema";

function findDepth(line: string) {
  return line.match(/^(\s*)/)![0].length;
}

type LineNode = { root: string; children: LineNode[] };

function treeFromIndentedLines(lines: string[]): LineNode[] {
  if (lines.length === 0) return [];
  const [firstLine, ...remainingLines] = lines;
  const topLevelIndent = findDepth(firstLine);
  const groups = [];
  let currentGroup: string[] = [firstLine];
  for (const line of remainingLines) {
    const depth = findDepth(line);
    if (depth > topLevelIndent) {
      currentGroup.push(line);
    } else {
      groups.push(currentGroup);
      currentGroup = [line];
    }
  }
  groups.push(currentGroup);

  return groups.map(([root, ...rest]) => ({
    root: root.replace(/^(\s*)/, ""),
    children: treeFromIndentedLines(rest),
  }));
}

function linesToNodeTree(lines: string[]) {
  const lineTree = treeFromIndentedLines(lines);
  function remapNode(node: LineNode): Node {
    const match = node.root.match(/^<(\w+)\((\d+)-(\d+)-(.*)\)>/);
    // return { ...node, match, children: node.children.map(remapNode) };

    if (!match) {
      return schema.text(JSON.parse(node.root));
    }
    const type = match[1];
    const attrs = match[4];
    const attrsObj =
      attrs === ""
        ? undefined
        : Object.fromEntries(
            attrs
              .split("-")
              .map((prop) => [
                prop.split("=")[0],
                JSON.parse(prop.split("=")[1]),
              ]),
          );
    return schema.node(type, attrsObj, node.children.map(remapNode));
  }

  return lineTree.map(remapNode);
}

export function stringToNodeTree(s: string) {
  return linesToNodeTree(s.split("\n").filter((l) => !l.match(/^(\s*)$/)));
}

function nodeTreeToLines(
  node: Node,
  indent = 0,
  startOffsetInDoc = 0,
): string[] {
  const resultLines: string[] = [];
  const textIndent = "  ".repeat(indent);
  if (node.isText) {
    return [
      textIndent +
        '"' +
        node.text +
        '"' +
        node.marks.map((m) => m.type.name).join("#"),
    ];
  }
  const end = startOffsetInDoc + node.content.size;
  const data = { ...node.attrs };
  const parametersString = Object.entries(data)
    .map(([key, value]) => `${key}=${JSON.stringify(value)}`)
    .join("-");
  resultLines.push(
    textIndent +
      `<${node.type.name}(${startOffsetInDoc}-${end}-${parametersString})>`,
  );
  node.content.forEach((n, offset) =>
    resultLines.push(
      ...nodeTreeToLines(n, indent + 1, startOffsetInDoc + offset + 1),
    ),
  );
  return resultLines;
}

/** Helper function making it easier to debug/test the prosemirror state */
export function nodeTreeToString(node: Node) {
  return nodeTreeToLines(node).join("\n");
}
