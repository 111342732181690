import { useCallback, useEffect, useRef, useState } from "react";
import { useAtom } from "jotai";
import { useAuth } from "../../auth/useAuth";
import { useUpdateEditor } from "../../editorPage/atoms/editorUpdate";
import { isLoadedAtom } from "../../editorPage/atoms/isLoadedAtom";
import { useNotifySidebarUpdate } from "../../sidebar/atoms/sidebarUpdate";
import { loadFromCache, sendMessageToSW } from "./handleSwMessage";
import { noteList } from "../services";

// Hook returning true if page is visible, false otherwise.
const useIsPageVisible = () => {
  const [isPageVisible, setIsPageVisible] = useState(true);
  useEffect(() => {
    const onVisibilityChange = () => setIsPageVisible(!document.hidden);
    window.addEventListener("visibilitychange", onVisibilityChange);
    return () =>
      window.removeEventListener("visibilitychange", onVisibilityChange);
  }, []);
  return isPageVisible;
};

export const useSyncNotes = (isSyncWorkerReady: boolean) => {
  const { user } = useAuth();
  const isPageVisible = useIsPageVisible();
  const [isLoaded, setIsLoaded] = useAtom(isLoadedAtom);

  const notifyEditorUpdate = useUpdateEditor();
  const notifySidebarUpdate = useNotifySidebarUpdate();

  // initial load from cache
  useEffect(() => {
    const load = async () => {
      (window as any)?.enableDebug?.(localStorage.getItem("debug"));
      const checkpoint = await loadFromCache();
      // await sleep(100_000);
      if (checkpoint > 0) {
        setIsLoaded(true);
        notifyEditorUpdate();
        notifySidebarUpdate();
      }
      // otherwise we have to wait for the notification from the service worker.
    };
    load();
  }, [notifyEditorUpdate, notifySidebarUpdate, user, setIsLoaded]);

  const sync = useCallback(() => {
    if (!isSyncWorkerReady || !isLoaded) return;
    const dirtyNotes = noteList
      .getAll(true)
      .filter((n) => n.localRev !== n.masterRev);
    if (dirtyNotes.length) {
      sendMessageToSW({
        type: "upsert",
        mapType: "note",
        elements: dirtyNotes,
      });
    }
  }, [isSyncWorkerReady, isLoaded]);

  useSetIntervalWhenIdle(sync, 1_000, 10_000);

  // updates on timer
  useEffect(() => {
    if (!isSyncWorkerReady) return;
    // immediately attempt to ping the sw
    sendMessageToSW({ type: "ping" });
    const inter = setInterval(
      () => sendMessageToSW({ type: "ping" }),
      30_000, // should be enough time? https://bugs.chromium.org/p/chromium/issues/detail?id=647943
    );
    return () => clearInterval(inter);
  }, [isSyncWorkerReady, isPageVisible, isLoaded]);
};

// Wait until and execute every period after
function useSetIntervalWhenIdle(
  callback: () => void,
  rampUpTime: number,
  period: number,
) {
  const timer = useRef<null | number>(null);

  const executeAndSchedule = useCallback(() => {
    callback();
    // should be a no op
    if (timer.current) window.clearTimeout(timer.current);
    timer.current = window.setTimeout(executeAndSchedule, period);
  }, [callback, period]);

  const cancelAndSchedule = useCallback(() => {
    if (timer.current) window.clearTimeout(timer.current);
    timer.current = window.setTimeout(executeAndSchedule, rampUpTime);
  }, [executeAndSchedule]);

  useEffect(() => {
    noteList.addChangeListener(cancelAndSchedule);
    cancelAndSchedule();
    (window as any).addEventListener("beforeunload", callback);
    return () => {
      noteList.removeChangeListener(cancelAndSchedule);
      (window as any).removeEventListener("beforeunload", callback);
      if (timer.current) window.clearTimeout(timer.current);
    };
  }, [callback, cancelAndSchedule]);
}
