import { BlockToken, ListItemToken } from "./../model/types";
import { InlineToken } from "../model/types";
import { assertUnreachable } from "../utils/assertUnreachable";
import { joinCompatibleAdjacentInlineTokens } from "../editor/joinCompatibleAdjacentInlineTokens";
import { generateId } from "../model/generateId";

function fixInlineTokens(tokens: InlineToken[]): InlineToken[] {
  // Notes saved with an older version of the app may contain hashtag tokens
  // split into two adjacent tokens. This fixes it. (linear.app/ideaflow/issue/ENT-1525)
  return joinCompatibleAdjacentInlineTokens(
    tokens
      .map((token: InlineToken) => {
        switch (token.type) {
          case "text":
            if (token.content.length > 0) return token;
            else {
              return null;
            }
          case "checkbox":
          case "linkloader":
          case "link":
          case "image":
          case "hashtag":
            return token;
          case "spaceship":
            token.tokenId = token.tokenId || generateId();
            return token;
          default:
            assertUnreachable(token);
            console.warn("unknown inline token " + JSON.stringify(token));
            return { type: "text", content: JSON.stringify(token) };
        }
      })
      .filter((t) => Boolean(t)) as InlineToken[],
  );
}

export function fixTopLevelTokens(tokens: BlockToken[] | any): BlockToken[] {
  if (tokens.length === 0) {
    return [
      {
        tokenId: generateId(),
        type: "paragraph",
        content: [{ type: "text", content: "", marks: [] }],
      },
    ];
  }
  return tokens.map((token: BlockToken) => {
    switch (token.type) {
      case "paragraph":
      case "codeblock":
        return fixBlockToken(token);
      case "list": {
        const listItems: ListItemToken[] = [];
        token.content.forEach((listItem) => {
          const token: ListItemToken = {
            type: "listItem",
            content: listItem.content.map(fixBlockToken),
            depth: listItem.depth,
          };
          listItems.push(token);
        });
        return {
          type: "list",
          content: listItems,
        };
      }
      default:
        assertUnreachable(token);
        console.warn("Unknown token type " + (token as any).type);

        return {
          type: "paragraph",
          content: (token as any).content
            ? fixInlineTokens((token as any).content)
            : [{ type: "text", content: JSON.stringify(token) }],
        };
    }
  });
}

const fixBlockToken = (token: BlockToken): BlockToken => {
  switch (token.type) {
    case "paragraph":
      return {
        tokenId: token.tokenId || generateId(),
        type: "paragraph",
        content: fixInlineTokens(token.content),
      };
    case "codeblock":
      return {
        tokenId: token.tokenId || generateId(),
        type: "codeblock",
        content: token.content.filter((token) => token.content?.length > 0),
      };
    default:
      throw new Error("unknown token type" + (token as any).type);
  }
};
