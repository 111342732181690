import {
  EditorState,
  TextSelection,
  Selection,
  Command,
  Transaction,
} from "prosemirror-state";
import { EditorView } from "prosemirror-view";
import { noteList, notePositions } from "../../../model/services";
import { SearchQuery } from "../../../search/SearchQuery";
import { trackEvent } from "../../../analytics/analyticsHandlers";
import { schema } from "../../schema";
import { generatePathFromNoteId } from "../../utils/path";
import { preserveExpandedSpaceshipsAround } from "../reference/referenceExpansionUtils";
import { getParentNote } from "../../utils/find";

export const trSplitNote = (
  state: EditorState,
  transaction?: Transaction,
  folderId?: string,
): Transaction | null => {
  const tr = transaction || state.tr;
  const { $from } = state.selection;

  // Make sure we are not inside a reference
  const parentNode = $from.node(-1);
  const grandparentNode = $from.node(-2);
  if (
    grandparentNode?.type === schema.nodes.expandedReference ||
    parentNode?.type === schema.nodes.expandedReference
  ) {
    return null;
  }

  // Create a new note in the model positioned after the current note
  const [prevNoteNode] = getParentNote(tr.doc, $from.pos);
  if (prevNoteNode === null)
    throw new Error("Must be inside a note to split it");
  const prevNote = noteList.get(prevNoteNode.attrs.noteId);
  if (!prevNote) throw new Error("Note not found in noteList");
  const [note] = noteList.insert({
    position: notePositions.generateAfter(prevNote.position)[0],
    insertedAt: prevNote.insertedAt,
    folderId,
  });

  if (tr.selection instanceof TextSelection) tr.deleteSelection();
  preserveExpandedSpaceshipsAround(state, tr, () => {
    tr.split(tr.mapping.map($from.pos), 2, [
      {
        type: schema.nodes.note,
        attrs: {
          path: generatePathFromNoteId(note.id),
          noteId: note.id,
          insertedAt: note.insertedAt,
          folderId,
        },
      },
    ]);
  });

  // If the cursor is at the start of a note when the note is split, the user is
  // probably simply trying to create a new note at the top of the note stack,
  // and wants to keep the cursor there. We special-case this to keep the cursor
  // on prevNote [ENT-208]
  const noteStartPos = $from.start(1);
  const splitCursorPos = $from.pos - 1;
  if (noteStartPos === splitCursorPos) {
    const cursorPos = tr.doc.resolve(noteStartPos);
    tr.setSelection(Selection.near(cursorPos));
  }

  tr.setMeta("addToHistory", false);
  tr.scrollIntoView();

  return tr;
};

export const getSplitNoteCommand =
  (searchQuery: SearchQuery): Command =>
  (state: EditorState, dispatch?: EditorView["dispatch"]): boolean => {
    if (!dispatch) return false;
    const folderId = searchQuery.type === "folder" ? searchQuery.q : undefined;
    const tr = trSplitNote(state, state.tr, folderId);
    if (tr == null) return false;
    dispatch(tr);
    trackEvent(["NOTE", folderId ? "SPLIT_FROM_FOLDER" : "SPLIT"]);
    return true;
  };
