import { ResolvedPos } from "prosemirror-model";
import { Plugin, PluginKey } from "prosemirror-state";
// Selects the note or inner-most expanded note that contains the

import {
  Command,
  EditorState,
  TextSelection,
  Transaction,
} from "prosemirror-state";
import { schema } from "../../schema";

// selection.$anchor.
export const selectAll: Command = (state, dispatch) => {
  const t = getSelectAllTransaction(state);
  if (t) {
    dispatch?.(t);
    return true;
  }
  return false;
};

function findNearestNoteNode(resolvedPos: ResolvedPos) {
  // taken from getParentNote
  let i = 0;
  for (; ; i++) {
    const node = resolvedPos.node(resolvedPos.depth - i);
    if (!node) return undefined;
    if (node.type === schema.nodes.note) break;
  }

  const depth = resolvedPos.depth - i;
  return depth;
}

// implementation helper for selectAll, which can also be used external to this module
// when a single transaction is needed
function getSelectAllTransaction(state: EditorState): Transaction | undefined {
  const from = state.selection.$from;
  const to = state.selection.$to;

  const fromNoteDepth = findNearestNoteNode(from);
  const toNoteDepth = findNearestNoteNode(to);

  if (fromNoteDepth === undefined || toNoteDepth === undefined) {
    return undefined;
  }

  const nodeStart = from.start(fromNoteDepth);
  const nodeEnd = to.end(toNoteDepth);

  // only select the text from the notes in the select (prevent selecting all notes)
  return state.tr.setSelection(
    TextSelection.create(state.doc, nodeStart, nodeEnd),
  );
}

export const selectAllPlugin = new Plugin({
  key: new PluginKey("detectSelectAll"),
  appendTransaction: (transactions, oldState, newState) => {
    // Detect a native "Select All," such as using the edit menu
    // (popover/tooltip thing) in iOS. This code isn't platform-specific.  It
    // also detects and handle some cases of "Edit > Select All" chosen with the
    // mouse on desktop.  (Android is not tested.) To detect a select-all, we
    // look for the selection going to a whole-document selection in one shot.
    // If the user is dragging to select, we don't want to change the behavior
    // of that, but in that case, there will be a series of transactions
    // changing the selection as the user drags.
    if (transactions.length !== 1) {
      return;
    }
    const tr = transactions[0];
    if (!tr.docChanged && tr.selectionSet && !newState.selection.empty) {
      const selStart = newState.selection.from;
      const selEnd = newState.selection.to;
      const doc = newState.doc;
      const docSize = doc.content.size;
      // Detect whether the new selection is a whole-document selection.
      // Check if the part of the document before the selection consists only of block
      // openers, and the part of the document after the selection consists only of block
      // closers.  We don't want to be too sensitive to the exact document schema.  The
      // cut-off of 20 is for performance reasons, to avoid creating a large slice for no
      // reason.  We also don't fire if the document has only one child, because suppose
      // there is one note with one character, for example; we don't want to treat selecting
      // that character as a select-all.
      if (
        selStart < 20 &&
        doc.slice(0, selStart).openEnd === selStart &&
        docSize - selEnd < 20 &&
        doc.slice(selEnd, docSize).openStart === docSize - selEnd &&
        doc.childCount > 1
      ) {
        // pass oldState because it has the original selection (and same document)
        return getSelectAllTransaction(oldState);
      }
    }
  },
});
