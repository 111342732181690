import { createEditorState, EditorStateAndViewConfig } from "./editorState";

import { EditorState, Transaction } from "prosemirror-state";

import debug from "debug";

import { schema } from "./schema";
import { Note } from "../model/types";
import { notesToProsemirrorNodes } from "./bridge";

import { serializeNode } from "./utils/serializeNode";
import { handleDrop, handlePaste } from "./utils/clipboard/handlePaste";
import { clipboardTextParser } from "./utils/clipboard/clipboardTextParser";
import { EditorView } from "prosemirror-view";
import { handleClickOn } from "./features/link/handleClickOn";

import { ReferenceView } from "./features/reference/ReferenceView";
import { ImageView } from "./features/image/ImageView";
import { LinkLoaderView } from "./features/link/LinkLoaderView";
import { HashtagView } from "./features/hashtag/HashtagView";
import { CheckboxView } from "./features/checkbox/CheckboxView";

import {
  transformCopied,
  transformPasted,
  transformPastedHTML,
} from "./utils/clipboard/transformPasted";
import {
  isContinuousIntegration,
  isIOs,
  isTransactionLogEnabled,
} from "../utils/environment";
import { Node } from "prosemirror-model";
import { getTransactionOrigin } from "./transactionTraps";

import { ExpandedReferenceView } from "./features/reference/ExpandedReferenceView";
import { nodeTreeToString } from "./utils/nodesToString";
import { SearchQuery } from "../search/SearchQuery";
import { Hit } from "../search/find";
import { Message } from "../modal/messageAtom";
import { AuthenticatedServerFetch } from "../auth/AccessTokenManager";
import { exposeIntegrationTestsViewHooks } from "./integrationTestsHooks";
export interface EditorViewConfig extends EditorStateAndViewConfig {
  div: HTMLElement;
  initialNotes: Hit<Note>[];
  searchQuery: SearchQuery;
  noteToFocusAtInit: string | null;
  setMessage: (m: Message) => void;
  authenticatedServerFetch: AuthenticatedServerFetch;
}

export interface Editor {
  view: EditorView;
  destroy: () => void;
}

export function createEditorView(args: EditorViewConfig): Editor {
  const {
    div,
    initialNotes,
    noteToFocusAtInit,
    searchQuery,
    setSearchQuery,
    setMessage,
    isReadOnly,
    authenticatedServerFetch,
  } = args;

  div.classList.add("editor-div");
  // makes sure that the first word after a period is autocapitalized on safari
  div.autocapitalize = "sentences";
  div.id = "editor-div";
  if (isIOs) {
    div.classList.add("editor-div-ios");
  }

  const initialDoc = schema.nodes.doc.create(
    null,
    notesToProsemirrorNodes(
      initialNotes,
      searchQuery.isCondensed,
      "/",
      (note: Note) => ({
        highlighted: note.id === noteToFocusAtInit,
        folderId: note.folderId,
      }),
    ),
  );

  const state = createEditorState(args, {
    initialDoc,
    isStartingWithNoResults: initialNotes.length === 0,
  });

  const tr = state.tr;

  // apply expansion state
  // applyExpansionState(tr);

  const newState = state.apply(tr);

  const view = new EditorView(
    { mount: div },
    {
      state: newState,
      clipboardTextSerializer: serializeNode,
      // clipboardSerializer, // see comments in file for explanation
      handleClickOn,
      handlePaste: handlePaste(setMessage, authenticatedServerFetch),
      handleDrop: handleDrop(setMessage, authenticatedServerFetch), // Prosemirror types for the handleDrop are broken
      transformCopied,
      transformPasted,
      transformPastedHTML,
      clipboardTextParser: clipboardTextParser(schema),
      editable: (editorState) => {
        if (isReadOnly) return false;
        const doc = editorState.doc;
        return doc.childCount > 0;
      },
      // DEBUG: Set NEXT_PUBLIC_LOG_EDITOR_TRANSACTION=true in .env to enable logging of editor states and transactions
      // This is useful for preparing editor tests since the editor state before and after some operation
      // can be copied over into the test
      dispatchTransaction: isTransactionLogEnabled
        ? (tr) => {
            debug("editor")("before", nodeTreeToString(view.state.doc));
            let state: EditorState, transactions: readonly Transaction[];
            try {
              ({ state, transactions } = view.state.applyTransaction(tr));
            } catch (err) {
              console.error("transaction origin", getTransactionOrigin(tr));
              throw err;
            }
            debug("editor")("after", nodeTreeToString(state.doc));
            debug("editor")({ appliedTransactions: transactions });
            debug("editor")(
              "Transaction origins",
              ...transactions.map((tr) => getTransactionOrigin(tr)),
            );
            view.updateState(state);
          }
        : undefined,
      nodeViews: {
        image: (node: Node, view: EditorView, getPos) =>
          new ImageView(node, view, getPos, isReadOnly),
        linkLoader: (node, view, getPos) =>
          new LinkLoaderView(node, view, getPos, authenticatedServerFetch),
        hashtag: () => new HashtagView(setSearchQuery, isReadOnly),
        reference: (node: Node, view: EditorView, getPos) =>
          new ReferenceView(node, view, getPos),
        checkbox: (node: Node, view: EditorView, getPos) =>
          new CheckboxView(node, view, getPos, isReadOnly),
        expandedReference: (node: Node, view: EditorView, getPos) =>
          new ExpandedReferenceView(node, view, getPos, setSearchQuery),
      },
    },
  );

  // Expose some helper functions for copy-paste handling in integration tests
  if (isContinuousIntegration) {
    exposeIntegrationTestsViewHooks(view);
  }

  if (isReadOnly) div.contentEditable = "false";

  div.spellcheck = false;

  return {
    view,
    destroy: () => view.destroy(),
  };
}
