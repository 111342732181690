import { Plugin, PluginKey, Transaction } from "prosemirror-state";

import { Selection } from "prosemirror-state";
import { generateId } from "../../../model/generateId";
import { noteList } from "../../../model/services";
import { schema } from "../../schema";
import { generatePathFromNoteId } from "../../utils/path";

const PLUGIN_KEY = new PluginKey("ensureAtLeastOneNote");

function transactionAddsNote(tr: Transaction): boolean {
  let addsNote = false;
  tr.doc.forEach((node) => {
    if (node.type === schema.nodes.note) {
      addsNote = true;
    }
  });
  return addsNote;
}

/**
 * HACK: ensureAtLeastOneNote.appendTransaction sometimes gets called twice with the
 * same transaction, which shouldn't happen. But it does, so this is a fairly
 * clean shim to get around that issue.
 *
 * The problem with seeing the same tr multiple times is that we end up
 * inserting multiple new notes into the noteList. Instead, we can keep a
 * WeakMap of transactions to note IDs, so we insert the same note into the
 * noteList if we see the same transaction multiple times.
 */
const transactionToDefaultNote = new WeakMap<Transaction, string>();
function generateDefaultNoteId(trs: readonly Transaction[]): string {
  const defaultNoteId = generateId();
  for (const tr of trs) {
    const existingNoteId = transactionToDefaultNote.get(tr);
    // if we've seen this transaction before, grab the old id and return
    if (existingNoteId) {
      return existingNoteId;
    }
    transactionToDefaultNote.set(tr, defaultNoteId);
  }
  // these are all-new transactions. Return the generated ID
  return defaultNoteId;
}

interface PluginArgs {
  isStartingWithNoResults: boolean;
}

/**
 * ensureAtLeastOneNotePlugin addresses a Thoughtstream/Prosemirror interop
 * issue that causes editor state corruption.
 *
 * == PROBLEM ==
 * When all editor content (all notes in the editor) is selected and deleted
 * somehow, ProseMirror ends up with a doc with zero nodes, i.e. an empty
 * document. This makes sense for PM but is an invalid state for Thoughtstream
 * docs to be in, because for a user to type something, *the doc must have at
 * least one note at all times*. This is thus an invariant of the Editor.
 * Typing when there are no notes results in ProseMirror crashing.
 *
 * == SOLUTION ==
 * We address this by checking every transaction to see if it results in a doc
 * with no notes, and adding a default note if that's the case. This ensures
 * the at-least-one-note invariant is always upheld.
 *
 * See also: https://linear.app/ideaflow/issue/ENT-470/typeerror-cannot-read-property-nodesize-of-undefined
 */
export function ensureAtLeastOneNote({ isStartingWithNoResults }: PluginArgs) {
  return new Plugin<{ hasResults: boolean }>({
    key: PLUGIN_KEY,
    state: {
      init: () => {
        return { hasResults: !isStartingWithNoResults };
      },
      apply: (tr, pluginState) => {
        if (pluginState.hasResults) return pluginState;

        if (transactionAddsNote(tr)) {
          return { hasResults: true };
        }
        return pluginState;
      },
    },
    appendTransaction(trs, _, state) {
      if (state.doc.childCount > 0) return;

      // If on an empty search results page, do nothing.
      const pluginState = PLUGIN_KEY.getState(state);
      if (!pluginState.hasResults) return;

      const id = generateDefaultNoteId(trs);
      if (noteList.get(id)) {
        return null;
      }
      const [note] = noteList.insert({ id });

      const editorNote = state.schema.nodes.note.create(
        {
          noteId: note.id,
          path: generatePathFromNoteId(note.id),
        },
        state.schema.nodes.paragraph.create(),
      );

      // Move cursor to start of doc
      const transaction = state.tr.insert(0, editorNote);
      const selection = Selection.atStart(transaction.doc);
      transaction.setSelection(selection);

      return transaction;
    },
  });
}
