import { NodeSpec } from "prosemirror-model";
import { domAttrs, getDefaultAttrs } from "../../utils/attrs";

export const bulletList: NodeSpec = {
  content: "bulletChild+",
  group: "topLevelNode",
  parseDOM: [{ tag: "ul" }],
  toDOM() {
    return ["ul", { class: "bullet-list" }, 0];
  },
};

export const listItem: NodeSpec = {
  content: "blockNode+",
  group: "bulletChild",
  defining: true,
  attrs: {
    depth: { default: 0 },
  },
  parseDOM: [
    { tag: "li", getAttrs: getDefaultAttrs([{ key: "depth", type: "int" }]) },
  ],
  toDOM(node) {
    return [
      "li",
      {
        ...domAttrs(node, ["depth"]),
        class: "list-item",
        style: `
          margin-left: ${node.attrs.depth * 24}px;
          list-style-type: disc;
          `,
      },
      0,
    ];
  },
};
