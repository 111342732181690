import { nanoid } from "nanoid";
import localforage from "./../../utils/localforage";
/**
 * RohanCache persists PWA offline data with ease and epic speed.
 *
 * PWA needs to:
 * - keep the memory footprint as low as possible.
 * - save a lot of data fast in the cache when saving the cache the first time,
 * - save a small subset of data that changes often when persisting offline edits,
 * - detect if there is new data in the cache (multiple tabs or webworkers...),
 * - handle objects of different types,
 * - handle different revisions of data structures,
 * - handle concurrency quite well due to the multiple tabs and devices that can display the app at once,
 * - work around platform specific incompatibilities,
 *
 * RohanCache has taken all those constraints into account. It takes inspiration from PostgreSql, AbsurdSql, PouchDb.
 *
 * I - Backend
 * It uses IndexedDB for its capacity, through localforage for its compatibility and fallback to LocalStorage.
 * You should only have one instance of RohanCache in your app (see concurrency).
 *
 * II - Physical Layout
 * To circumvent the slowness of IndexedDB updates [1],
 * all data of a given datatype is stored into 2 files: one main and one delta.
 * The updates are batched into the delta file which is periodically merged to the main cache via a vacuum process.
 *
 * The delta file could also be used to replay edits asynchronously on an API for instance,
 * but this API is right now not supported.
 *
 * III - Logical Layout
 * Each type can be stored into its own table, in a normalized fashion.
 * The advantage of normalizing data is that it usually maps quite well with API and updates.
 *
 * IV - Concurrency
 * Concurrency is handled via a mutex that automatically expires after 2s (configurable).
 * Operations are only async-mono-thread safe, so typically 2 tabs can make it fail.
 *
 * A typical save operation takes <1ms and vacuum 20ms every hour.
 * If saves are tight to user actions only, it is very hard to have 2 saves happening at once.
 *
 * However, if saves happen automatically every 1s,
 * a user with 10 tabs will hit a race condition with a 4.8% probability (birthday paradox),
 * which is unacceptable.
 * If you need to save something in the cache at regular intervals, make sure that only one tab per user does so.
 * For instance, by doing it in a service worker, or by electing a master tab.
 *
 *
 * V - Example usage
 *
 * const cache = new RohanCache(['notes', 'folders'], 'after_adding_ids');
 * const notesAndFolders = await cache.load();
 * await saveDelta({
 *  notes: {
 *      'a': {name: 'my cool note'},
 *  },
 *  folders: {
 *      'b': {name: 'my cool folder'}
 *      'c': null, // delete folder c.
 *  }
 * });
 *
 * [1] https://jlongster.com/future-sql-web
 *
 */

type Row = any;

interface Delta {
  [itemId: string]: Row;
}

type Firehose = Delta;

interface TableData {
  dataRevision: string | null; // data revision
  schemaRevision: string | null; // each time the data in the cache changes representation due to a migration or a model evolution, one can use this format number to migrate data in the cache
  data: Firehose;
}

type SchemaData = { [tableId: string]: TableData } & {
  dataRevision: string;
  isFresh: boolean;
};

type RohanDelta = { [tableId: string]: Delta };

export class RohanCache {
  public mutex = new Mutex();
  public tables: Map<string, RohanTable> = new Map();
  public lastSavedOrLoaded: Map<string, string> = new Map();

  /**
   * Creates a new Schema.
   *
   * All operations on this Schema should manage concurrency properly.
   * The sce
   * @param tables
   * @param schemaRevision
   */
  constructor(tables: string[], private schemaRevision: string) {
    tables.forEach((id) =>
      this.tables.set(id, new RohanTable(id, this.schemaRevision)),
    );
    // eslint-disable-next-line no-eval
    if (eval('typeof window !== "undefined"')) {
      (window as Window).setInterval(() => this.vaccum(), 1_000 * 60 * 60); // run vacuum every hour, for long running operations
      this.vaccum(); // we don't await, which should be fine
    }
  }

  /**
   * Loads from cache all tables.
   *
   * Each table data can have a different data revision,
   * but all tables will share the same schema revision.
   *
   * Use the data revision to know if you need to process the data from the cache or if you already have processed it.
   * If the schema revision do not match, the whole cache will be busted.
   */
  async load(): Promise<SchemaData> {
    const output: Partial<SchemaData> = {};
    const dataRevision = new Map();
    await this.mutex.runExclusive(async () => {
      for (const [id, table] of this.tables.entries()) {
        output[id] = await table.load();
        dataRevision.set(id, output[id]!.dataRevision);
      }
    });
    output.dataRevision = this.buildVersionNumber(dataRevision);
    output.isFresh =
      output.dataRevision !== this.buildVersionNumber(this.lastSavedOrLoaded);
    this.lastSavedOrLoaded = dataRevision;
    return output as SchemaData;
  }

  private buildVersionNumber(map: Map<string, string>) {
    return [...map.values()].join("/");
  }

  /**
   * Saves a delta of the Schema.
   *
   * When resolved, the data has been really saved - aka committed to disk.
   *
   * Rows are cancelled and replaced.
   * Oe can delete one entry by setting its entry to null.
   * It is important to provide the whole Row when saving.
   */
  async saveDelta(delta: RohanDelta) {
    await this.mutex.runExclusive(async () => {
      for (const [id, data] of Object.entries(delta)) {
        const dataRevision = await this.tables.get(id)!.saveDelta(data);
        this.lastSavedOrLoaded.set(id, dataRevision);
      }
    });
  }

  /**
   * Optimizes the table for performance.
   *
   * This operation performs some maintenance on the data to improve performance.
   * It will be done from time to time automatically (every hour and when instantiating the class).
   * It can be called explicitely to raise update performances after a large load for instance.
   *
   * Full will also delete the empty rows, only do it when you are done syncing the empty rows.
   * @returns Promise<void>
   */
  vaccum(full = false) {
    return this.mutex.runExclusive(async () => {
      for (const table of this.tables.values()) {
        await table.vacuum(full);
      }
    });
  }

  /**
   * Busts the cache.
   */
  clear() {
    return this.mutex.runExclusive(async () => {
      for (const table of this.tables.values()) {
        await table.clear();
      }
    });
  }
}

/**
 * Basic and fast Mutex implementation.
 *
 * When the mutex cannot be acquired, the app waits before retrying to acquire the mutex.
 * When hitting TTL, the lock expires.
 */
const wait = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));

class Mutex {
  private _isLocked = false;
  private _start = 0;

  constructor(private TTL_MS = 2_000) {}

  private async _acquire() {
    // we can acquire the lock if it is free or expired
    while (this._isLocked && Date.now() - this._start < this.TTL_MS) {
      await wait(1_000 / 60); // wait at least one frame.
    }
    this._isLocked = true;
    this._start = Date.now();
  }

  private _release() {
    this._isLocked = false;
  }

  /**
   * Runs the sync or async block of code exclusively.
   *
   * Bundle up all the critical section inside a function,
   * and you will have guarantee that it runs exclusively.
   * The mutex expires after TTL_MS milliseconds (check constructor).
   *
   * @param fn a function containing the critical section.
   *
   * @returns Promise<any>
   */
  async runExclusive<T>(fn: (...args: any) => T | Promise<T>): Promise<T> {
    await this._acquire();
    const start = this._start; // copy
    const r = await fn();
    if (start === this._start) this._release(); // only release if we didnt expire
    return r;
  }
}

function removeEmpty(obj: any) {
  return Object.fromEntries(Object.entries(obj).filter(([_, v]) => v !== null));
}
/**
 *  Represents a collection of objects ready to be persisted.
 *  Works with a WAL, to allow reducing the performance impact of serializing, deserializing and persisting
 *  large data set.
 * Need to be vaccuumed at regular intervals
 */
class RohanTable {
  static createEmptyTable() {
    return {
      dataRevision: null,
      schemaRevision: "",
      data: {},
    };
  }

  constructor(private tableName: string, private schemaRevision: string) {}

  private _isCache(cache: any): cache is TableData {
    return (
      !!cache &&
      cache.schemaRevision === this.schemaRevision &&
      cache.dataRevision !== ""
    );
  }

  private _deltaName() {
    return "rohan_" + this.tableName + "_delta";
  }
  private _mainName() {
    return "rohan_" + this.tableName;
  }

  /**
   * Loads the table from cache.
   *
   * It will merge the main cache and the WAL cache into one list,
   * and will also remove the empty entries.
   *
   * The empty entries can be convenient to delete RAM data.
   */
  async load(): Promise<TableData> {
    let cacheDelta = await localforage.getItem<TableData>(this._deltaName());
    if (!this._isCache(cacheDelta)) {
      await localforage.removeItem(this._deltaName());
      cacheDelta = RohanTable.createEmptyTable();
    }

    let cache: TableData | null = await localforage.getItem(this._mainName());
    if (!this._isCache(cache)) {
      await localforage.removeItem(this._mainName());
      cache = RohanTable.createEmptyTable();
    }
    Object.assign(cache.data, cacheDelta.data);
    return {
      dataRevision: cacheDelta.dataRevision ?? cache.dataRevision,
      schemaRevision: cacheDelta.schemaRevision ?? cache.schemaRevision,
      data: cache.data,
    };
  }

  async saveDelta(data: Delta) {
    const delta = await this._merge(this._deltaName(), data);
    delta.dataRevision = nanoid(8);
    await localforage.setItem(this._deltaName(), delta);
    return delta.dataRevision;
  }

  /**
   * Optimizes the table for performance.
   *
   * Full vacuum will remove the empty entries as well.
   * Only use it when you are done syncing deleted rows in RAM.
   */
  async vacuum(full = false) {
    const delta = await localforage.getItem<TableData>(this._deltaName());
    if (!this._isCache(delta)) {
      await localforage.removeItem(this._deltaName());
      if (!full) {
        // no delta to merge, or invalid, skip the partial vacuum
        // full vacuum still needs to remove empty items from the main table part
        return;
      }
    }

    const merged = await this._merge(
      this._mainName(),
      delta ? delta.data : RohanTable.createEmptyTable().data,
    );
    merged.dataRevision = delta ? delta.dataRevision : merged.dataRevision;
    if (full) merged.data = removeEmpty(merged.data);
    await localforage.setItem(this._mainName(), merged);
    await localforage.removeItem(this._deltaName());
  }

  /**
   * Clears the table.
   */
  async clear() {
    await localforage.removeItem(this._mainName());
    await localforage.removeItem(this._deltaName());
  }

  /**
   * Merges the data from the key entry.
   *
   * When vacuum is true, the data is cleaned up.
   * @param key location of the save
   * @param data data to be saved
   */
  private async _merge(key: string, data: Delta) {
    const delta: TableData =
      (await localforage.getItem(key)) ?? RohanTable.createEmptyTable();
    delta.data = { ...delta.data, ...data };
    delta.schemaRevision = this.schemaRevision; // we assume that
    return delta;
  }
}
