import isEqual from "lodash.isequal";
import difference from "lodash.difference";
import { Node } from "prosemirror-model";
import { EditorState } from "prosemirror-state";
import { NoteId } from "../../model/types";
import { descendNotes } from "./descendNotes";
import { getTopLevelTokensFromContent } from "../bridge";
import { schema } from "../schema";

export const getNotesThatChangedInTree = (
  oldDoc: EditorState["doc"],
  newDoc: EditorState["doc"],
): { deletes: NoteId[]; upserts: Node[] } => {
  // Collect all note nodes in the old doc
  const oldNotes = new Map<string, { node: Node; pos: number }>();
  const oldNoteIds = new Set<string>();
  descendNotes(oldDoc, (note, pos) => {
    oldNotes.set(note.attrs.path, { node: note, pos });
    if (note.attrs.depth === 0) {
      oldNoteIds.add(note.attrs.noteId);
    }
  });

  // Compare the old and new note nodes
  const newNoteIds = new Set<string>();
  const upserts: Node[] = [];
  descendNotes(newDoc, (note, pos) => {
    if (note.attrs.depth === 0) {
      newNoteIds.add(note.attrs.noteId);
    }
    const oldNote_ = oldNotes.get(note.attrs.path);
    if (!oldNote_) {
      // The note node is new
      upserts.push(note);
      return;
    }
    const { node: oldNote, pos: oldNodePos } = oldNote_;
    if (oldNote === note) {
      // If the note node is the same object, none of its children have changed,
      // so we don't need to descend into it.
      return false;
    }

    // If the note node is different, but the content is the same,
    // we still consider it unchanged.
    const newContent = getTopLevelTokensFromContent(note.content);
    const oldContent = getTopLevelTokensFromContent(oldNote.content);
    const newHasAutocomplete = newDoc.rangeHasMark(
      pos,
      pos + note.nodeSize - 2,
      schema.marks.autocompleteRegion,
    );
    const oldHasAutocomplete = oldDoc.rangeHasMark(
      oldNodePos,
      oldNodePos + oldNote.nodeSize - 2,
      schema.marks.autocompleteRegion,
    );
    if (
      newHasAutocomplete !== oldHasAutocomplete ||
      !isEqual(newContent, oldContent)
    ) {
      upserts.push(note);
    }
  });

  const deletes = difference([...oldNoteIds], [...newNoteIds]);

  return { deletes, upserts };
};
