import { EditorView } from "prosemirror-view";
import {
  copyCurrentSelectionAsHTML,
  __clipboardTestingUtils,
} from "./utils/clipboard/__clipboardTestingUtils";

export function exposeIntegrationTestsViewHooks(view: EditorView) {
  window.copyCurrentSelectionAsHTML = () => {
    return copyCurrentSelectionAsHTML(view);
  };

  window.pasteHtml = (html: string) => {
    const { pasteEvent } = __clipboardTestingUtils.newPasteEvent(view, {
      dataType: "text/html",
      data: html,
    });

    view.dom.dispatchEvent(pasteEvent);
  };
}
