import { Plugin, PluginKey } from "prosemirror-state";
import { getNotesThatChangedInTree } from "../getNotesThatChangedInTree";
import { applyMarks } from "./applyMarks";

/**
 * There are quite a few mark types that we want to apply automatically as a
 * function of syntax rather than by user choice.  This plugin applies those
 * marks on every edit so it stays consistent with the editor state.
 */
export const applyMarksPlugin = new Plugin({
  key: new PluginKey("applyMarks"),
  appendTransaction(trs, oldState, newState) {
    if (trs[0].getMeta("addToHistory") === false) {
      return;
    }
    if (trs[0].getMeta("type") === "highlight") return;

    const tr = newState.tr;
    tr.setMeta("addToHistory", false);
    tr.setMeta("type", "apply-marks");
    const nodesThatChanged = getNotesThatChangedInTree(oldState.doc, tr.doc);
    applyMarks(tr, nodesThatChanged.upserts);
    // only append the transaction if applyMarks made changes to the doc
    return tr.docChanged ? tr : null;
  },
});
