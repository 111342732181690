import { isApple } from "../utils/environment";
import { shortcuts as s, Shortcut, NO_KEYBINDING } from "./getShortcuts";
import { removeDiacritics } from "../search/find";

export const shortcuts = s;

export const formatAndSplitKeyString = (keys: string): string[] => {
  if (isApple) {
    keys = keys.replace("Alt", "⌥").replace("Meta", "⌘");
  }
  keys = keys
    .replace(" ", "space")
    .replace("Escape", "Esc")
    .replace("ArrowUp", "↑")
    .replace("ArrowDown", "↓")
    .replace("ArrowLeft", "←")
    .replace("ArrowRight", "→");
  keys = removeDiacritics(keys);
  return keys.split("-").map((c) => (c.length === 1 ? c.toUpperCase() : c));
};

export const getShortcutString = (shortcuts: Shortcut) => {
  const { keys } = shortcuts;
  const combo = formatAndSplitKeyString(keys);
  return `( ${combo.join(" + ")} )`;
};

export type { Shortcut };
export { NO_KEYBINDING };
