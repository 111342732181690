import React from "react";
import { Cta } from "./Cta";

export const CtaBanner = ({
  height,
  text,
  ctaText,
  onClick,
}: {
  height: number;
  text: string;
  ctaText: string;
  onClick: () => void;
}) => {
  return (
    <div
      style={{
        height,
        backgroundColor: "black",
        color: "white",
        position: "fixed",
        bottom: 0,
        left: 0,
        width: "100%",
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        zIndex: 2,
      }}
    >
      <div style={{ fontSize: 24, marginRight: "24px" }}>{text}</div>
      <div>
        <Cta onClick={onClick} style={{ fontSize: 16, padding: "12px 32px" }}>
          {ctaText}
        </Cta>
      </div>
    </div>
  );
};
