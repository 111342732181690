import { Slice, Fragment, Node, Attrs } from "prosemirror-model";
import { generateId } from "../../../model/generateId";
import { noteFormatter, noteList } from "../../../model/services";
import { removeEllipsis } from "../../features/ellipsis/removeEllipsis";
import { schema } from "../../schema";
import { generatePathFromNoteId } from "../path";
import { regexpMatchers } from "../mark/regexpMatchers";

// When pasting notes we need to generate new ids for them
// We want to generate the new id from an old id in a stable way, so when
// using the same mapper reference ids will be correctly translated
function createNoteIdMapper() {
  const oldNoteIdToNewNoteId = new Map<string, string>();
  return (oldId: string) => {
    let newId = oldNoteIdToNewNoteId.get(oldId);
    if (newId === undefined) {
      newId = generateId();
      oldNoteIdToNewNoteId.set(oldId, newId);
    }
    return newId;
  };
}
function deepMapNodeTree(
  node: Node,
  mapper: (node: Node) => Partial<Attrs> | Node | Fragment | null,
): Fragment | null;
function deepMapNodeTree(
  node: Fragment,
  mapper: (node: Node) => Partial<Attrs> | Node | Fragment | null,
): Fragment;
function deepMapNodeTree(
  node: Node | Fragment,
  mapper: (node: Node) => Partial<Attrs> | Node | Fragment | null,
): Fragment | null {
  if (node instanceof Node) {
    const newAttrs = mapper(node);
    if (!newAttrs) return null;
    if (newAttrs instanceof Node) return Fragment.from(newAttrs);
    if (newAttrs instanceof Fragment) return newAttrs;
    if (node.isText) {
      console.error("You must map every text node to a text node");
      return Fragment.from(node);
    }
    return Fragment.from(
      node.type.create({ ...node.attrs, ...newAttrs }, getMappedChildren()),
    );
  } else {
    return getMappedChildren();
  }
  function getMappedChildren() {
    const mappedChildren: Node[] = [];
    (node instanceof Fragment ? node : node.content).forEach((n) => {
      const mappedChildNode = deepMapNodeTree(n, mapper);
      if (!mappedChildNode) return;
      if (mappedChildNode instanceof Node) mappedChildren.push(mappedChildNode);
      if (mappedChildNode instanceof Fragment)
        mappedChildNode.forEach((child) => mappedChildren.push(child));
    });
    return Fragment.fromArray(mappedChildren);
  }
}

function findChildNodesGroupedIntoTextGroups(node: Node) {
  if (node.isText) {
    return [{ isText: true as const, textNodes: [node] }];
  }
  const children: (
    | { isText: true; textNodes: Node[] }
    | { isText: false; node: Node }
  )[] = [];
  let textAccumulator: null | Node[] = null;
  const flushTextAccumulator = () => {
    if (textAccumulator !== null) {
      children.push({ isText: true, textNodes: textAccumulator });
      textAccumulator = null;
    }
  };
  node.content.forEach((n) => {
    if (n.isText) {
      textAccumulator = [...(textAccumulator ?? []), n];
    } else {
      flushTextAccumulator();
      children.push({ isText: false, node: n });
    }
  });
  flushTextAccumulator();
  return children;
}

function remapMatches(
  node: Node,
  regex: RegExp,
  createNodesFromMatch: (match: RegExpMatchArray) => Node[],
) {
  return deepMapNodeTree(node, (node) => {
    // Don't recurse into the codeblock nodes (we don't search for matches there)
    if (node.type === schema.nodes.codeblock) return node;
    if (node.type !== schema.nodes.paragraph && !node.isText) return {};
    const children = findChildNodesGroupedIntoTextGroups(node);
    const mappedChildren = children.flatMap((c) =>
      c.isText ? processText(c.textNodes) : [c.node],
    );

    if (node.type === schema.nodes.paragraph) {
      return schema.nodes.paragraph.create(node.attrs, mappedChildren);
    } else {
      return Fragment.from(mappedChildren);
    }
  })!;

  function processText(textNodes: Node[]): Node[] {
    if (textNodes.length === 0) return [];
    regex.lastIndex = 0;
    const firstMatch = regex.exec(
      textNodes.map((tn) => tn.textContent).join(""),
    );
    if (!firstMatch) return textNodes;

    const { textNodesBeforeMatch, textNodesAfterMatch } =
      spliceOutRangeFromTextNodes(
        textNodes,
        firstMatch.index,
        firstMatch.index + firstMatch[0].length,
      );

    return [
      ...textNodesBeforeMatch,
      ...createNodesFromMatch(firstMatch),
      ...processText(textNodesAfterMatch),
    ];
  }
}

function spliceOutRangeFromTextNodes(
  textNodes: Node[],
  startPosition: number,
  endPosition: number,
) {
  const textNodesBeforeMatch: Node[] = [];
  const textNodesAfterMatch: Node[] = [];
  let position = 0;
  for (const textNode of textNodes) {
    if (position + textNode.nodeSize <= startPosition) {
      textNodesBeforeMatch.push(textNode);
    } else if (position > endPosition) {
      textNodesAfterMatch.push(textNode);
    } else {
      const textSliceBefore = textNode.textContent.slice(
        0,
        Math.max(0, startPosition - position),
      );
      if (textSliceBefore.length > 0) {
        textNodesBeforeMatch.push(schema.text(textSliceBefore, textNode.marks));
      }
      const textSliceAfter = textNode.textContent.slice(endPosition - position);
      if (textSliceAfter.length > 0) {
        textNodesAfterMatch.push(schema.text(textSliceAfter, textNode.marks));
      }
    }
    position += textNode.textContent.length;
  }

  return { textNodesBeforeMatch, textNodesAfterMatch };
}

export const transformPasted = (slice: Slice): Slice => {
  let hasCodeblock = false;
  const noteIdMapper = createNoteIdMapper();
  const newNoteIds: string[] = [];
  let transformedFragment = deepMapNodeTree(slice.content, (item: Node) => {
    // Note
    if (item.type === schema.nodes.note) {
      if (item.attrs.depth < 1) {
        const newNoteNode = deepMapNodeTree(item, (node) => {
          // Preserve the text nodes
          if (node.isText) return node;
          if (node.type === schema.nodes.note) {
            // we need a new ID here since we copying a note (see ENT-460)
            const newId = node.attrs.noteId
              ? noteIdMapper(node.attrs.noteId)
              : generateId();
            if (node.attrs.noteId) {
              newNoteIds.push(newId);
            }
            return { noteId: newId, path: generatePathFromNoteId(newId) };
          }
          // Remove all expandedReference nodes
          if (node.type === schema.nodes.expandedReference) {
            return null;
          }
          if (node.type === schema.nodes.backlinks) {
            return null;
          }
          return {};
        });
        return newNoteNode;
      }
    }
    // Codeblock
    else if (item.type === schema.nodes.codeblock) {
      hasCodeblock = true;
    }
    return item;
  });

  // Remap the linkedNoteId in pasted references.
  // If a referenced note cannot be found replace it with the first line of the referenced note
  transformedFragment = deepMapNodeTree(transformedFragment, (node) => {
    // Preserve the text nodes
    if (node.isText) return node;
    if (node.type !== schema.nodes.reference) return {};

    // The referenced note exits in the currently loaded thoughtstream, keep it as is
    const oldLinkedNodeId = node.attrs.linkedNoteId;
    if (noteList.has(oldLinkedNodeId)) return {};

    const newLinkedNodeId = noteIdMapper(node.attrs.linkedNoteId);
    if (!newNoteIds.includes(newLinkedNodeId)) {
      return schema.text(node.attrs.content);
    }
    return { linkedNoteId: newLinkedNodeId };
  });

  // Prepend a linkLoader node in front of every link detected with high confidence
  // The linkLoader node is responsible for unfurling the link (detecting and inserting the title of the linked page)
  transformedFragment = deepMapNodeTree(transformedFragment, (node) =>
    remapMatches(node, regexpMatchers.highConfidenceLink, (match) => {
      const linkLoaderNode = schema.nodes.linkLoader.create({
        url: match[0],
        title: null,
        description: null,
        image: null,
      });

      return [linkLoaderNode, schema.text(match[0])];
    }),
  );

  return new Slice(
    transformedFragment,
    // if we have a codeblock, we want to go one level higher so that
    // prosemirror knows at which level to merge into
    slice.openStart + (hasCodeblock ? -1 : 0),
    slice.openEnd + (hasCodeblock ? -1 : 0),
  );
};

export function transformCopied(slice: Slice): Slice {
  // Attach the full content of each refernced note to each reference object
  // This way if the referenced note itself is not being copied at least the
  // text content can be preserved
  let fragment = deepMapNodeTree(slice.content, (node) => {
    // Preserve the text nodes
    if (node.isText) return node;
    if (node.type === schema.nodes.reference) {
      return {
        // Get the first non-empty line of the referenced note without any other truncation
        content: noteFormatter.getNoteEllipsis(
          node.attrs.linkedNoteId,
          true,
          Infinity,
        ),
      };
    }
    return {};
  });
  // While in condensed view, condensed nodes are wrapped in ellipsis nodes.
  // Unwrap them before copying.
  fragment = removeEllipsis(fragment);
  return new Slice(fragment, slice.openStart, slice.openEnd);
}

/**
 * Pasted nested list need to support the following scenarios:
 * - pasting from ideaflow to ideaflow
 * - pasting nested html lists from google doc
 * - pasting nested html lists from contentEditable editors like Gutenberg: https://wordpress.org/gutenberg/
 * See: https://linear.app/ideaflow/issue/ENT-1603
 */
function embedDepthInformationInHTMLDocument(doc: Document) {
  // querySelectorAll returns an ordered list (https://www.w3.org/TR/selectors-api/#queryselectorall)
  const listsInOrder = Array.from(doc.querySelectorAll("ul,ol"));
  // Find the nested depth of each <ul> list
  for (let i = 0; i < listsInOrder.length; ++i) {
    const currentList = listsInOrder[i];
    const parentList = listsInOrder
      .slice(0, i)
      .reverse()
      .find((parentList) => parentList.contains(currentList));
    const depth = parentList
      ? parseInt(parentList.getAttribute("data-depth")!) + 1
      : 0;
    currentList.setAttribute("data-depth", depth + "");
  }

  // Give each <li> the depth of its parent list
  for (const listItem of doc.querySelectorAll("li")) {
    let parentList: HTMLElement | null = listItem;
    while (
      parentList !== null &&
      parentList.tagName !== "UL" &&
      parentList.tagName !== "OL"
    ) {
      parentList = parentList.parentElement;
    }

    if (parentList) {
      const depth = parentList.getAttribute("data-depth")!;
      // Don't override already set data-depth attributes
      // This preserves depth when copying from ideaflow
      if (!listItem.hasAttribute("data-depth")) {
        listItem.setAttribute("data-depth", depth);
      }
    }
  }

  // Flatten the tree of list items, since our prosemirror schema doesn't
  // allow for nested lists within lists and instead uses the depth attribute
  for (const currentList of Array.from(listsInOrder)) {
    currentList.replaceWith(...currentList.childNodes);
  }
}

/** Used to transform pasted HTML text, before it is parsed, to clean it up. */
export const transformPastedHTML = (html: string): string => {
  const domParser = new DOMParser();
  const document = domParser.parseFromString(html, "text/html");
  embedDepthInformationInHTMLDocument(document);

  const rootElements = document.body.children;
  // in VS Code, we get a wrapper div that messes up the parser, so we
  // remove it. see: https://linear.app/ideaflow/issue/ENT-431
  if (rootElements.length === 1 && rootElements[0].children.length > 1) {
    return rootElements[0].innerHTML;
  }

  const serializer = new XMLSerializer();
  return serializer.serializeToString(document);
};
