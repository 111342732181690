import { NoteId } from "../../../model/types";
import { Decoration, DecorationSet } from "prosemirror-view";
import { PluginKey, Plugin, EditorState } from "prosemirror-state";
import { Node } from "prosemirror-model";
import { schema } from "../../schema";
import { descendNotes } from "../../utils/descendNotes";
import { getBacklinkOffsetInNote } from "../backlink/backlinkPlugin";
import { findDescendantNote } from "../../utils/find";

interface StateEntry {
  decorationOffset: number;
  noteOffset: number;
  node: Node;
}

type State = Map<NoteId, StateEntry>;

function nodeIncludesElipsis(node: Node): boolean {
  let res = false;
  node.descendants((n) => {
    if (res === true) return false;
    if (n.type === schema.nodes.expandedReference) {
      return false;
    }
    if (n.type === schema.nodes.ellipsisContainer) {
      res = true;
      return false;
    }
  });
  return res;
}

const getNodes = (doc: EditorState["doc"]): State => {
  const nodes: State = new Map();
  descendNotes(doc, (node, pos) => {
    if (nodeIncludesElipsis(node)) {
      nodes.set(node.attrs.path, {
        decorationOffset: pos + getBacklinkOffsetInNote(node),
        node,
        noteOffset: pos,
      });
    }
  });
  return nodes;
};

const getDom = (node: Node) => {
  const dom = document.createElement("div");
  dom.className = "expand-button";
  dom.textContent = node.attrs.isExpanded ? "Show less" : "... Show more";
  return dom;
};

export const toggleEllipsisPlugin: Plugin<State> = new Plugin({
  key: new PluginKey("condenseNote"),
  state: {
    init: (_config, state) => getNodes(state.doc),
    apply: (tr, pluginState) =>
      tr.docChanged ? getNodes(tr.doc) : pluginState,
  },
  props: {
    handleDOMEvents: {
      mousedown: (view, event) => {
        if (!isEventOnEllipsisButton(event as MouseEvent)) return;
        const tr = view.state.tr;

        // get the noteid of the note we're in
        const notePath = (event.target as HTMLElement)
          ?.closest(".note[data-path]")
          ?.getAttribute("data-path");
        if (!notePath) return false;

        // find the corresponding node
        const [node, pos] = findDescendantNote(
          tr.doc,
          (n) => n.attrs.path === notePath,
        );
        if (node === null || pos === null) return false;

        // toggle it
        tr.setMeta("noChangeToModel", true);
        tr.setNodeMarkup(pos, null, {
          ...node?.attrs,
          isExpanded: !node?.attrs.isExpanded,
        });
        view.dispatch(tr);
        return true;
      },
    },
    decorations(state) {
      const decorations: Decoration[] = [];
      (this as Plugin)
        .getState(state)!
        .forEach(({ decorationOffset, node }: StateEntry, noteId: NoteId) => {
          decorations.push(
            Decoration.widget(decorationOffset, () => getDom(node), {
              side: -1,
              key: `${noteId}-expand-note`,
            }),
          );
        });

      return DecorationSet.create(state.doc, decorations);
    },
  },
});

function isEventOnEllipsisButton(e: MouseEvent) {
  const el = e.target as Element;
  if (!el) return false;
  const isExpandButton = el.classList.contains("expand-button");
  const isEllipsisDot =
    el.classList.contains("ellipsis-dot") ||
    el.parentElement?.classList.contains("ellipsis-dot");
  return isExpandButton || isEllipsisDot;
}
