import {
  SerializedFolder,
  SerializedNote as ServerNote,
} from "../../model/types";
import { Note, Folder } from "../../model/types";
const toDate = (ts: string) => {
  const d = new Date(ts);
  if (d instanceof Date && !isNaN(d as any)) return d;
  return new Date();
};

// N+1 (cli and the sw)
// we receive N from the server delta (because the upsert didnt resolve yet)
// We should not update local rev to N
export const noteApiToClient = (serNote: ServerNote): Note => {
  return {
    id: serNote.id,
    createdAt: toDate(serNote.created_at),
    updatedAt: toDate(serNote.updated_at),
    deletedAt: serNote.deleted_at ? toDate(serNote.deleted_at) : null,
    masterRev: serNote.rev,
    localRev: serNote.rev,
    tokens: serNote.tokens,
    insertedAt: serNote.inserted_at,
    position: serNote.position,
    readAll: serNote.read_all,
    positionInPinned: serNote.position_in_pinned,
    folderId: serNote.folder_id,
    importSource: serNote.import_source,
    importBatch: serNote.import_batch,
    importForeignId: serNote.import_foreign_id,
  };
};

export const folderApiToClient = (serverFolder: SerializedFolder): Folder => {
  return {
    id: serverFolder.id,
    name: serverFolder.name,
    parentId: serverFolder.parent_id,
    position: serverFolder.position,
    masterRev: "HELLO", //@todo
    localRev: "HELLO", //@todo
    updatedAt: toDate(serverFolder.updated_at),
    deletedAt: serverFolder.deleted_at ? toDate(serverFolder.deleted_at) : null,
  };
};
