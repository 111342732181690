import React from "react";

import { Plugin, PluginKey, Selection } from "prosemirror-state";
import { NodeType, ResolvedPos } from "prosemirror-model";
import { DecorationSet, Decoration, EditorView } from "prosemirror-view";

import { escapeRegExp } from "../../../search/find";
import { autocompleteModules } from "./modules";
import { AutoCompleteState } from "./useAutocompleteState";
import { getParentNote } from "../../utils/find";
import { schema } from "../../schema";
import { fixedRemoveMark } from "../../utils/fixedRemoveMark";

interface State {
  match: { from: number; to: number } | null;
  userCanceledAt: number | null;
}

interface FullMatch {
  from: number;
  to: number;
  text: string;
}

export const AUTOCOMPLETE_DECORATION_CLASS = "prosemirror-suggestion";

function isSelectionInMatchRange(
  selection: Selection,
  match: { from: number; to: number },
) {
  return !(selection.from < match.from) && !(selection.from > match.to + 1);
}

/**
 * pass in a whitelist of valid nodes we should show the plugin on
 */
export const autocompletePlugin = (
  validNodeTypes: NodeType[],
  setAutocompleteState: (
    func: (prevState: AutoCompleteState) => AutoCompleteState,
  ) => void,
  autocompleteKeyDownRef: React.RefObject<
    ((event: KeyboardEvent) => boolean) | null
  > | null,
) => {
  const plugin: Plugin<State> = new Plugin<State>({
    key: new PluginKey("autocomplete"),
    state: {
      init(): State {
        return {
          match: null,
          userCanceledAt: null,
        };
      },
      apply(tr, oldPluginState): State {
        // see https://discuss.prosemirror.net/t/3420
        const newPluginState = tr.getMeta(plugin);
        const pluginState: State = { ...oldPluginState, ...newPluginState };

        // Deactivate the autocomplete when there is a selection, to prevent
        // flickering as you drag across a # sign (for example) while selecting text.
        if (tr.selection.from !== tr.selection.to) {
          return { match: null, userCanceledAt: null };
        }

        // If the user puts the cursor before or after the match, cancel that match
        // if they click anywhere after the prevMatch, close the menu
        const caretExitedPreviousMatch =
          pluginState.match &&
          !isSelectionInMatchRange(tr.selection, pluginState.match);

        // only find a new match if the doc has been updated
        // or the caret exited the previously selected match
        // or the transaction explicitly asks the autocompletePlugin to search for the match
        // The last condition is important for when an editor gets recreated (see https://linear.app/ideaflow/issue/ENT-1737)
        const shouldLookForNewMatch =
          tr.docChanged ||
          caretExitedPreviousMatch ||
          tr.getMeta("reapplyAutocompletePlugin");
        if (!shouldLookForNewMatch) return pluginState;

        if (pluginState.userCanceledAt != null) {
          pluginState.userCanceledAt = tr.mapping.map(
            pluginState.userCanceledAt,
          );
        }

        const $caretPos = tr.selection.$from;

        // only run the plugin on a whitelist of nodes
        if (!validNodeTypes.includes($caretPos.parent.type)) return pluginState;

        const { match, autocompleteMod } = getMatchAndModule($caretPos);

        if (!match || !autocompleteMod) {
          // Preserve the userCanceledAt to avoid rematching the same string after
          // user adds a new line and then deletes it
          return { ...pluginState, match: null };
        }

        // If the found match is explicitly rejected by the user, treat it as no match
        if (match.from === pluginState.userCanceledAt) {
          return { ...pluginState, match: null };
        }

        // if the brand new match does not contain the cursor reject it
        if (!isSelectionInMatchRange(tr.selection, match)) {
          return { ...pluginState, match: null };
        }

        const $matchFrom = $caretPos.doc.resolve(match.from);
        const [parentNote] = getParentNote(tr.doc, $matchFrom.pos);
        const noteId = parentNote?.attrs.noteId;

        if (!noteId) throw new Error("missing parent noteId");

        setAutocompleteState((s) => ({
          ...(s || { cancel() {} }),
          noteId,
          matcherName: autocompleteMod.matcherName,
          match: { to: match.to, from: match.from },
          text: match.text,
        }));

        return {
          ...pluginState,
          userCanceledAt: null,
          match: { to: match.to, from: match.from },
        };
      },
    },
    appendTransaction(trs, oldState, newState) {
      const oldPluginState = plugin.getState(oldState)!;
      const newPluginState = plugin.getState(newState)!;

      if (!newPluginState.match && !oldPluginState.match) return null;

      const tr = newState.tr;
      if (oldPluginState.match) {
        // Remove the autocompleteRegion mark from old matches
        const oldFrom = trs.reduce(
          (pos, tr) => tr.mapping.map(pos, -1),
          oldPluginState.match.from,
        );
        const oldTo = trs.reduce(
          (pos, tr) => tr.mapping.map(pos, +1),
          oldPluginState.match.to,
        );
        if (newPluginState.match) {
          // If the old and new match ranges are the same do nothing
          const { from: newFrom, to: newTo } = newPluginState.match;
          if (oldFrom === newFrom && oldTo === newTo) return null;
        }

        // For some reason prosemirror sometimes cannot preserve a selection when a mark is removed.
        // without this we lose selecting after cmd+a shortcut (see https://linear.app/ideaflow/issue/ENT-1738)
        fixedRemoveMark(tr, oldFrom, oldTo, schema.marks.autocompleteRegion);
      }

      if (newPluginState.match) {
        const { from: newFrom, to: newTo } = newPluginState.match;
        tr.removeMark(newFrom, newTo, schema.marks.highlight);
        tr.addMark(newFrom, newTo, schema.marks.autocompleteRegion.create({}));
      }
      return tr;
    },
    props: {
      // to decorate the currently active entity text in ui
      decorations(editorState) {
        const { match, userCanceledAt } = (this as Plugin).getState(
          editorState,
        );
        if (!match || match.from === userCanceledAt) return null;

        return DecorationSet.create(editorState.doc, [
          Decoration.inline(match.from, match.to, {
            nodeName: "span",
            class: AUTOCOMPLETE_DECORATION_CLASS,
          }),
        ]);
      },
      // The actual handling of the keydown event is delegated to the
      // function passed from the AutocompleteMenu component through autocompleteKeyDownRef
      handleKeyDown(_, event) {
        return autocompleteKeyDownRef?.current?.(event) ?? false;
      },
    },
    view(view: EditorView) {
      // This function is created only once to avoid messing with the setAutocompleteState shallow optimization
      function cancel() {
        const { match } = plugin.getState(view.state)!;
        // updated the plugin state https://discuss.prosemirror.net/t/3420
        view.updateState(
          view.state.apply(
            view.state.tr.setMeta(plugin, {
              // Match needs to be reset here, since after just pressing Escape, we have tr.docChanged === false
              // So the apply logic above will not be looking for a new match
              match: null,
              userCanceledAt: match?.from,
            }),
          ),
        );
      }
      return {
        update(view) {
          const { match, userCanceledAt } = plugin.getState(view.state)!;

          setAutocompleteState((state) =>
            match && state
              ? {
                  ...state,
                  match,
                  userCanceledAt,
                  cancel,
                }
              : null,
          );
        },
        destroy: () => {
          setAutocompleteState(() => null);
        },
      };
    },
  });

  return plugin;
};

const getMatchAndModule = ($caretPos: ResolvedPos) => {
  const modules = Object.values(autocompleteModules);
  for (let i = 0; i < modules.length; i++) {
    const match = getMatch($caretPos, modules[i].regexp, modules[i].prefix);
    if (match) return { match, autocompleteMod: modules[i] };
  }
  return { match: null, autocompleteMod: null };
};

/**
 * If a match is present at the current caret position, returns the range and the text that matches.
 */
const getMatch = (
  $caretPos: ResolvedPos,
  regex: RegExp,
  prefix: string,
): FullMatch | null => {
  if ($caretPos.pos === 0) return null;

  const surroundingText = findSurroundingText($caretPos);
  if (!surroundingText) return null;

  const {
    textBefore: textToCursor,
    textAfter: textAfterCursor,
    startingPosition: matchingStartPosition,
  } = surroundingText;

  // apply the matching up to the cursor or the next space
  const textToCursorOrNextSpace = textToCursor + textAfterCursor.split(" ")[0];

  // const match = Array.from(
  //   textToCursorOrNextSpace.matchAll(
  //     new RegExp(regex.source, regex.flags + "g"),
  //   ),
  // ).slice(-1)[0];
  const match = textToCursorOrNextSpace.match(regex);

  if (!match) {
    // if we don't have a full match, check to see if it's the case where the
    // prefix is showing but nothing else
    const onlyPrefixMatch = textToCursorOrNextSpace.match(
      // create a regex to match the prefix at the end of the string
      RegExp(`(^|\\s|\\W)${escapeRegExp(prefix)}$`, "g"),
    );

    if (!onlyPrefixMatch) return null;

    // since we only want a match when the end of the string is the prefix
    // remove the targetOffset, the add the string's we match to length minus
    // the length of the prefix
    const from =
      matchingStartPosition + textToCursorOrNextSpace.length - prefix.length;

    return { from, to: from + prefix.length, text: "" };
  }

  const matchedContent = match[2];

  // The preceeding characters before the "+" or "#" are captured in the first group (match[1])
  // so we have to offset further into the match to find the actual content
  const from = matchingStartPosition + match.index! + match[1].length;
  // the matching text plus the length of the prefix
  const to = from + matchedContent.length + prefix.length;

  if (to < $caretPos.pos) {
    console.warn("Found non-suffix match!", { textToCursor, match });
    return null;
  }

  // this is to support autocomplete with optional prefixes
  const finalText =
    prefix && matchedContent.startsWith(prefix)
      ? matchedContent.substr(prefix.length)
      : matchedContent;

  return { from, to, text: finalText };
};

function findSurroundingText($caretPos: ResolvedPos) {
  const currentNode = $caretPos.doc.nodeAt($caretPos.pos - 1);
  if (!currentNode) return null;

  const currentNodeText = currentNode.text;
  if (!currentNodeText) return null;

  // When this position points into a text node, this returns the distance
  // between the position and the start of the text node. Will be zero
  // for positions that point between nodes.
  const targetOffset =
    $caretPos.textOffset === 0 ? currentNodeText.length : $caretPos.textOffset;

  const textToCursor = currentNodeText.substr(0, targetOffset);
  const textAfterCursor = currentNodeText.substr(targetOffset);

  return {
    textBefore: textToCursor,
    textAfter: textAfterCursor,
    startingPosition: $caretPos.pos - targetOffset,
  };
}
