import { Slice } from "prosemirror-model";
import { Node as PMNode } from "prosemirror-model";
import { schema } from "../schema";

type NodeSerializerFn = (node: PMNode, depth: number, indent: number) => string;

/**
 * NodeSerializer contains the algorithm that determines how to serialize a
 * ProseMirror Slice into text for copy purposes. It is similar to but distinct
 * from NoteFormatter: NoteFormatter formats an entire Note, usuallly for
 * display in a single line. NodeSerializer formats a Slice of a Doc for pasting
 * somewhere else, and tries to preserve formatting as much as possible.
 */
class NodeSerializer {
  slice: Slice;

  ignoredFirstChildren: PMNode[];
  ignoredLastChildren: PMNode[];

  constructor(slice: Slice) {
    this.slice = slice;

    // In the given Slice, the actual "first node" may start at a non-zero
    // depth, given by slice.openStart. For example, if selection to copy starts
    // deep in a listItem, we do not want to add the "- " to the parent bullets.
    // The ignoredFirstChildren list contains firstChildren at smaller depth
    // than slice.openStart. The list determines which initial parents we should
    // ignore for prefixing purposes.
    this.ignoredFirstChildren = [];
    let firstChild = this.slice.content.firstChild;
    while (firstChild) {
      if (this.ignoredFirstChildren.length < slice.openStart)
        this.ignoredFirstChildren.push(firstChild);
      else break;

      if (firstChild.childCount) firstChild = firstChild.child(0);
      else firstChild = null;
    }

    // See comment above about ignoredFirstChildren.
    this.ignoredLastChildren = [];
    let lastChild = this.slice.content.lastChild;
    while (lastChild) {
      if (this.ignoredLastChildren.length < slice.openEnd)
        this.ignoredLastChildren.push(lastChild);
      else break;

      if (lastChild.childCount)
        lastChild = lastChild.child(lastChild.childCount - 1);
      else lastChild = null;
    }
  }

  public serialize(): string {
    const nodes: PMNode[] = [];
    this.slice.content.forEach((node: PMNode) => {
      nodes.push(node);
    });

    let out = "";
    for (let i = 0; i < nodes.length; i++) {
      const last = nodes[i - 1];
      const curr = nodes[i];

      if (i === 0) {
        out += this.serializeNode(curr, 0, 0);
        continue;
      }

      // Handle any topLevelTokens / block tokens that have special delimiters line a
      // new line or a line divider (note).
      switch (last.type) {
        case schema.nodes.note:
          out += "\n--\n";
          break;
        case schema.nodes.paragraph:
        case schema.nodes.listItem:
        case schema.nodes.bulletList:
        case schema.nodes.codeblock:
          out += "\n";
          break;
      }

      out += this.serializeNode(curr, 0, 0);
    }

    return out;
  }

  private serializeNode: NodeSerializerFn = (node, depth, indent) => {
    switch (node.type) {
      case schema.nodes.doc:
        return this.serializeDoc(node, depth, indent);
      case schema.nodes.note:
        return this.serializeNote(node, depth, indent);
      case schema.nodes.text:
        return this.serializeText(node, depth, indent);
      case schema.nodes.checkbox:
        return this.serializeCheckbox(node, depth, indent);
      case schema.nodes.paragraph:
        return this.serializeParagraph(node, depth, indent);
      case schema.nodes.codeblock:
        return this.serializeCodeblock(node, depth, indent);
      case schema.nodes.reference:
        return this.serializeSpaceship(node, depth, indent);
      case schema.nodes.listItem:
        return this.serializeListItem(node, depth, indent);
      case schema.nodes.bulletList:
        return this.serializeBulletList(node, depth, indent);
      default:
        return this.serializeUnknown(node, depth, indent);
    }
  };

  private serializeDoc: NodeSerializerFn = (node, depth, indent) => {
    const noteNodes: PMNode[] = [];
    node.content.forEach((node) => noteNodes.push(node));
    return noteNodes
      .map((node) => this.serializeNode(node, depth + 1, indent))
      .join("\n--\n");
  };

  private serializeNote: NodeSerializerFn = (node, depth, indent) => {
    const topLevelNodes: PMNode[] = [];
    node.content.forEach((node) => topLevelNodes.push(node));
    return topLevelNodes
      .map((node) => this.serializeNode(node, depth + 1, indent))
      .join("\n");
  };

  private serializeText: NodeSerializerFn = (node) => {
    return node.textContent;
  };

  private serializeCheckbox: NodeSerializerFn = (node) => {
    return node.attrs.isChecked ? "[x] " : "[ ] ";
  };

  private serializeParagraph: NodeSerializerFn = (node, depth, indent) => {
    const inlineNodes: PMNode[] = [];
    node.content.forEach((node) => inlineNodes.push(node));
    return inlineNodes
      .map((node) => this.serializeNode(node, depth + 1, indent))
      .join("");
  };

  private serializeCodeblock: NodeSerializerFn = (node) => {
    return (
      (this.ignoredFirstChildren.includes(node) ? "" : "```\n") +
      node.textContent +
      (this.ignoredLastChildren.includes(node) ? "" : "\n```")
    );
  };

  private serializeSpaceship: NodeSerializerFn = (node) => {
    return node.attrs.content;
  };

  private serializeListItem: NodeSerializerFn = (node, depth, indent) => {
    const topLevelNodes: PMNode[] = [];
    node.content.forEach((node) => topLevelNodes.push(node));

    const listItemContent = topLevelNodes
      .map((node) => this.serializeNode(node, depth + 1, indent))
      .join("\n");

    const leadingSpace = "  ".repeat(node.attrs.depth || 0);

    return listItemContent
      .split("\n")
      .map((line, i) => {
        if (this.ignoredFirstChildren.includes(node)) {
          return line;
        }

        return i === 0
          ? leadingSpace + "- " + line
          : leadingSpace + "  " + line;
      })
      .join("\n");
  };

  private serializeBulletList: NodeSerializerFn = (node, depth, indent) => {
    const listItems: PMNode[] = [];
    node.content.forEach((node) => listItems.push(node));

    return listItems
      .map((node) => this.serializeNode(node, depth + 1, indent + 1))
      .join("\n");
  };

  private serializeUnknown: NodeSerializerFn = (node, depth, indent) => {
    const children: PMNode[] = [];
    node.content.forEach((node) => children.push(node));
    return children
      .map((node) => this.serializeNode(node, depth + 1, indent))
      .join("\n");
  };
}

/** A function that will be called to get the text for the current selection when
 * copying text to the clipboard. */
export const serializeNode = (slice: Slice): string => {
  return new NodeSerializer(slice).serialize();
};
