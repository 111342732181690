import { Node as ProsemirrorNode } from "prosemirror-model";
import { EditorView, NodeView } from "prosemirror-view";
import { SearchQuery } from "../../../search/SearchQuery";
import { domAttrs } from "../../utils/attrs";

export class ExpandedReferenceView implements NodeView {
  dom: HTMLElement;
  contentDOM: HTMLElement;
  constructor(
    node: ProsemirrorNode,
    _view: EditorView,
    _getPos: () => number,
    _setSearchQuery: (q: SearchQuery) => void,
  ) {
    this.dom = document.createElement("div");
    this.dom.classList.add("expandedNote");
    setAttributes(this.dom, domAttrs(node, node.attrs));
    if (node.attrs.isHighlighted) {
      this.dom.classList.add("highlighted");
    }
    const content = document.createElement("div");
    content.classList.add("expandedNote-content");
    this.contentDOM = content;

    // @todo use createMenu
    this.dom.append(content);
  }
}

function setAttributes(el: HTMLElement, attrs: any) {
  for (const key in attrs) {
    el.setAttribute(key, attrs[key]);
  }
}
