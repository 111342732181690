import { useRouter } from "next/router";
import { useEffect, useMemo, useRef, useState } from "react";
import { useQuery } from "urql";
import { useAuth } from "../../auth/useAuth";
import { createEditorView, EditorViewConfig } from "../../editor/editorView";
import { loadModel } from "../../model/sync/loadModel";
import { noteList } from "../../model/services";
import { useColorScheme } from "../../settings/ColorScheme";
import { CtaBanner } from "../../share/CtaBanner";
import { sortByDefault } from "../../search/SearchQuery";
import { getGraphqlUrl } from "../../model/useGraphqlClient";
import { noteApiToClient } from "../../service-worker/api/serverToClient";
/**
 * This component displays a read only note that has been shared publicly.
 * A note will display with the favorite color scheme of the user, if they have an account.
 *
 * Loading is more complex than expected, as we need first to load the note, then load all entities that may be present in that note.
 * It doesn't handle yet <>, but could be added in a very symmetrical way; if we don't care about cascading <> previews inside the <>.
 *
 */
const CTA_BANNER_HEIGHT = 100;

const query = `
query OneNoteLoad($noteId: String!) {
    note_by_pk(id: $noteId) {
      tokens
      created_at
      deleted_at
      id
      inserted_at
      position
      read_all
      user {
        first_name
        last_name
      }
    }
  }
`;

/* eslint-disable */
enum LoadState {
  WaitForNote,
  WaitForEntities,
  UnknownNote,
  Done,
}
/* eslint-enable */

export default function SharedPage() {
  const router = useRouter();
  const { noteId } = router.query;

  const [loadState, setLoadState] = useState(LoadState.WaitForNote);
  const editorDiv = useRef<HTMLDivElement>(null);
  const [notesResult] = useQuery({
    query,
    variables: {
      noteId,
    },
    context: useMemo(
      () => ({
        url: getGraphqlUrl(),
      }),
      [],
    ),
  });
  const colorScheme = useColorScheme();

  const { isAuthenticated } = useAuth();

  // Effect transitioning from WaitForNote to WaitForEntities
  // This is when we can retrieve <> notes if it's worth it
  useEffect(() => {
    if (
      notesResult &&
      notesResult.data &&
      loadState === LoadState.WaitForNote
    ) {
      if (!notesResult.data.note_by_pk) {
        throw new Error("Unknown note");
      } else {
        setLoadState(LoadState.WaitForEntities);
      }
    }
  }, [notesResult, noteId, loadState]);

  // Effect transitioning from WaitForEntities to Done
  // We load the data and render the editor
  useEffect(() => {
    if (notesResult.data && loadState === LoadState.WaitForEntities) {
      const note = noteApiToClient(notesResult.data.note_by_pk);
      loadModel([note], []); // there is no interesting local rev when we are in read only.

      const defaultArgs: EditorViewConfig = {
        isReadOnly: true,
        div: editorDiv.current!,
        initialNotes: [{ entry: noteList.get(noteId as string)! }],
        noteToFocusAtInit: null,
        dispatchToModel: () => {},
        setAutocompleteState: () => {},
        searchQuery: {
          type: "*",
          isCondensed: false,
          isIncludingSubFolders: false,
          sortBy: sortByDefault,
        },
        setSearchQuery: () => {},
        setMessage: () => {},
        autocompleteKeyDownRef: null,
        authenticatedServerFetch: () => {
          throw new Error("NO SERVER IS AVAILABLE");
        },
      };
      createEditorView(defaultArgs);
      setLoadState(LoadState.Done);
    }
  }, [loadState, noteId, notesResult]);

  return (
    <div
      style={{
        marginTop: 24,
        flexDirection: "column",
        alignItems: "center",
        width: "100%",
        overflow: "auto",
        height: "100vh",
        display: "flex",
        position: "absolute",
      }}
    >
      <img
        alt="logo"
        src={colorScheme !== "dark" ? "/logo-white.png" : "/logo.png"}
        style={{ width: "120px" }}
      ></img>
      <div
        style={{
          marginTop: "48px",
          width: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          maxWidth: 720,
        }}
      >
        <div
          ref={editorDiv}
          className="editor-div ProseMirror"
          style={{ padding: `24px 24px` }}
        />
        <div style={{ height: CTA_BANNER_HEIGHT + 56 }}></div>
        {!isAuthenticated && (
          <CtaBanner
            height={CTA_BANNER_HEIGHT}
            text="Ideaflow, the no-friction no-bs notebook"
            ctaText="Request access"
            onClick={() => (window.location.href = "https://ideaflow.io")}
          />
        )}
        {isAuthenticated && (
          <CtaBanner
            height={CTA_BANNER_HEIGHT}
            text=""
            ctaText="Go to my Ideaflow"
            onClick={() => (window.location.href = "/")}
          />
        )}
      </div>
    </div>
  );
}
