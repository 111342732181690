import { useMemo } from "react";
import { atom, useAtomValue, useSetAtom } from "jotai";

// used by the sidebar to subscribe to editor updates
//   - use the timestamp value, since in the future
//     the sidebar will need to query updates from the server
const editorUpdate = atom(0);

/**
 * Helper to notify the editor has updated
 *
 */
export const useUpdateEditor = () => {
  const set = useSetAtom(editorUpdate);
  return useMemo(() => () => set(new Date().getTime()), [set]);
};

/**
 * Refreshes when the editor changes.
 */
export const useRefreshWithEditorUpdate = () => {
  return useAtomValue(editorUpdate);
};
