import { isProd } from "./environment";

export type ErrorCode = string;
export const isErrorCode = (e: string): e is ErrorCode =>
  /^EE[0-9]{3}:/.test(e);

// errors that can be discriminated based on a type,
// contain a message
// and can have context extra info that are serializable to json.
export interface IdeaError<ErrorType extends ErrorCode> {
  type: ErrorType;
  message: string;
  extra: Serialiazable;
}

interface Serialiazable {
  [x: string]:
    | string
    | number
    | boolean
    | Date
    | Serialiazable
    | Serialiazable[];
}

// Transforms codified errors into user facing messages.
export const displayToUser = (e: IdeaError<any>): string => {
  if (isProd) {
    const code = e.type.match(/^EE([0-9]{3}):/);
    if (!code || code.length === 0)
      throw new Error(`Please make sure to use a codified error`);
    switch (code[0]) {
      case "107":
        return `A critical error occured. Please contact us`;
      case "101":
        return `(${code[0]}). An error occured. Refresh your screen or contact us.`;
      default:
        return e.type + " " + e.message;
    }
  } else return e.type + " " + e.message;
};
