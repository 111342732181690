import { isApple, isElectron } from "../utils/environment";

export const NO_KEYBINDING = "none";
type Keybinding = string | typeof NO_KEYBINDING;

export type Shortcut = {
  id: string;
  // Human-readable description for the shortcut that appears in the UI
  // If not defined, it won't show up in the shortcuts list
  label?: string;
  // These names use https://github.com/marijnh/w3c-keyname/blob/master/index.es.js convention
  // we use a partial implementation of this naming convention in
  // utils.useHotkeys for all non prosemirror related usages.
  keys: Keybinding;
  // If the binding displayed in the option needs to be different from the
  // actual binding. Used for macOS, where the Option key will sometimes insert
  // non-ASCII Unicode chars.
  keysForShortcutList?: Keybinding;
  // can be customized on electron, we can see later if it needs to be customizable on the web as well
  isCustomizable: boolean;
  // for customizable shortcuts, provide the key in the configuration.
  configIndex?: string;
};

const ctrlCmd = (shortcutPart: Keybinding) => {
  return `${isApple ? "Meta" : "Ctrl"}-${shortcutPart}`;
};

// Annoyingly, on macOS, "Shift" then "k" registers the event as {key: 'K'} while
// on Linux it registers as {key: 'k'} (lowercase). This helper papers over this
// difference.
const shift = (key: string) => {
  return `Shift-${isApple ? key : key.toUpperCase()}`;
};

export const shortcuts = {
  createNewNote: {
    id: "createNewNote",
    label: "Create a new note",
    keys: ctrlCmd("k"),
    isCustomizable: false,
  },
  createNewNoteDesktop: {
    id: "createNewNoteDesktop",
    label: "Create a new note (desktop)",
    keys: isElectron ? ctrlCmd("n") : NO_KEYBINDING,
    isCustomizable: false,
  },
  openCurrentNote: {
    id: "openCurrentNote",
    label: "Open note as page",
    keys: ctrlCmd("j"),
    isCustomizable: false,
  },
  invoke: {
    id: "invoke",
    label: "Invoke (desktop)",
    keys: isElectron ? (window as any).api.config.invoke : NO_KEYBINDING,
    isCustomizable: isElectron,
    configIndex: "invoke",
  },
  quickAdd: {
    id: "quickAdd",
    label: "Quick add (desktop)",
    keys: isElectron ? (window as any).api.config.quickAdd : NO_KEYBINDING,
    isCustomizable: true,
    configIndex: "quickAdd",
  },
  createNewPage: {
    id: "createNewPage",
    label: "Create a new blank page",
    keys: ctrlCmd(shift("k")),
    isCustomizable: false,
  },
  search: {
    id: "search",
    label: "Search",
    keys: ctrlCmd("/"),
    isCustomizable: false,
  },
  searchForSelection: {
    id: "searchForSelection",
    label: "Search for selection under cursor",
    keys: ctrlCmd("Alt-/"),
    isCustomizable: false,
  },
  searchAlt: {
    id: "searchAlt",
    label: "Search (desktop)",
    keys: isElectron ? ctrlCmd("f") : NO_KEYBINDING,
    isCustomizable: false,
  },
  toggleSidebar: {
    id: "toggleSidebar",
    label: "Toggle sidebar",
    keys: ctrlCmd("d"),
    isCustomizable: false,
  },
  backToAllNotes: {
    id: "backToAllNotes",
    label: "Return to All Notes",
    keys: "Escape",
    isCustomizable: false,
  },
  toggleSettings: {
    id: "toggleSettings",
    label: "Open/close settings",
    // NOTE: AdBlock Plus intercepts Ctrl/Cmd + . before we can get it. But this
    // is deemed ok because "open settings" is not a freq used shortcut
    keys: ctrlCmd("."),
    isCustomizable: false,
  },
  insertCodeblock: {
    id: "insertCodeblock",
    label: "Insert code block",
    keys: "```",
    isCustomizable: false,
  },
  insertParagraph: {
    id: "insertParagraph",
    // to break out of codeblock
    label: "Leave code block",
    keys: "Shift-Enter",
    isCustomizable: false,
  },
  insertBr: {
    id: "insertBr",
    keys: "Enter",
    isCustomizable: false,
  },
  tab: {
    id: "tab",
    keys: "Tab",
    isCustomizable: false,
  },
  shiftTab: {
    id: "shiftTab",
    keys: "Shift-Tab",
    isCustomizable: false,
  },
  selectAll: {
    id: "selectAll",
    label: "Select note",
    keys: ctrlCmd("a"),
    isCustomizable: false,
  },
  undo: {
    id: "undo",
    label: "Undo",
    keys: ctrlCmd("z"),
    isCustomizable: false,
  },
  redo: {
    id: "redo",
    label: "Redo",
    // We intentionally don't use the shift('z') utility function here because
    // that seems to break things and make Redo not work on at least macOS.
    keys: ctrlCmd("Shift-z"),
    isCustomizable: false,
  },
  goToLineStart: {
    id: "goToLineStart",
    label: "Go to start of line",
    keys: isApple ? "Ctrl-a" : "Home",
    isCustomizable: false,
  },
  goToLineEnd: {
    id: "goToLineEnd",
    label: "Go to end of line",
    keys: isApple ? "Ctrl-e" : "End",
    isCustomizable: false,
  },
  goToNoteStart: {
    id: "goToNoteStart",
    label: "Go to start of note",
    keys: ctrlCmd("ArrowUp"),
    isCustomizable: false,
  },
  goToNoteEnd: {
    id: "goToNoteEnd",
    label: "Go to end of note",
    keys: ctrlCmd("ArrowDown"),
    isCustomizable: false,
  },
  collapseReference: {
    id: "collapseReference",
    label: "Collapse reference",
    keys: "Alt-ArrowUp",
    isCustomizable: false,
  },
  expandReference: {
    id: "expandReference",
    label: "Expand reference",
    keys: "Alt-ArrowDown",
    isCustomizable: false,
  },
  collapseBacklinks: {
    id: "collapseBacklinks",
    label: "Collapse backlinks",
    keys: ctrlCmd("Alt-ArrowUp"),
    isCustomizable: false,
  },
  expandBacklinks: {
    id: "expandBacklinks",
    label: "Expand backlinks",
    keys: ctrlCmd("Alt-ArrowDown"),
    isCustomizable: false,
  },
  selectUntilNoteStart: {
    id: "selectUntilNoteStart",
    label: "Select to start of note",
    keys: ctrlCmd("Shift-ArrowUp"),
    isCustomizable: false,
  },
  selectUntilNoteEnd: {
    id: "selectUntilNoteEnd",
    label: "Select to end of note",
    keys: ctrlCmd("Shift-ArrowDown"),
    isCustomizable: false,
  },
  splitNote: {
    id: "splitNote",
    keys: ctrlCmd("Enter"),
    isCustomizable: false,
  },
  toggleItalics: {
    id: "toggleItalics",
    label: "Italicize text",
    keys: ctrlCmd("i"),
    isCustomizable: false,
  },
  toggleBold: {
    id: "toggleBold",
    label: "Bold text",
    keys: ctrlCmd("b"),
    isCustomizable: false,
  },
  toggleUnderline: {
    id: "toggleUnderline",
    label: "Underline text",
    keys: ctrlCmd("u"),
    isCustomizable: false,
  },
  clearFormatting: {
    id: "clearFormatting",
    label: "Clear Formatting",
    keys: isApple ? ctrlCmd("\\") : ctrlCmd(" "),
    isCustomizable: false,
  },
  toggleCheckbox: {
    id: "toggleCheckbox",
    label: "Toggle checkbox",
    keys: ctrlCmd(shift("y")),
    isCustomizable: false,
  },
  toggleStrikethrough: {
    id: "toggleStrikethrough",
    label: "Strikethrough text",
    keys: ctrlCmd(shift("x")),
    isCustomizable: false,
  },
  backspace: {
    id: "backspace",
    keys: "Backspace",
    isCustomizable: false,
  },
  delete: {
    id: "delete",
    keys: "Delete",
    isCustomizable: false,
  },
  showInTimeline: {
    id: "showInTimeline",
    label: "Show in timeline",
    keys: "Ctrl-Shift-3",
    isCustomizable: false,
  },
  confirmCreateNewNoteInAutocomplete: {
    id: "confirmCreateNewNoteInAutocomplete",
    label: "Create new note skipping autocomplete",
    keys: ctrlCmd("Enter"),
    isCustomizable: false,
  },
} satisfies Record<Shortcut["id"], Shortcut>;
