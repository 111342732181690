import { regexpMatchers } from "../../utils/mark/regexpMatchers";
import {
  noteList,
  noteFormatter,
  suggestionsSearcher,
} from "../../../model/services";
import { Suggestion } from "../../../search/SuggestionSearcher";
import { Node as PMNode } from "prosemirror-model";
import { schema } from "../../schema";
import { generateId } from "../../../model/generateId";
import { NoteId } from "../../../model/types";
import { trackEvent } from "../../../analytics/analyticsHandlers";
import { ShortcutIcons } from "../../../shortcuts/KeyIcons";
import { shortcuts } from "../../../shortcuts/shortcuts";

export type AutocompleteTypes = "spaceship" | "hashtag";

interface AutocompleteModule<T = AutocompleteTypes> {
  getSuggestions: (text: string, noteId: NoteId) => Suggestion[];
  createNode: (attrs: { id: string; content: string }) => PMNode;
  matcherName: T;
  regexp: RegExp;
  prefix: string;
}

const modules: { [x in AutocompleteTypes]: AutocompleteModule<x> } = {
  spaceship: {
    matcherName: "spaceship",
    prefix: "+",
    regexp: regexpMatchers.spaceship,
    createNode: (attrs) => {
      trackEvent(["RELATIONS", "CREATED"]);
      return schema.nodes.reference.create({
        tokenId: generateId(),
        linkedNoteId: attrs.id,
        content: noteFormatter.getNoteEllipsis(attrs.id),
      });
    },
    getSuggestions: (text, noteId) => {
      const suggestions = suggestionsSearcher.suggests(text, "spaceship", {
        noteId,
      });

      if (text === "") {
        return [
          {
            id: "",
            display: `Create blank note`,
            content: text,
            action: "create-and-expand",
          },
          ...suggestions,
        ];
      }

      return [
        ...suggestions,
        {
          id: "",
          display: (
            <>
              Create new note "{text}"{" "}
              <span style={{ fontStyle: "normal" }}>
                (
                <ShortcutIcons
                  keys={shortcuts.confirmCreateNewNoteInAutocomplete.keys}
                />
                )
              </span>
            </>
          ),

          content: text,
          action: "create",
        },
      ];
    },
  },
  hashtag: {
    matcherName: "hashtag",
    prefix: "#",
    regexp: regexpMatchers.hashtag,
    createNode: (attrs) =>
      schema.text(attrs.content, [schema.marks.hashtag.create()]),
    getSuggestions: (text, noteId) => {
      const suggestions = suggestionsSearcher.suggests(text, "hashtag", {
        noteId,
      });

      const currentHashtag = "#" + text;
      const exactHashtagAlreadyExists =
        noteList.hashtags.getNoteCountForItem(currentHashtag) > 1;
      return suggestions.filter(
        (i) =>
          i.content !== currentHashtag ||
          (i.content === currentHashtag && exactHashtagAlreadyExists),
      );
    },
  },
};

export const autocompleteModules = Object.entries(modules).reduce(
  (acc, [key, value]) => ({
    ...acc,
    // strip flags except "u" from provided regexp
    [key]: { ...value, regexp: new RegExp(value.regexp.source, "u") },
  }),
  {} as typeof modules,
);
