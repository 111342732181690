import { NodeSpec } from "prosemirror-model";
import { domAttrs, getDefaultAttrs } from "../../utils/attrs";

export const note: NodeSpec = {
  content: "topLevelNode+ backlinks?",
  group: "note",
  selectable: false,
  draggable: false,
  defining: true,
  attrs: {
    noteId: {},
    path: {},
    depth: { default: 0 },
    highlighted: { default: false },
    folderId: { default: null },
    isCondensable: { default: false }, // whether note can be condensed i.e. contains ellipses
    isExpanded: { default: false }, // false when condensable and condensed, true otherwise
    insertedAt: { default: null },
  },
  parseDOM: [
    {
      tag: "div.note",
      getAttrs: getDefaultAttrs([
        "noteId",
        "path",
        { key: "depth", type: "int" },
        "folderId",
        "isExpanded",
      ]),
    },
  ],
  toDOM(node) {
    return [
      "div",
      {
        ...domAttrs(node, [
          "noteId",
          "path",
          "depth",
          "folderId",
          "isExpanded",
          "isCondensable",
        ]),
        class:
          "note" +
          (node.attrs.highlighted ? " highlighted" : "") +
          (node.attrs.isExpanded ? " isExpanded" : ""),
      },
      0,
    ];
  },
};
