import {
  BlockToken,
  CodeblockToken,
  InlineToken,
  ParagraphToken,
} from "../../model/types";
import { assertUnreachable } from "../assertUnreachable";

/** Creates a function that loops though each token in a
 *  note and applies a callback function to block nodes.
 * @param blockTokenMapFn callback function that applies some operation to block nodes
 * @param joinOperator (optional) string join operation to perform on values returned by the blockTokenProcessor
 */
export const mapBlockTokens = <T>(
  blockTokenMapFn: (blockToken: ParagraphToken | CodeblockToken) => T,
  joinOperator?: string,
): ((t: BlockToken) => T[] | string) => {
  const processTopLevelToken = (topLevelToken: BlockToken): any => {
    switch (topLevelToken.type) {
      case "codeblock":
      case "paragraph":
        return blockTokenMapFn(topLevelToken);
      case "list": {
        const processedContent = topLevelToken.content.map((listItem: any) =>
          listItem.content.map(blockTokenMapFn),
        );
        if (joinOperator != null)
          return processedContent.flat().join(joinOperator);
        return processedContent;
      }
      default:
        assertUnreachable(topLevelToken);
        throw new Error("unknown top level token");
    }
  };
  return processTopLevelToken;
};

export const applyOnInline = (
  tokens: BlockToken[],
  callback: (t: InlineToken) => any,
) => {
  tokens.forEach((bT) => {
    if (bT.type === "paragraph") {
      bT.content.forEach(callback);
    }
    if (bT.type === "list") {
      if (bT.content) {
        bT.content.forEach((li) => applyOnInline(li.content, callback));
      }
    }
  });
};
