/**
 * Main Layout of the app, as well as coordinating some global state for now.
 *
 * It's mostly just CSS.
 */
import * as Sentry from "@sentry/nextjs";
import React, { useEffect } from "react";
import { MessageBox } from "./modal/MessageBox";
import { ModalRoot } from "./modal/ModalRoot";
import { ImportModal } from "./export/ImportModal";
import { useAuth } from "./auth/useAuth";
import LoginButton from "./auth/LoginButton";
import styled from "@emotion/styled";
import {
  isAuthEnabled,
  isElectron,
  isIOs,
  isMac,
  isPersistenceEnabled,
  isTouchDevice,
  isUploadEnabled,
} from "./utils/environment";
import { useColorScheme } from "./settings/ColorScheme";
import { setUserId } from "./analytics/analyticsHandlers";

const LayoutRoot = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 0;
  padding: 0;
  width: 100vw;
  max-width: 100%;
  overflow: hidden;
  height: 100%;
  height: -webkit-fill-available;
`;

const FeatureFlagContainer = styled.div`
  background-color: red;
  color: white;
  position: fixed;
  display: flex;
  justify-content: center;
  bottom: 0;
  left: 0;
  z-index: 1;
  padding: 1px 6px;
`;

const FeatureFlag = () => {
  const deactivatedFeatures = [];
  if (!isAuthEnabled) deactivatedFeatures.push("authentication");
  if (!isPersistenceEnabled) deactivatedFeatures.push("persistence");
  if (!isUploadEnabled) deactivatedFeatures.push("upload");
  if (deactivatedFeatures.length === 0) return null;
  return (
    <FeatureFlagContainer role="status" id="features-deactivated">
      features deactivated: {deactivatedFeatures.join(", ")}
    </FeatureFlagContainer>
  );
};

const DevNoticeContainer = styled.div`
  background-color: #e4975d;
  color: white;
  position: fixed;
  display: flex;
  align-content: center;
  justify-content: center;
  bottom: 0;
  right: 16px;
  z-index: 100;
  padding: 4px 8px;
  border-radius: 4px 4px 0 0;
  pointer-events: none;
  user-select: none;
  > span {
    max-width: 130px;
    text-overflow: ellipsis;
    overflow-x: hidden;
    padding-right: 6px;
    font-size: 14px;
    line-height: 22px;
    white-space: pre;
  }
`;

const DevelopmentNotice = () => {
  if (window.location.hostname === "ideaflow.app") {
    return null;
  }

  const allFlags = [
    isMac ? "isMac" : null,
    isIOs ? "isIOs" : null,
    isTouchDevice ? "isTouchDevice" : null,
    isElectron ? "isElectron" : null,
  ].filter((flag) => flag !== null);

  return (
    <DevNoticeContainer role="status">
      <span>on {window.location.hostname}</span>
      <b>
        NOT production, {allFlags.length > 0 && <> ({allFlags.join(", ")})</>}
      </b>
    </DevNoticeContainer>
  );
};

export const MainLayout: React.FC<{
  requireLogin: boolean;
  children?: React.ReactNode;
}> = ({ requireLogin, children }) => {
  const { isAuthenticated, isLoading, user } = useAuth();
  useColorScheme();

  useEffect(() => {
    if (!isAuthenticated) return;

    if (user && user.email) {
      setUserId(user.email);
      Sentry.setUser({ email: user.email });
    }
  }, [isAuthenticated, user]);

  if (isLoading) {
    return <LayoutRoot role="presentation">Loading</LayoutRoot>;
  }
  if (!isAuthenticated && requireLogin) {
    return (
      <LayoutRoot role="presentation">
        <LoginButton />
      </LayoutRoot>
    );
  }

  return (
    <LayoutRoot role="presentation">
      <FeatureFlag />
      <DevelopmentNotice />
      <MessageBox />
      <ModalRoot>
        {(modal: string) => {
          switch (modal) {
            case "import":
              return <ImportModal />;
            default:
              return null;
          }
        }}
      </ModalRoot>
      {children}
    </LayoutRoot>
  );
};
