import React, { useEffect } from "react";
import { useAtom } from "jotai";
import styled from "@emotion/styled";
import { messageAtom, NO_MESSAGE } from "./messageAtom";

import { colors } from "../utils/style";
import { useCaptureError } from "./useCaptureError";
import { isMac } from "../utils/environment";
import { captureMessage } from "@sentry/nextjs";

const MesContainer = styled.div`
  position: fixed;
  display: flex;
  justify-content: center;
  top: 0;
  width: 100%;
  text-align: center;
  z-index: 100;
  pointer-events: none;
`;

const MesInner = styled.div`
  color: white;
  padding: 2px;
  border-bottom-left-radius: var(--radius-large);
  border-bottom-right-radius: var(--radius-large);
  font-size: 12px;
  width: 300px;
  pointer-events: all;
`;

export function MessageBox() {
  const [message, setMessage] = useAtom(messageAtom);
  useCaptureError();
  // alert message when pressing ctrl+s
  useEffect(() => {
    function deactivate(e: KeyboardEvent) {
      // e.key can be undefined, for example when user presses Enter after selecting
      // an autocomplete entry in the built-in browser autocomplete dropdown.
      // See https://codepen.io/errendir/pen/RwVjZLr?editors=1010
      if (
        e.key !== undefined &&
        e.key.toLowerCase() === "s" &&
        (isMac ? e.metaKey : e.ctrlKey)
      ) {
        e.preventDefault();
        (globalThis as any).manualSync();
        setMessage({
          type: "ok",
          content: "Your notes are saved automatically :)",
        });
      }
    }
    document.addEventListener("keydown", deactivate, false);
    return () => document.removeEventListener("keydown", deactivate);
  }, [setMessage]);

  useEffect(() => {
    let timer = 0;
    // errors stay displayed on screen
    if (message.type !== "error") {
      window.setTimeout(
        () => (timer = window.setTimeout(() => setMessage(NO_MESSAGE), 5000)),
      );
    }
    if (["error", "fatal"].includes(message.type)) {
      captureMessage(message.content);
    }
    return () => window.clearTimeout(timer);
  }, [message, setMessage]);

  if (!message.content) return null;

  const backgroundColor = colors.bg[message.type];
  return (
    <>
      <MesContainer>
        <MesInner
          style={{ backgroundColor }}
          dangerouslySetInnerHTML={{ __html: message.content }}
        ></MesInner>
      </MesContainer>
    </>
  );
}
