import { Selection } from "prosemirror-state";
import { schema } from "../../schema";
import { Command } from "prosemirror-state";
import {
  expandOrCollapseSpaceship,
  getLinkedNotePosFromSpaceshipPos,
  getSpaceshipPosFromExpandedNotePos,
} from "./referenceExpansionUtils";
import { noteList, notePositions } from "../../../model/services";
import { generateId } from "../../../model/generateId";
import { noteToProsemirrorNode } from "../../bridge";
import { trackEvent } from "../../../analytics/analyticsHandlers";
import { findParent } from "../../utils/find";

/**
 * Create new note and append expanded reference to it below current selection.
 */
export const appendExpandedReferenceCommand: Command = (
  state,
  dispatch,
): boolean => {
  if (!dispatch) return false;

  const { $from, $to } = state.selection;

  // Get root note and expansion
  const rootNote = $from.node(1);
  const [expansion, posExpansion] = findParent(
    state.doc,
    $from.pos,
    (n) => n.type === schema.nodes.expandedReference,
  );

  // selection must be inside an expansion
  if (!expansion) {
    return false;
  }
  const posExpansionEnd = posExpansion + expansion.nodeSize;
  if ($from.pos < posExpansion || $to.pos > posExpansionEnd) {
    return false;
  }

  // Don't allow command inside a backlink expansion
  const expParent = state.doc.resolve(posExpansion).parent;
  if (expParent.type === schema.nodes.backlinks) {
    return false;
  }

  const tr = state.tr;

  // create new blank note and insert below
  const [newNote] = noteList.insert({
    position: notePositions.generateAfter(rootNote.attrs.position)[0],
  });
  tr.insert(tr.selection.$anchor.after(1), noteToProsemirrorNode(newNote));

  // create a spaceship to the new note and insert beside the
  // spaceship linked to the current expansion
  const posSpaceship = getSpaceshipPosFromExpandedNotePos(
    state.doc,
    tr.mapping.map(posExpansion),
  );
  const newSpaceship = schema.nodes.reference.create({
    tokenId: generateId(),
    linkedNoteId: newNote.id,
  });
  tr.insert(posSpaceship + 1, [schema.text(" "), newSpaceship]);
  const posNewSpaceship = posSpaceship + 2; // +1 for the space, +1 for the new node

  // Expand the new spaceship
  if (tr.doc.nodeAt(posNewSpaceship)?.attrs.linkedNoteId !== newNote.id) {
    throw new Error("Can't find new spaceship to expand");
  }
  expandOrCollapseSpaceship(tr, tr.doc.resolve(posNewSpaceship));
  const posNewExpansion = getLinkedNotePosFromSpaceshipPos(
    tr.doc,
    posNewSpaceship,
  );
  // Set the cursor inside it
  tr.setSelection(Selection.near(tr.doc.resolve(posNewExpansion)));

  tr.setMeta("type", "appendExpansion");
  dispatch(tr);

  trackEvent(["EXPANSION", "APPEND"]);

  return true;
};
