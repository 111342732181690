// This import must be the first one to avoid other import errors from overshadowing this guard
import "./guardModuleNotOnSW";

import { FolderList } from "./folderList";
import { LastUpdated } from "./ram/LastUpdated";
import { ManyToManyIndex } from "./ram/ManyToManyIndex";
import { RohanCache } from "./sync/rohanCache";
import { Path } from "../editor/utils/path";

/**
 * Instantiates all the services needed when running the app.
 */

import { NoteList } from "./noteList";
import { PositionGenerator } from "./positionGenerator";
import { ObjectExporter } from "../export/toObject";
import { Searcher } from "../search/Searcher";
import { NoteFormatter, BlockText } from "./noteFormatter";
import { SuggestionSearcher } from "../search/SuggestionSearcher";
import { FlatIndex } from "./ram/FlatIndex";
import { NoteId, TokenId, Note, Folder, FolderId } from "./types";
import modelVersion from "../service-worker/modelVersion";

// indexes are not exposed
const hashtagIndex = new ManyToManyIndex(true);
const spaceshipIndex = new ManyToManyIndex(false);
const lastUpdatedHashtags = new LastUpdated(30);
const blockTextIndex = new FlatIndex<NoteId, BlockText[]>();

export type NoteMap = Map<NoteId, Note>;
const noteMap: NoteMap = new Map();

export const noteFormatter = new NoteFormatter(noteMap);
export const noteList = new NoteList(
  noteMap,
  noteFormatter,
  hashtagIndex,
  spaceshipIndex,
  lastUpdatedHashtags,
  blockTextIndex,
);

export type FolderMap = Map<FolderId, Folder>;
const folderMap: FolderMap = new Map();

export const folderList = new FolderList(folderMap);

export const noteSearcher = new Searcher(noteList, folderList, blockTextIndex);
export const suggestionsSearcher = new SuggestionSearcher(
  noteList,
  blockTextIndex,
);
export const objectExporter = new ObjectExporter(noteSearcher, noteMap);

export const cache = new RohanCache(
  ["notes", "folders", "metadata"],
  modelVersion,
);

// Positions
export const notePositions = new PositionGenerator(noteList, (e) => e.position);
export const pinnedNotePositions = new PositionGenerator(
  noteList,
  (e) => e.positionInPinned ?? null,
);
export const folderPositions = new PositionGenerator(
  folderList,
  (e) => e.position,
);

/**
 * Index of note blocks that are considered matches in the current view.
 * These blocks should *not* be collapsed while in compact view.
 */
export const noteBlockMatches = new FlatIndex<Path, TokenId[]>();

/** A set of paths to references and backlink sections that should be expanded.*/
export const expansionSet = new Set<Path>();
