import { EditorState } from "prosemirror-state";
import { EditorView } from "prosemirror-view";
import { schema } from "../../schema";
import { preserveExpandedSpaceshipsAround } from "../reference/referenceExpansionUtils";

export const insertParagraphCommand = (
  state: EditorState,
  dispatch?: EditorView["dispatch"],
) => {
  const { $from } = state.selection;
  const tr = state.tr;
  preserveExpandedSpaceshipsAround(state, tr, () => {
    tr.deleteSelection().split(state.tr.mapping.map($from.pos), 1, [
      { type: schema.nodes.paragraph },
    ]);
  });
  dispatch?.(tr);
  return true;
};
