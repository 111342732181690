import React from "react";
import { useAuth } from "./useAuth";
import styled from "@emotion/styled";
import { isMac } from "../utils/environment";

const Button = styled.div`
  background-color: #01c8ce;
  cursor: pointer;
  color: white;
  font-weight: 700;
  font-size: 16px;
  border-radius: 4px;
  border: none;
  padding: 8px 16px;
  :hover {
    background-color: #118b96;
  }
`;

const LoginButton = () => {
  const { isLoading, user, isAuthenticated, loginWithRedirect, logout, error } =
    useAuth();

  if (isLoading) {
    return <div>Loading your profile information...</div>;
  }

  if (error) {
    logout();
  }

  // we cannot be authenticated and have no user
  if (isAuthenticated && !user) {
    return (
      <div>
        Oops! We encountered a problem authenticating you. Please refresh by
        pressing {isMac ? " ⌘ + r" : "ctrl  +r"}
      </div>
    );
  }

  if (!isAuthenticated) {
    return (
      <Button
        onClick={async () => {
          try {
            loginWithRedirect();
          } catch (error) {
            console.error(error);
          }
        }}
      >
        Log in
      </Button>
    );
  }

  return null;
};

export default LoginButton;
