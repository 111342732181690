import { EditorState, Selection } from "prosemirror-state";
import { schema } from "../../schema";
import { Command } from "prosemirror-state";
import {
  expandOrCollapseSpaceship,
  getLinkedNotePosFromSpaceshipPos,
  getSpaceshipPosFromExpandedNotePos,
} from "./referenceExpansionUtils";
import { findParent, findResult, getParentNote } from "../../utils/find";

function getSpaceshipNearSelection(state: EditorState): findResult {
  const { $from, $to } = state.selection;
  if ($from.parent.childCount === 0) return [null, null];
  // Get index of child containing selection but clamped to valid range
  const fromIdx = Math.min(
    Math.max(0, $from.index()),
    $from.parent.childCount - 1,
  );
  // return first spaceship inside selection
  for (let idx = fromIdx; idx < $from.parent.childCount; idx++) {
    const pos = $from.posAtIndex(idx);
    if (pos > $to.pos) break;
    const node = $from.parent.child(idx);
    if (node?.type === schema.nodes.reference) {
      return [node, pos];
    }
  }
  // otherwise return first spaceship before selection
  for (let idx = fromIdx; idx >= 0; idx--) {
    const node = $from.parent.child(idx);
    if (node?.type === schema.nodes.reference) {
      return [node, $from.posAtIndex(idx)];
    }
  }
  return [null, null];
}

export const expandReferenceCommand: Command = (state, dispatch): boolean => {
  if (!dispatch) return false;
  const [refNode, refPos] = getSpaceshipNearSelection(state);
  const [, notePos] = getParentNote(state.doc, state.selection.$anchor.pos);
  // Can't expand if we're not in a note or there's no reference nearby
  if (notePos === null || refNode === null) return false;
  // Can't expand if we're already expanded
  if (refNode.attrs.isExpanded) return false;

  const tr = state.tr;
  const res = expandOrCollapseSpaceship(tr, tr.doc.resolve(refPos));
  if (!res) {
    return false;
  }
  const posExpansion = getLinkedNotePosFromSpaceshipPos(
    state.doc,
    tr.mapping.map(refPos),
  );
  tr.setSelection(Selection.near(tr.doc.resolve(posExpansion)));
  tr.setMeta("noChangeToModel", true);
  dispatch(tr);
  return true;
};

const trCollapseReference = (state: EditorState, posSpaceship: number) => {
  const tr = state.tr;
  expandOrCollapseSpaceship(tr, tr.doc.resolve(posSpaceship));
  tr.setSelection(
    Selection.near(tr.doc.resolve(tr.mapping.map(posSpaceship) + 1)),
  );
  tr.setMeta("noChangeToModel", true);
  return tr;
};

export const collapseReferenceCommand: Command = (state, dispatch): boolean => {
  if (!dispatch) return false;
  // Collapse nearby spaceship
  const [refNode, refPos] = getSpaceshipNearSelection(state);
  if (refPos !== null && refNode?.attrs.isExpanded) {
    dispatch(trCollapseReference(state, refPos));
    return true;
  }
  const [expRefNode, expRefPos] = findParent(
    state.doc,
    state.selection.$anchor.pos,
    (n) => n.type === schema.nodes.expandedReference,
  );
  // Or expanded reference we're inside of
  if (!!expRefNode && !expRefNode.attrs.isBacklink) {
    const pos = getSpaceshipPosFromExpandedNotePos(state.doc, expRefPos);
    dispatch(trCollapseReference(state, pos));
    return true;
  }
  return false;
};
