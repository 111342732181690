import { InputRule, inputRules } from "prosemirror-inputrules";
import { schema } from "../../schema";

function createTodo(regexp: any) {
  return new InputRule(regexp, (state, match, start, end) => {
    const checkbox = schema.nodes.checkbox.create({
      isChecked: match[0] === "[x]",
    });
    const tr = state.tr.delete(start, end);
    tr.insert(start, checkbox);
    return tr;
  });
}

const checkboxPlugin = inputRules({
  rules: [createTodo(/\[(\s|x)?\]$/g)],
});

export { checkboxPlugin };
