import { generateId } from "../../model/generateId";
import { Folder, Note } from "../../model/types";

export const getDefaultNotes = (
  graphId: string | undefined,
): {
  id: string;
  notes: Note[];
  folders: Folder[];
} => {
  if (!graphId) {
    throw new Error("cannot getDefaultThoughtstream, undefined graphId");
  }
  const thoughtstreamId = graphId;

  // hardcoded, default notes should rarely change
  const referenceId = [...new Array(100)].map(() => generateId());

  const date = new Date();
  const ISODate = date;
  const insertedAt = `${date.getFullYear()}${(date.getMonth() + 1)
    .toString(10)
    .padStart(2, "0")}${date.getDate().toString().padStart(2, "0")}`;

  const defaultFields = {
    createdAt: ISODate,
    updatedAt: ISODate,
    masterRev: "",
    insertedAt,
    folderId: null,
    readAll: false,
    deletedAt: null,
    positionInPinned: null,
    importSource: null,
    importBatch: null,
    importForeignId: null,
  };

  return {
    id: thoughtstreamId,
    folders: [],
    notes: [
      {
        id: referenceId[0],
        tokens: [
          {
            type: "paragraph",
            tokenId: generateId(),
            content: [
              {
                type: "text",
                marks: ["bold"],
                content: "Welcome to your second brain, ",
              },
              {
                type: "spaceship",
                linkedNoteId: referenceId[3],
                tokenId: generateId(),
              },
              {
                type: "text",
                marks: [],
                content: "!",
              },
              {
                type: "text",
                marks: ["bold"],
                content: "💡",
              },
            ],
          },
          {
            type: "paragraph",
            content: [],
            tokenId: generateId(),
          },
          {
            type: "paragraph",
            tokenId: generateId(),
            content: [
              {
                type: "text",
                marks: [],
                content:
                  "Here are a few quick tips and tricks for navigating your notebook, or as we like to call it, your ",
              },
              {
                type: "text",
                marks: ["italic"],
                content: "ThoughtStream",
              },
              {
                type: "text",
                marks: [],
                content: ". 💭 🌊",
              },
            ],
          },
        ],
        localRev: generateId(),
        position: "aF",
        ...defaultFields,
      },
      {
        id: referenceId[1],
        tokens: [
          {
            type: "paragraph",
            tokenId: generateId(),
            content: [
              {
                type: "text",
                marks: [],
                content:
                  "Each space contained between two dividers is called a note. Notes are used to separate different thoughts, similar to how your brain stores distinct thoughts. Included below are a couple of examples 👇",
              },
            ],
          },
        ],
        position: "aL",
        localRev: generateId(),
        ...defaultFields,
      },
      {
        id: referenceId[2],
        tokens: [
          {
            type: "paragraph",
            tokenId: generateId(),
            content: [
              {
                type: "text",
                marks: [],
                content: "#️⃣ ",
              },
              {
                type: "text",
                marks: ["bold"],
                content: "Hashtags",
              },
              {
                type: "text",
                marks: [],
                content: " like ",
              },
              {
                type: "hashtag",
                content: "#book",
              },
              {
                type: "text",
                marks: [],
                content:
                  " are used to tag notes into categories, similar to a desktop folder.",
              },
            ],
          },
          {
            type: "paragraph",
            tokenId: generateId(),
            content: [
              {
                type: "text",
                marks: [],
                content: "🔗 ",
              },
              {
                type: "text",
                marks: ["bold"],
                content: "References,",
              },
              {
                type: "text",
                marks: [],
                content:
                  " accessible through the “+” key, allow you to link, display, and edit related notes while recording a new note.",
              },
            ],
          },
          {
            type: "list",
            content: [
              {
                type: "listItem",
                content: [
                  {
                    type: "paragraph",
                    tokenId: generateId(),
                    content: [
                      {
                        type: "text",
                        marks: [],
                        content:
                          "References unlock a new paradigm in note-taking and memory augmentation, helping elevate your digital knowledge-base to a network that reveals powerful insights.",
                      },
                    ],
                  },
                ],
                depth: 0,
              },
            ],
          },
        ],
        position: "aO",
        localRev: generateId(),
        ...defaultFields,
      },
      {
        id: referenceId[3],
        tokens: [
          {
            type: "paragraph",
            tokenId: generateId(),
            content: [
              {
                type: "text",
                marks: ["bold"],
                content: "Ideaflow",
              },
            ],
          },
          {
            type: "paragraph",
            tokenId: generateId(),
            content: [],
          },
          {
            type: "list",
            content: [
              {
                type: "listItem",
                content: [
                  {
                    type: "paragraph",
                    tokenId: generateId(),
                    content: [
                      {
                        type: "text",
                        marks: [],
                        content: "Seed-stage startup backed by ",
                      },
                      {
                        type: "spaceship",
                        linkedNoteId: referenceId[4],
                        tokenId: generateId(),
                      },
                      {
                        type: "text",
                        marks: [],
                        content: " and ",
                      },
                      {
                        type: "spaceship",
                        linkedNoteId: referenceId[5],
                        tokenId: generateId(),
                      },
                    ],
                  },
                ],
                depth: 0,
              },
              {
                type: "listItem",
                content: [
                  {
                    type: "paragraph",
                    tokenId: generateId(),
                    content: [
                      {
                        type: "text",
                        marks: [],
                        content:
                          "An AI-powered notebook for digital notetakers looking to augment their memory",
                      },
                    ],
                  },
                ],
                depth: 0,
              },
            ],
          },
        ],
        position: "aP",
        localRev: generateId(),
        ...defaultFields,
      },
      {
        id: referenceId[4],
        tokens: [
          {
            type: "paragraph",
            tokenId: generateId(),
            content: [
              {
                type: "text",
                marks: [],
                content: "First Round",
              },
            ],
          },
          {
            type: "paragraph",
            tokenId: generateId(),
            content: [
              {
                type: "text",
                marks: [],
                content: "Seed investor, great partner of ",
              },
              {
                type: "spaceship",
                linkedNoteId: referenceId[3],
                tokenId: generateId(),
              },
            ],
          },
        ],
        position: "ax",
        localRev: generateId(),
        ...defaultFields,
      },
      {
        id: referenceId[5],
        tokens: [
          {
            type: "paragraph",
            tokenId: generateId(),
            content: [
              {
                type: "text",
                marks: [],
                content: "8VC",
              },
            ],
          },
          {
            type: "paragraph",
            tokenId: generateId(),
            content: [
              {
                type: "text",
                marks: [],
                content: "Investor based in SF",
              },
            ],
          },
        ],
        position: "ap",
        localRev: generateId(),
        ...defaultFields,
      },
    ],
  };
};
