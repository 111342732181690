import {
  CombinedError,
  createClient,
  fetchExchange,
  makeOperation,
  Operation,
  OperationContext,
} from "urql";
import { authExchange } from "@urql/exchange-auth";
import jwtDecode, { JwtPayload } from "jwt-decode";
import {
  adminSecret,
  apiUrl,
  isAuthEnabled,
  authMockSub,
} from "../utils/environment";

// Configures our graphqlClient.
// Most of the code is related to auth management.
//
// Use cases covered:
// - auth deactivated (using mock account)
// - not authenticated
// - authenticated with an expired JWT
// - authenticated with a valid JWT
// - the query failed (retry up to 4 times)
//

// It represents the object that the different stages of the authExchange shares.
// null means the auth is not initialized yet.
//
// If current approach works, we could add a state NotInitialized | Deactivated | Initialized to reflect the status of the Auth.
// I set it up as an object instead of just a nullable string to mirror https://formidable.com/open-source/urql/docs/advanced/authentication/
type AuthState = {
  token: string;
} | null;

/**
 * This factory generates a async function to retrieve the current JWT token, renewing it if required.
 * It's wishful thinking to hope that Auth0 will use refresh tokens when calling getTokenSilently() to renew expired JWT,
 * but hey, that may be the case!
 */
const getAuth =
  (getToken: () => Promise<string>) =>
  async (_: { authState: AuthState }): Promise<AuthState> => {
    if (getToken) {
      const token = await getToken();
      return { token };
    }
    return null;
  };

/**
 * Get the authState, the query and blends both together.
 * */
const addAuthToOperation = ({
  authState,
  operation,
}: {
  authState: AuthState;
  operation: Operation;
}) => {
  // auth not initialized yet
  if (!authState || !authState.token || authState.token === "INVALID_JWT") {
    return operation;
  }
  let fetchOptions: OperationContext["fetchOptions"] = undefined;
  // auth deactivated
  if (!isAuthEnabled) {
    if (!adminSecret)
      throw new Error(
        "When using NEXT_PUBLIC_FLAG_AUTH=false, you need to set NEXT_PUBLIC_ADMIN_SECRET to bypass Hasura JWT verification.",
      );
    fetchOptions = {
      headers: {
        "X-Hasura-Role": "user",
        "X-Hasura-User-ID": authMockSub,
        "x-hasura-admin-secret": adminSecret,
      },
    };
  } else {
    if (authState.token) {
      // auth activated
      fetchOptions = {
        headers: { Authorization: `Bearer ${authState.token}` },
      };
    }
  }

  return makeOperation(operation.kind, operation, {
    ...operation.context,
    fetchOptions,
  });
};

/**
 * /!\ Not tested!
 *
 * Filters the query that broke due to auth reasons (missing tokens, invalid token etc).
 * It will call back getAuth as a consequence if it returns true.
 * I could see this function kicking in if there is a long time happening between willAuthError and the query,
 * but I mostly implemented it to inform the errorExchange if we set it up to have nice error messages.
 */
const didAuthError = ({
  error,
  response,
}: {
  error: CombinedError;
  response?: any;
}) => {
  return error.graphQLErrors.some(
    (e: any) => response?.status === 401, //@todo documentation looks broken
  );
};

/**
 * Predicts if a query will fail.
 * It will call getAuth in anticipation, hopefully renewing the token.
 */
const willAuthError = ({ authState }: { authState: AuthState }) => {
  // auth not initialized
  if (!authState) return true;
  // auth deactivated
  if (!isAuthEnabled) {
    if (!adminSecret) {
      return true;
    } else {
      return false;
    }
  }
  // auth up and running
  if (authState.token === "INVALID_JWT") return true;
  try {
    const jwt = jwtDecode<JwtPayload>(authState.token);
    // unparsable JWT
    if (!jwt) return true;
    // expired JWT
    const currentTime = new Date().getTime() / 1000;
    if (currentTime > jwt.exp!) return true;
  } catch (err) {
    return true;
  }

  // valid JWT
  return false;
};

export function getGraphqlUrl() {
  // eslint-disable-next-line no-restricted-globals
  const { protocol, host } = location;
  return (apiUrl || protocol + "//" + host) + "/v1/graphql";
}

export function getGraphqlClient(
  getToken: () => Promise<string>,
  isAuthenticated: boolean,
) {
  if (!isAuthenticated) {
    return null;
  }

  return createClient({
    // When NEXT_PUBLIC_API_URL is empty instead of using "localhost" host
    // use the current hostname the app is opened on (and port 8080)
    // This makes it easier to test on mobile devices on the same network
    url: getGraphqlUrl(),
    exchanges: [
      authExchange({
        getAuth: getAuth(getToken),
        addAuthToOperation,
        didAuthError,
        willAuthError,
      }),
      fetchExchange,
    ],
  });
}
