import { Command } from "prosemirror-state";
import { schema } from "../../schema";

export const toggleCheckbox: Command = (state, dispatch) => {
  const selection = state.selection;
  const range = selection.ranges[0];
  if (!range) return false;

  const cursorPos = range.$from.pos;

  // The strategy here is to
  // 1. Search for a checkbox immediately before cursor
  // 2. Search for a checkbox immediately after cursor
  // 3. Search the current line of text (paragraph) for a checkbox
  // We do this by making attempts to find checkboxes and keeping nodePos and
  // checkboxNode updated when we find one.
  let nodePos = cursorPos;
  let checkboxNode: any;

  // Is there a checkbox immediately before cursor?
  if (cursorPos > 0) {
    const nodeBeforePos = state.doc.nodeAt(cursorPos - 1);
    if (nodeBeforePos && nodeBeforePos.type === schema.nodes.checkbox) {
      nodePos = cursorPos - 1;
      checkboxNode = nodeBeforePos;
    }
  }

  // Is there a checkbox immediately after cursor?
  if (!checkboxNode) {
    const nodeAfterPos = state.doc.nodeAt(cursorPos);
    if (nodeAfterPos && nodeAfterPos.type === schema.nodes.checkbox) {
      checkboxNode = nodeAfterPos;
    }
  }

  // Is there a checkbox in the current paragraph?
  if (!checkboxNode) {
    const parent = range.$from.parent;
    const parentStartPos = range.$from.pos - range.$from.parentOffset;

    parent.forEach((node, childPos) => {
      if (checkboxNode) return;
      if (node.type === schema.nodes.checkbox) {
        nodePos = parentStartPos + childPos;
        checkboxNode = node;
      }
    });
  }

  // Bail if no checkbox found
  if (!checkboxNode) return false;

  dispatch?.(
    state.tr.setNodeMarkup(nodePos, checkboxNode.type, {
      isChecked: !checkboxNode.attrs.isChecked,
    }),
  );

  return true;
};
