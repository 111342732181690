export type FractionKey = string;

export class FractionKeyEncoding {
  digits: string;

  constructor(digits: string) {
    if (digits.split("").sort().join("") !== digits) {
      throw new Error("digits must be in ascending character order");
    }
    this.digits = digits;
  }

  // `a` may be empty string, `b` is null or non-empty string.
  // `a < b` lexicographically if `b` is non-null.
  // no trailing zeros allowed.
  midpoint(a: FractionKey, b: FractionKey | null): FractionKey {
    const { digits } = this;
    if (b !== null && a >= b) {
      throw new Error(a + " >= " + b);
    }
    if (a.slice(-1) === "0" || (b && b.slice(-1) === "0")) {
      throw new Error("trailing zero");
    }
    if (b) {
      // remove longest common prefix.  pad `a` with 0s as we
      // go.  note that we don't need to pad `b`, because it can't
      // end before `a` while traversing the common prefix.
      let n = 0;
      while ((a.charAt(n) || "0") === b.charAt(n)) {
        n++;
      }
      if (n > 0) {
        return b.slice(0, n) + this.midpoint(a.slice(n), b.slice(n));
      }
    }
    // first digits (or lack of digit) are different
    const digitA = a ? digits.indexOf(a.charAt(0)) : 0;
    const digitB = b !== null ? digits.indexOf(b.charAt(0)) : digits.length;
    if (digitB - digitA > 1) {
      const midDigit = Math.round(0.5 * (digitA + digitB));
      return digits.charAt(midDigit);
    } else {
      // first digits are consecutive
      if (b && b.length > 1) {
        return b.slice(0, 1);
      } else {
        // `b` is null or has length 1 (a single digit).
        // the first digit of `a` is the previous digit to `b`,
        // or 9 if `b` is null.
        // given, for example, midpoint('49', '5'), return
        // '4' + midpoint('9', null), which will become
        // '4' + '9' + midpoint('', null), which is '495'
        return digits.charAt(digitA) + this.midpoint(a.slice(1), null);
      }
    }
  }
}
