/**
 * Simple Index keeping one element for one key.
 */
export class FlatIndex<K extends string, T> {
  // maps a given item ID to a set of notes it appears in
  private entries = new Map<K, T>();

  set(noteId: K, text: T) {
    this.entries.set(noteId, text);
  }
  get(id: K) {
    return this.entries.get(id);
  }
  delete(noteId: K) {
    this.entries.delete(noteId);
  }
  clear() {
    this.entries.clear();
  }
}
