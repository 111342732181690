import React from "react";
import { colors } from "../utils/style";

export function Secondary(props: any) {
  const { style, ...otherProps } = props;
  return (
    <button
      style={{
        border: "none",
        cursor: props.disabled ? "default" : "pointer",
        padding: "8px 16px",
        borderColor: colors.text.accent,
        borderWidth: "1px",
        borderRadius: "4px",
        minWidth: "100px",
        margin: "0px 8px",
        ...style,
      }}
      {...otherProps}
    ></button>
  );
}
