import { useEffect, useState } from "react";
import { atom, useAtom } from "jotai";
import useInterval from "../../../utils/useInterval";
import { noteList } from "../../services";
import { sendMessageToSW } from "../handleSwMessage";
import { NoteId } from "../../types";
import styled from "@emotion/styled";
import { useSetAtom } from "jotai";
import { messageAtom } from "../../../modal/messageAtom";
import debug from "debug";
import { isDev, isPersistenceEnabled } from "../../../utils/environment";

export const otherSyncStatusAtom = atom<OtherSyncStats>({
  sw: null,
  server: null,
});

// Stats from the Cli, the SW and the Server gets combined to derive a SyncStatus
// Then the SyncStatus is derived into a SyncIndicatorColor and finally a MessageType.

export type SyncStats = null | {
  sampleDate: number;
  noteCount: number;
  mostRecentUpdatedAt: number;
  dirtyList: NoteId[];
}; // null means not known yet

type CliSyncStats = SyncStats;
export type OtherSyncStats = { sw: SyncStats; server: SyncStats };

const getCliStats = (): CliSyncStats => {
  const allNotes = noteList.getAll(true);
  const mostRecentUpdatedAt = allNotes
    .reduce((p, n) => (n.updatedAt > p ? n.updatedAt : p), new Date(0))
    .getTime();
  return {
    sampleDate: Date.now(),
    noteCount: allNotes.length,
    mostRecentUpdatedAt,
    dirtyList: [],
  };
};

type SyncStatus =
  | "ok"
  | "offline"
  | "server-fatal"
  | "cli-unresponsive"
  | "sw-unresponsive"
  | "cli-sw-count-mismatch" // for more than 10s
  | "sw-server-count-mismatch" // for more than 10s
  | "cli-sw-update-at-mismatch" // for more than 10s
  | "sw-server-update-at-mismatch" // for more than 10s
  | "cli-has-dirty-list" // for more than 10s
  | "sw-has-dirty-list" // for more than 10s
  | "missing-cli-stats"
  | "missing-sw-stats"
  | "booting"
  | "missing-server-stats"
  | "persist-disabled";

type SyncCriticality = "offline" | "unknown" | "ok" | "warning" | "error";

const tooltipMap: { [s in SyncCriticality]: string } = {
  offline: "You are offline",
  unknown: "Please wait, we are downloading your latest notes",
  ok: "Everything is synced",
  warning: "Please wait...",
  error: "A sync error has occured",
};

const bootTime = Date.now();
const MAX_DELAY = 5 * 1_000;
// null means the status cannot be determined, and should be the same as before
const computeSyncStatus = (
  cliStatus: CliSyncStats,
  otherStatus: OtherSyncStats,
): SyncStatus | null => {
  const now = Date.now();
  if (!isPersistenceEnabled) {
    return "persist-disabled";
  }
  // time since boot?
  if (!cliStatus) {
    return now - bootTime > MAX_DELAY ? "missing-cli-stats" : "booting";
  }
  if (!otherStatus.sw) {
    return now - bootTime > MAX_DELAY ? "missing-sw-stats" : "booting";
  }
  const { sw, server } = otherStatus;

  const cli = cliStatus;

  if (now - cli.sampleDate > MAX_DELAY) {
    return "cli-unresponsive";
  }
  if (now - sw.sampleDate > MAX_DELAY) {
    return "sw-unresponsive";
  }

  // if the most recent update happened recently, we consider nothing has changed
  // and if the app just booted as well
  if (now - cli.mostRecentUpdatedAt < MAX_DELAY || now - bootTime < 2_000)
    return null;

  if (cli.noteCount !== sw.noteCount) {
    debug("sync-main")(
      `note count on screen: ${cli.noteCount.toLocaleString()}, in local cache: ${sw.noteCount.toLocaleString()}`,
    );
    // refresh cli
    return "cli-sw-count-mismatch";
  }

  if (cli.mostRecentUpdatedAt !== sw.mostRecentUpdatedAt) {
    debug("sync-main")(
      `most recent update on screen: ${cli.mostRecentUpdatedAt}, in cache screen:${sw.mostRecentUpdatedAt}`,
    );
    // refresh cli
    return "cli-sw-update-at-mismatch";
  }

  if (cli.dirtyList.length > 0) {
    return "cli-has-dirty-list";
  }
  // when online, we can run more checks.
  const isOnline = !!server && now - server.sampleDate < MAX_DELAY;
  if (isOnline) {
    if (!server.noteCount || !server.mostRecentUpdatedAt) {
      return "server-fatal"; // badly formatted server response...
    }
    if (sw.noteCount !== server.noteCount) {
      debug("sync-main")(
        `note count cache: ${sw.noteCount.toLocaleString()}, on server: ${server.noteCount.toLocaleString()}`,
      );
      return "sw-server-count-mismatch";
    }
    if (server.mostRecentUpdatedAt !== sw.mostRecentUpdatedAt) {
      debug("sync-main")(
        `most recent update in cache: ${sw.mostRecentUpdatedAt}, on server:${server.mostRecentUpdatedAt}`,
      );
      return "sw-server-update-at-mismatch";
    }
    // if the service worker cannot checkin with the server, it's normal to keep items in the dirty list.
    if (sw.dirtyList.length > 0) {
      return "sw-has-dirty-list";
    }
  }

  return isOnline ? "ok" : "offline";
};

const getCriticality = (syncStatus: SyncStatus): SyncCriticality => {
  if (syncStatus === "ok") return "ok";
  if (syncStatus === "offline") return "offline";
  if (["booting", "missing-server-stats"].includes(syncStatus))
    return "unknown";
  if (["missing-cli-stats", "missing-sw-stats"].includes(syncStatus))
    return "error";
  return "error";
};
const SyncStatusLine = styled.div`
  border-right: thick solid green;
  margin-left: auto;
  margin-right: 0;
`;

const SyncStatusIcon = () => {
  const [cliStats, setCliStats] = useState<CliSyncStats>(null);
  const [otherStats] = useAtom<OtherSyncStats>(otherSyncStatusAtom);
  const [syncStatus, setSyncStatus] = useState<SyncStatus>("booting");
  const setErrorMessage = useSetAtom(messageAtom);

  useInterval(() => {
    setCliStats(getCliStats());
  }, 2_000);

  useInterval(() => {
    // asks the sw for its stats
    sendMessageToSW({ type: "get-status" });
  }, 2_000);

  useEffect(() => {
    const status = computeSyncStatus(cliStats, otherStats);
    if (status) setSyncStatus(status);
  }, [syncStatus, cliStats, otherStats]);

  const tooltipMessage = `${tooltipMap[getCriticality(syncStatus)]}${
    isDev ? "\n" + syncStatus : ""
  }`;

  return (
    <div
      role="tooltip"
      data-microtip-position="bottom"
      aria-label={tooltipMessage}
      className={`sync-icon ${getCriticality(syncStatus)}`}
      style={{
        height: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        padding: "0 3px",
      }}
      onTouchStart={() => {
        setErrorMessage({
          content: tooltipMap[getCriticality(syncStatus)],
          type: "ok",
        });
      }}
    >
      <SyncStatusLine>&nbsp;</SyncStatusLine>
    </div>
  );
};

export default SyncStatusIcon;
