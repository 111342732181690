import React from "react";
import styled from "@emotion/styled";

import { modalAtom } from "./modalAtom";
import { useSetAtom } from "jotai";

const Divs = {
  Container: styled.div`
    z-index: 10;
    position: fixed;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.2);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  `,
  Content: styled.div`
    z-index: 11;
    display: flex;
    flex-direction: column;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 400px;
    min-height: 200px;
    background-color: var(--color-bg-secondary);
    color: var(--color-text-primary);
    padding: 50px;
    padding-bottom: 70px;
    border-radius: 4px;
    animation: 400ms;
    min-width: 33%;
    animation-name: slideTop;
    h1 {
      margin: 0;
    }
  `,
};
export function Modal({ children }: any) {
  const setModal = useSetAtom(modalAtom);
  return (
    <>
      <Divs.Container onClick={() => setModal("")}></Divs.Container>
      <Divs.Content onClick={(e) => e.stopPropagation()}>
        {children}
      </Divs.Content>
    </>
  );
}
