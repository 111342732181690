import { refreshSyncMark } from "../../editor/features/sync-mark/syncMarkPlugin";
import { NoteId } from "../types";

class NotesInError {
  ids: Set<NoteId> = new Set([]);
  add(id: NoteId) {
    this.ids.add(id);
    refreshSyncMark();
  }

  remove(id: NoteId) {
    this.ids.delete(id);
    refreshSyncMark();
  }

  includes(id: NoteId) {
    return this.ids.has(id);
  }
}

export const notesInError = new NotesInError();
