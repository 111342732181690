import { InlineToken } from "../model/types";

/**
 * Sometimes Prosemirror leaves two adjacent nodes that can be merged unmerged
 * We do not want to have notes with split hashtags, so we join them back up.
 * This can happen when part of the hashtag matches the search query and gets the
 * searchQueryMatch mark added (linear.app/ideaflow/issue/ENT-1525)
 */
export function joinCompatibleAdjacentInlineTokens(tokens: InlineToken[]) {
  if (tokens.length === 0) return [];
  // eslint-disable-next-line prefer-const
  let [lastToken, ...rest] = tokens;
  const joinedTokens: InlineToken[] = [];
  for (const token of rest) {
    const mergedToken = attemptToMergeAdjacentInlineTokens(lastToken, token);
    if (mergedToken) {
      lastToken = mergedToken;
    } else {
      joinedTokens.push(lastToken);
      lastToken = token;
    }
  }

  joinedTokens.push(lastToken);
  return joinedTokens;
}

function attemptToMergeAdjacentInlineTokens(
  token1: InlineToken,
  token2: InlineToken,
): InlineToken | null {
  if (token1.type !== token2.type) return null;
  if (token1.type === "hashtag") {
    return {
      type: "hashtag",
      content: token1.content + (token2 as any).content,
    };
  }
  if (token1.type === "link") {
    return {
      type: "link",
      slug: token1.slug + (token2 as any).slug,
      content: token1.content + (token2 as any).content,
    };
  }

  return null;
}
