import { TextSelection } from "prosemirror-state";

import { Command } from "prosemirror-state";

export const goToLineStart: Command = (state, dispatch) => {
  const head = state.selection.head;
  const lineStart = state.doc.resolve(head).start();
  if (lineStart !== head) {
    dispatch?.(
      state.tr.setSelection(new TextSelection(state.doc.resolve(lineStart))),
    );
  }
  return true;
};

export const goToLineEnd: Command = (state, dispatch) => {
  const head = state.selection.head;
  const lineEnd = state.doc.resolve(head).end();
  if (lineEnd !== head) {
    dispatch?.(
      state.tr.setSelection(new TextSelection(state.doc.resolve(lineEnd))),
    );
  }
  return true;
};
