import { Plugin, PluginKey } from "prosemirror-state";
import { DispatchToModel } from "../../editorPage/Editor";
import { getTopLevelTokensFromContent } from "../bridge";
import { getNotesThatChangedInTree } from "./getNotesThatChangedInTree";
import { trackEvent } from "../../analytics/analyticsHandlers";
import { SearchQuery } from "../../search/SearchQuery";
import { Node } from "prosemirror-model";

import * as Sentry from "@sentry/nextjs";
import isEqual from "lodash.isequal";

export const persistencePlugin = (
  dispatchToModel: DispatchToModel,
  searchQuery: SearchQuery,
) => {
  return new Plugin({
    key: new PluginKey("persistence"),
    state: {
      init: () => {},
      /** we don't keep a state on the plugin, but we use the apply to watch
       * all transactions and update our model to match
       */
      apply(tr, _, oldState, newState) {
        if (tr.getMeta("noChangeToModel") || !tr.docChanged) return;

        const notesThatChanged = getNotesThatChangedInTree(
          oldState.doc,
          newState.doc,
        );

        // Synchronize deletes to the model
        notesThatChanged.deletes.forEach((noteId) => {
          trackEvent([
            "NOTE",
            searchQuery.type === "folder" ? "DELETED_FROM_FOLDER" : "DELETED",
            noteId,
          ]);
        });
        dispatchToModel({ type: "delete", payload: notesThatChanged.deletes });

        const upsertsByNoteId = new Map<string, Node[]>();
        for (const node of notesThatChanged.upserts) {
          const arr = upsertsByNoteId.get(node.attrs.noteId) ?? [];
          arr.push(node);
          upsertsByNoteId.set(node.attrs.noteId, arr);
        }

        // Synchronize upserts to the model
        const payload = Array.from(upsertsByNoteId.entries()).map(
          ([noteId, upsertedNotes]) => {
            const [firstNote] = upsertedNotes;
            const topLevelTokens = getTopLevelTokensFromContent(
              firstNote.content,
            );
            for (const otherNote of upsertedNotes.slice(1)) {
              if (
                !isEqual(
                  getTopLevelTokensFromContent(otherNote.content),
                  topLevelTokens,
                )
              ) {
                console.error(
                  `Two edited nodes with the same noteId (${noteId}) disagree on tokens!`,
                );
                Sentry.captureMessage(
                  "persistence-plugin-inconsistent-tokens",
                  {
                    level: "error",
                  },
                );
              }
            }
            const { insertedAt, depth } = firstNote.attrs;
            trackEvent([
              "NOTE",
              depth === 0 ? "UPDATED" : "UPDATED_FROM_EXPANSION",
              noteId,
            ]);
            return {
              id: noteId,
              tokens: topLevelTokens,
              ...(insertedAt ? { insertedAt } : {}),
              // The upserted note might have been brought back by undo, so we must mark it as not deleted
              deletedAt: null,
            };
          },
        );
        dispatchToModel({ type: "update", payload });

        if (notesThatChanged.deletes.length > 0) {
          // The notes count has changed, update the sidebar
          dispatchToModel({ type: "refreshSidebar" });
        }
      },
    },
  });
};
