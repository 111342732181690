import debug from "debug";
import { noteList, folderList } from "../services";
import { Note, Folder } from "../types";

export function loadModel(notes: Note[], folders: Folder[]) {
  debug("sync-main")(
    `loading ${notes.length} notes and ${folders.length} folders coming from ${origin}`,
  );
  // load folders before notes
  folders.forEach((folder) => {
    folderList.upsert(folder, false);
  });
  // when loading on the cli, the local_rev from the sw is
  // the master rev of the cli
  noteList.upsert(notes.map((n) => ({ ...n, masterRev: n.localRev })));
}
