/* eslint-disable node/no-process-env */
import { mobileWidth } from "./style";

/** **Warning**: this flag can be true on iPads too (Safari and Chrome sometimes pretend to be running on a Mac) */
export const isMac =
  (typeof navigator !== "undefined" && !!navigator.platform.match("Mac")) ||
  process.platform === "darwin"; // We also check process.platform since `navigator` is not available in TestCafe tests' backend environment

/** Is it an IOs web? Should be only used for safari mobile exclusive things. */
export const isIOs =
  typeof navigator !== "undefined" &&
  /(iPhone|iPad|iPod).*WebKit/.test(navigator.userAgent);

export const isApple = isIOs || isMac;
// TODO: Figure out how to do this without `eval`, right now the problem is that
// webpack incorrectly assumes windows is always an object in all contexts on the web and so
// it simplifies the expression `typeof window !== 'undefined'` to `true` which is incorrect
// inside of the SharedWorker
// eslint-disable-next-line no-eval
const isFrontend = eval("typeof window !== 'undefined'");

// eslint-disable-next-line no-eval
export const isWorker = eval("typeof WorkerGlobalScope !== 'undefined'");

export const isGreaterThanMobileWidth =
  isFrontend && window.innerWidth > mobileWidth;

export const isMobileWidth = !isGreaterThanMobileWidth;

export const isTouchDevice = isFrontend && "ontouchstart" in window;

export const isElectron = isFrontend && !!(window as any).api;

export const isServerSide = !(process as any).browser;

export const isClientSide = !isServerSide;

export const isProd = process.env.NODE_ENV === "production";
export const isDev = process.env.NODE_ENV === "development";

/// FEATURE FLAGS

export const isAuthEnabled = process.env.NEXT_PUBLIC_FLAG_AUTH === "true";

export const isAnalyticsEnabled =
  process.env.NEXT_PUBLIC_FLAG_ANALYTICS === "true";

export const isPersistenceEnabled =
  process.env.NEXT_PUBLIC_FLAG_PERSISTENCE === "true";

export const isUploadEnabled = process.env.NEXT_PUBLIC_FLAG_UPLOAD === "true";

export const isContinuousIntegration =
  process.env.NEXT_PUBLIC_CONTINUOUS_INTEGRATION === "true";

if (isContinuousIntegration && (isPersistenceEnabled || isAuthEnabled)) {
  throw new Error(
    "Invalid environment configuration: disable persistence and auth in CI env",
  );
}

export const isTransactionLogEnabled =
  process.env.NEXT_PUBLIC_LOG_EDITOR_TRANSACTION === "true";

export const urlUpload = process.env.NEXT_PUBLIC_UPLOAD_URL;

export const adminSecret = process.env.NEXT_PUBLIC_ADMIN_SECRET;
/** When auth is disabled, this is the user that will be logged in*/
export const authMockSub =
  process.env.NEXT_PUBLIC_AUTH_MOCK_SUB || "ideaflow|123456789";

export const matomoUrl = process.env.NEXT_PUBLIC_FLAG_MATOMO_URL;
export const apiUrl = process.env.NEXT_PUBLIC_API_URL;
export const auth0Domain = process.env.NEXT_PUBLIC_AUTH0_DOMAIN;
export const auth0ClientId = process.env.NEXT_PUBLIC_AUTH0_CLIENT_ID;
export const sentryDsn = process.env.NEXT_PUBLIC_SENTRY_DSN;
export const publicVersion = process.env.NEXT_PUBLIC_VERSION;

export const env = process.env.NODE_ENV;
