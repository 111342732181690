import { useEffect } from "react";
import debounce from "lodash.debounce";

/**
 * Add/remove a class on the main document when ctrl is pressed.
 *
 * Used to change the cursor used when pressing ctrl down.
 */
const isCtrlMetaPressed = (e: KeyboardEvent) =>
  e.metaKey || e.key === "Meta" || e.ctrlKey || e.key === "Control";

/** Component to set the status of the Meta/Ctrl for the document */
export const useKeyboardStatusWatcher = () => {
  useEffect(() => {
    const CtrlMetaClass = "CtrlMetaPressed";
    const onKeyDown = debounce(
      (e: KeyboardEvent) => {
        if (isCtrlMetaPressed(e)) document.body.classList.add(CtrlMetaClass);
      },
      50,
      { leading: true, trailing: true },
    );
    const onKeyUp = debounce(
      (e: KeyboardEvent) => {
        if (isCtrlMetaPressed(e)) document.body.classList.remove(CtrlMetaClass);
      },
      50,
      { leading: true, trailing: true },
    );
    // we also add a mouse move event because if a user presses ctrl(cmd) and
    // then switches focus away from the document (change tabs, change away
    // from the browser, etc.), the `keyup`/`keydown` event will not be
    // registered. so we add a mouse move event to help us reset the cursor
    // in this case
    const onMouseOver = debounce(
      (e: MouseEvent) => {
        if (
          isCtrlMetaPressed(e as any) &&
          document.body.classList.contains(CtrlMetaClass) === false
        ) {
          document.body.classList.add(CtrlMetaClass);
        }
        if (
          isCtrlMetaPressed(e as any) === false &&
          document.body.classList.contains(CtrlMetaClass)
        ) {
          document.body.classList.remove(CtrlMetaClass);
        }
      },
      200,
      { leading: true, trailing: true },
    );

    document.addEventListener("keydown", onKeyDown);
    document.addEventListener("keyup", onKeyUp);
    document.addEventListener("mouseover", onMouseOver);

    return () => {
      document.removeEventListener("keydown", onKeyDown);
      document.removeEventListener("keyup", onKeyUp);
      document.removeEventListener("mouseover", onMouseOver);
    };
  }, []);
};
