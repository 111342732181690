import { toggleIsExpandedAttributeOnSpaceship } from "../../features/reference/referenceExpansionUtils";
import { schema } from "../../schema";
import { EditorView } from "prosemirror-view";
import { ResolvedPos, Slice } from "prosemirror-model";
import { nanoid } from "nanoid";
import { Message } from "../../../modal/messageAtom";
import { noteList, notePositions } from "../../../model/services";
import { NoteId } from "../../../model/types";
import { TextSelection } from "prosemirror-state";
import { trackEvent } from "../../../analytics/analyticsHandlers";
import { isUploadEnabled, urlUpload } from "../../../utils/environment";
import { AuthenticatedServerFetch } from "../../../auth/AccessTokenManager";

function getImageItems(dt: DataTransfer | null) {
  return Array.from(dt?.items ?? []).filter((item) =>
    item.type.includes("image"),
  );
}

export const handleDrop =
  (
    setMessage: (mes: Message) => void,
    authenticatedServerFetch: AuthenticatedServerFetch,
  ) =>
  (
    view: EditorView,
    event: DragEvent,
    slice: Slice,
    moved: boolean,
  ): boolean => {
    const imageFiles = getImageItems(event.dataTransfer).map((item) =>
      item.getAsFile(),
    );
    if (imageFiles.length === 0) return false;

    // Inspired by: https://github.com/ProseMirror/prosemirror-dropcursor/blob/master/src/dropcursor.ts#L119
    const pos = view.posAtCoords({ left: event.clientX, top: event.clientY });

    // Stop the default handling since it results in an image being open in the same browser tab
    if (!pos) return true;

    const uploadAllDroppedFiles = async () => {
      for (const file of imageFiles) {
        await handleRawImageUploadPaste(
          authenticatedServerFetch,
          setMessage,
          file,
          view,
          pos.pos,
        );
      }
    };
    uploadAllDroppedFiles();
    return true;
  };

export const handlePaste =
  (
    setMessage: (mes: Message) => void,
    authenticatedServerFetch: AuthenticatedServerFetch,
  ) =>
  (view: EditorView, pasteEvent: ClipboardEvent, slice: Slice): boolean => {
    const pressingShift = (view as any).shiftKey; // undocumented api: see https://git.io/JtfDA
    if (!pasteEvent.clipboardData || pressingShift) return false; // some older browsers have a broken paste API.

    // intercept the paste handler to check if we need to collapse expanded notes (ENT-809)
    const { $from } = view.state.selection;
    const parentNode = $from.parent;
    if (parentNode.type === schema.nodes.paragraph) {
      // check each node in the enclosing paragraph and expand any spaceships
      // with an "isExpanded" property set to true
      parentNode.forEach((inlineNode, _, index) => {
        if (
          inlineNode.type === schema.nodes.reference &&
          inlineNode.attrs.isExpanded === true
        ) {
          const position = $from.posAtIndex(index);
          toggleIsExpandedAttributeOnSpaceship(position, inlineNode)(
            view.state,
            view.dispatch,
          );
        }
      });

      // Compute positions
      upsertNewNotes($from, slice);
    }

    if (!isUploadEnabled) return false;
    if (!urlUpload) {
      console.warn(
        "You need to configure an upload endpoint to be able to upload files",
      );
      return false;
    }

    // every paste event will have n clipboardData items
    // for each clipboardData we have a different meme type  for each event
    // we want to have different rules for each item
    // the reason we want to look through them all, is that when uploading
    // files, we get the filename as one item and the image itself as another
    // this is the same with HTML entries
    const imageItems = Array.from(pasteEvent.clipboardData.items).filter(
      (item) => item.type.includes("image"),
    );
    // Upload and insert the images one by one sequentially.
    // This way we preserve their order
    (async () => {
      // The files must be obtained before the paste event handler returns
      // If the item.getAsFile() is called later, the file is empty
      const files = imageItems.map((item) => item.getAsFile());
      for (const file of files) {
        await handleRawImageUploadPaste(
          authenticatedServerFetch,
          setMessage,
          file,
          view,
        );
      }
    })();
    // Prevent CodeMirror from automatically handling the paste if there are any images that are being uploaded
    return imageItems.length > 0;
  };

async function readFileAsDataUri(file: File) {
  return new Promise<string>((res, rej) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = async () => {
      const placeholderSrc = reader.result;

      // if not a string, there is an error
      if (!(typeof placeholderSrc === "string")) {
        rej(new Error("dataUri cannot be generated"));
        return;
      }

      res(placeholderSrc);
    };
    reader.onerror = (error) => {
      // error converting to base64
      console.error("Error: ", error);
      rej(error);
    };
  });
}

async function handleRawImageUploadPaste(
  authenticatedServerFetch: AuthenticatedServerFetch,
  setMessage: (mes: Message) => void,
  file: File | null,
  view: EditorView,
  insertPosition?: number,
): Promise<boolean> {
  if (!file) {
    // maybe put a flash message?
    console.warn("empty file");
    setMessage({ type: "error", content: "Cannot process an empty file" });
    return false;
  }
  const extension = file.name.split(".").pop();
  const filename = nanoid() + "." + extension;

  // use base64 img rep as placeholder
  let placeholderSrc: string;
  try {
    placeholderSrc = await readFileAsDataUri(file);
  } catch (err) {
    setMessage({
      type: "error",
      content: "We're having a problem processing this image.",
    });
    throw err;
  }

  const { naturalWidth, naturalHeight, smallPreviewDataURL } =
    await measureNaturalSizeAndGeneratePreview(placeholderSrc);

  const imageNode = view.state.schema.nodes.image.create({
    // When creating the image node, we need to set the img src
    //   to the correct image location optimistically (instead of setting the src
    //   as the base64 placeholder and then resetting the src post-upload)
    // We do this even though we know it will cause a 404 right away
    //   b/c our src setting is done outside of Prosemirror, and
    //   on undo and redo we don't want PM to give us back the placeholder
    src: urlUpload + "/images/" + filename,
    width: null,
    naturalWidth,
    naturalHeight,
    id: filename,
    placeholder: placeholderSrc,
    smallPreviewDataURL,
  });

  const tr = view.state.tr;
  if (insertPosition) {
    tr.setSelection(new TextSelection(tr.doc.resolve(insertPosition)));
  }
  tr.replaceSelectionWith(imageNode, false);
  view.dispatch(
    tr.scrollIntoView().setMeta("paste", true).setMeta("uiEvent", "paste"),
  );
  setPlaceholder(filename, placeholderSrc as string); // this typecast is safe
  uploadFile(authenticatedServerFetch, setMessage)(file, filename, view);

  return true;
}

function upsertNewNotes($from: ResolvedPos, slice: Slice) {
  const note = $from.node(1); // note
  const { position, insertedAt } = noteList.get(note.attrs.noteId)!;
  const notesToBePositioned: NoteId[] = [];
  slice.content.descendants((node) => {
    if (node.type === schema.nodes.note) {
      notesToBePositioned.push(node.attrs.noteId);
    }
  });
  const posInserted = notePositions.generateAfter(
    position,
    notesToBePositioned.length,
  );
  noteList.insert(
    notesToBePositioned.map((id, idx) => {
      trackEvent(["NOTE", "CREATED", id]);
      return {
        id,
        position: posInserted[idx],
        tokens: [],
        insertedAt,
      };
    }),
  );
}

function measureNaturalSizeAndGeneratePreview(imgUrl: string) {
  const img = document.createElement("img");
  return new Promise<{
    naturalWidth: number;
    naturalHeight: number;
    smallPreviewDataURL: string | null;
  }>((res, rej) => {
    img.onload = () =>
      res({
        naturalWidth: img.naturalWidth,
        naturalHeight: img.naturalHeight,
        smallPreviewDataURL: generatePreview(img),
      });
    img.onerror = () => rej("Couldn't load the image");
    img.src = imgUrl;
  });
}

export function generatePreview(img: HTMLImageElement) {
  // Limit the resolution of the preview to fit in the 64x32
  const scale = Math.min(64 / img.naturalWidth, 32 / img.naturalHeight);
  const canvas = document.createElement("canvas");
  canvas.width = img.naturalWidth * scale;
  canvas.height = img.naturalHeight * scale;
  const ctx = canvas.getContext("2d");
  ctx?.drawImage(
    img,
    0,
    0,
    img.naturalWidth * scale,
    img.naturalHeight * scale,
  );
  try {
    return canvas.toDataURL("image/jpeg");
  } catch (err) {
    // It's possible the image server did not send the "Access-Control-Allow-Origin"
    // and the canvas is considered tainted after drawing the image to it.
    // In those situations we can't generate the preview on the client side
    console.warn(err);
    return null;
  }
}

const setPlaceholder = (filename: string, placeholder: string) => {
  // set a shaded version of the image as the placeholder while waiting on upload
  //   - don't do this operation in a PM transaction bc we want correct undo redo stacks
  const imageToRefresh: HTMLImageElement | null = document.querySelector(
    `img[id='${filename}']`,
  );
  if (!imageToRefresh) {
    console.warn("could not find the image to refresh");
  } else {
    // set shaded placeholder
    imageToRefresh!.src = placeholder;
    imageToRefresh!.style.opacity = "0.2";
    imageToRefresh!.id = filename;
  }
};

const uploadFile =
  (
    authenticatedServerFetch: AuthenticatedServerFetch,
    setMessage: (mes: Message) => void,
  ) =>
  async (file: File, filename: string, view: EditorView) => {
    if (!window.navigator.onLine) {
      setMessage({
        type: "ok",
        content: `We're don't support yet image uploads while being offline`,
      });
      return;
    }

    try {
      const fileDataUri = await readFileAsDataUri(file);
      const response = await authenticatedServerFetch(
        "mutation UploadFile($dataUri: String!, $filename: String!) { uploadImageFile(dataUri: $dataUri, filename: $filename) }",
        { dataUri: fileDataUri, filename },
      );
      if (!response.ok) {
        throw new Error("Unknown error");
      }
      const json = await response.json();
      if ("errors" in json) {
        throw new Error(json.errors[0].message);
      }

      // update image src if successful
      const imageToRefresh: HTMLImageElement | null = document.querySelector(
        `img[id='${filename}']`,
      );
      if (!imageToRefresh) {
        // that could happen if the image is uploaded before the DOM. I don't know how to resolve this properly, as dispatch has no callback
        console.warn("could not find the image to refresh");
        return;
      } else {
        // replace shaded placeholder with image served from upload server
        imageToRefresh!.src = urlUpload + "/images/" + filename;
        imageToRefresh!.style.opacity = "1";
      }
    } catch (error: unknown) {
      // on upload failure, search for image w/placeholder by id and remove
      view.state.tr.doc.descendants((node, pos) => {
        if (node.type === schema.nodes.image && node.attrs.id === filename) {
          const tr = view.state.tr.replace(pos, pos + node.nodeSize);
          view.dispatch(tr);
          setMessage({
            type: "error",
            content: `We're having a problem processing this image. ${getErrorMessage(
              error,
            )}`,
          });
        }
      });
    }
  };

function getErrorMessage(error: unknown) {
  if (error instanceof Error) return error.message;
  if (!error) return "";
  return String(error);
}
