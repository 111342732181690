import { Command } from "prosemirror-state";
import { schema } from "../../schema";
import { findParent } from "../../utils/find";

export const expandEllipsisCommand: Command = (state, dispatch): boolean => {
  if (!dispatch) return false;
  const tr = state.tr;
  const { $from, $to } = state.selection;
  if ($from.pos !== $to.pos) return false;
  // Get note containing ellipsis
  const [node, pos] = findParent(
    tr.doc,
    $from.pos,
    (node) => node.type === schema.nodes.note,
  );
  if (!node || node.attrs.isExpanded) {
    return false;
  }
  // Expand ellipsis
  tr.setNodeMarkup(pos, null, {
    ...node?.attrs,
    isExpanded: true,
  });
  dispatch(tr);
  return true;
};

export const collapseEllipsisCommand: Command = (state, dispatch): boolean => {
  if (!dispatch) return false;
  const tr = state.tr;
  const { $from, $to } = state.selection;
  if ($from.pos !== $to.pos) return false;
  // Get note containing ellipsis
  const [node, pos] = findParent(
    tr.doc,
    $from.pos,
    (node) => node.type === schema.nodes.note,
  );
  if (pos !== null && node?.attrs?.isExpanded === true) {
    // Expand ellipsis
    tr.setNodeMarkup(pos, null, {
      ...node?.attrs,
      isExpanded: false,
    });
    dispatch(tr);
    return true;
  }
  return false;
};
