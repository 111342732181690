import { Transaction } from "prosemirror-state";
import { Node } from "prosemirror-model";
import { regexpMatchers } from "./regexpMatchers";
import {
  getMatchesForEachRegex,
  getTextRunsForNode,
  TextRun,
} from "./regexMarkUtils";
import { schema } from "../../schema";
import { equalMarks } from "./equalMarks";
import { descendNotes } from "../descendNotes";

export const MarkSpecs = [
  {
    type: "hashtag" as const,
    regex: regexpMatchers.hashtag,
    skipFirstGroup: true,
  },
  {
    type: "highConfidenceLink" as const,
    regex: regexpMatchers.highConfidenceLink,
    skipFirstGroup: false,
  },
  {
    type: "lowConfidenceLink" as const,
    regex: regexpMatchers.lowConfidenceLink,
    skipFirstGroup: true,
  },
];

/** There are a few Marks (e.g. hashtags and URL links) that are not user-set,
 * and are instead defined only as a pure function of syntax. Every transaction,
 * applyMarks runs to (re)apply these Marks in the correct places.
 */
export function applyMarks(
  tr: Transaction,
  nodesThatChangedOrAdded: Node[],
): void {
  descendNotes(tr.doc, (noteNode, pos) => {
    if (!nodesThatChangedOrAdded.includes(noteNode)) return;
    const textRuns = getTextRunsForNode(noteNode, pos);
    textRuns.forEach((textRun) => applyMarksInText(tr, textRun));
  });
}

function applyMarksInText(transaction: Transaction, textRun: TextRun): void {
  const startPos = textRun.startPos;
  const text = textRun.textNodes.map((n) => n.text!).join("");
  const matches = getMatchesForEachRegex(text, MarkSpecs);
  if (equalMarks(textRun, matches)) return;
  transaction
    .removeMark(startPos, startPos + text.length, schema.marks.link)
    .removeMark(startPos, startPos + text.length, schema.marks.hashtag);

  matches.forEach(({ start, end, type }) => {
    const mark =
      type === "hashtag"
        ? schema.marks.hashtag.create()
        : schema.marks.link.create({ content: text.slice(start, end) });
    if (
      !transaction.doc.rangeHasMark(
        startPos + start,
        startPos + end,
        schema.marks.autocompleteRegion,
      )
    ) {
      // start and end position are relative to the beginning of the text node.
      transaction.addMark(startPos + start, startPos + end, mark);
    }
  });
}
