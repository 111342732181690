import { FolderId, ParagraphToken, CodeblockToken } from "../model/types";
import { NoteList } from "../model/noteList";
import { Note, NoteId, TokenId } from "../model/types";
import { SearchQuery } from "./SearchQuery";
import { findInArray, Hit, HitWithMatch } from "./find";
import { isIOs } from "../utils/environment";
import { assertUnreachable } from "../utils/assertUnreachable";
import { formatInsertedAt } from "../editorPage/utils/insertedAt";
import { SortBy } from "../search/SearchQuery";
import { relevance, createQueryMatchers } from "./nlp";
import { FlatIndex } from "../model/ram/FlatIndex";
import { mapBlockTokens } from "../utils/tokenIterators/mapBlockTokens";
import { FolderList } from "../model/folderList";
import { assert } from "../utils/assert";
import { BlockText } from "../model/noteFormatter";

export const DEFAULT_PARAGRAPH_LIMIT = isIOs ? 100 : 300;

// How many hidden notes should be shown when the user taps on the "Load more notes" button
export const PARAGRAPH_LIMIT_INCREMENT = 300;

export class Searcher {
  constructor(
    private noteList: NoteList,
    private folderList: FolderList,
    private textIndex: FlatIndex<string, BlockText[]>,
  ) {}

  searchNotes(searchQuery: SearchQuery, paragraphLimit?: number) {
    if (paragraphLimit === undefined) {
      paragraphLimit = searchQuery.limit ?? DEFAULT_PARAGRAPH_LIMIT;
    }
    return sortAndPrepareForDisplay(
      this.searchNotesWithoutLimit(searchQuery),
      paragraphLimit,
      searchQuery,
    );
  }

  //@todoellipsis change type to NoteWithEllipsis
  // to add highlight + ellipsis
  private searchNotesWithoutLimit(searchQuery: SearchQuery): Hit<Note>[] {
    switch (searchQuery.type) {
      case "text":
        return this.searchByText(searchQuery.q);
      case "note":
        return this.searchByNoteId(searchQuery.q, searchQuery.asPage);
      case "hashtag":
        return this.searchForBlock((b) =>
          b.content.some(
            (t) =>
              t.type === "hashtag" &&
              t.content.toLowerCase() === searchQuery.q.toLowerCase(),
          ),
        );
      case "is": {
        switch (searchQuery.q) {
          case "public":
            return this.noteList
              .getAll()
              .filter((n) => n.readAll)
              .map((note) => ({ entry: note }));
          case "todo":
            return this.searchForBlock((b) =>
              b.content.some((t) => t.type === "checkbox"),
            );
          case "incomplete":
            return this.searchForBlock((b) =>
              b.content.some((t) => t.type === "checkbox" && !t.isChecked),
            );
          case "complete":
            return this.searchForBlock((b) =>
              b.content.some((t) => t.type === "checkbox" && t.isChecked),
            );
          default: {
            assertUnreachable(searchQuery);
            throw new Error(`invalid search type'`);
          }
        }
      }
      case "*":
        return this.searchAll().map((n) => ({ entry: n, match: [] }));
      case "date":
        return this.searchByDate(searchQuery.q);
      case "folder":
        return this.searchByFolder(
          searchQuery.q,
          searchQuery.isIncludingSubFolders,
        );
      default:
        throw assertUnreachable(searchQuery);
    }
  }

  private searchAll(): Note[] {
    return this.noteList.getAll();
  }

  private searchByFolder(
    folderId: FolderId,
    isIncludingSubFolders: boolean,
  ): Hit<Note>[] {
    assert(folderId !== null, "folder id must be set");
    // maybe this shold be a feature we can toggle?
    const ids = isIncludingSubFolders
      ? this.folderList.getSubFoldersId(folderId)
      : [folderId];
    console.log(ids);
    return this.noteList
      .getAll()
      .map((n) => ({ entry: n }))
      .filter((r) => ids.includes(r.entry.folderId!));
  }

  private searchByNoteId(noteId: NoteId, asPage: boolean): Hit<Note>[] {
    if (!this.noteList.has(noteId)) {
      if (asPage) return [];
      return this.searchAll().map((n) => ({ entry: n }));
    }

    const note = this.noteList.get(noteId);
    if (!note || note.deletedAt) throw new Error("This note doesnt exist");
    if (asPage) {
      return [{ entry: note }];
    }

    const notes = this.noteList.getAll().map((n) => ({ entry: n }));
    const results = sortByPosition(notes);
    const noteIndex = results.findIndex((n) => n.entry.id === noteId);

    // filter around
    const windowSize = 100; // keep it even
    const lowerBound = Math.max(0, noteIndex - windowSize / 2);
    const higherBound = noteIndex + windowSize / 2;
    return results.filter((_, i) => i >= lowerBound && i < higherBound);
  }

  private searchByText(search: string): HitWithMatch<Note>[] {
    return findInArray(this.noteList.getAll(), this.textIndex, search);
  }

  private searchForBlock(
    condition: (block: ParagraphToken | CodeblockToken) => boolean,
  ): HitWithMatch<Note>[] {
    return this.noteList
      .getAll()
      .map((note: Note) => {
        const matches: TokenId[] = [];
        note.tokens.forEach(
          mapBlockTokens((blockToken) => {
            if (condition(blockToken)) {
              matches.push(blockToken.tokenId);
            }
          }),
        );
        return { note, matches };
      })
      .filter(({ matches }) => matches.length > 0)
      .map(({ note, matches }) => ({
        entry: note,
        matches,
        asText: this.textIndex.get(note.id) ?? [],
      }));
  }

  private searchByDate(date: Date): Hit<Note>[] {
    const matchingInsertedAt = formatInsertedAt(date);
    return this.noteList
      .getAll()
      .filter((note) => {
        return note.insertedAt === matchingInsertedAt;
      })
      .map((n) => ({ entry: n, match: [] }));
  }
}

const sortAndPrepareForDisplay = (
  results: Hit<Note>[],
  paragraphLimit: number,
  searchQuery: SearchQuery,
) => {
  let numberSoFar = 0;

  let sortedResults = results.filter((r) => Boolean(r.entry));
  if (searchQuery.type === "text") {
    switch (searchQuery.sortBy) {
      case SortBy.relevance:
        sortedResults = sortByRelevance(sortedResults, searchQuery.q);
        break;
      case SortBy.lastUpdated:
        sortedResults = sortByLastUpdate(sortedResults);
        break;
      case SortBy.position:
        sortedResults = sortByPosition(sortedResults);
    }
  } else {
    sortedResults = sortByPosition(sortedResults);
  }

  const totalNumberOfNotes = sortedResults.length;

  const takenNotes: Hit<Note>[] = [];
  while (sortedResults.length > 0 && numberSoFar < paragraphLimit) {
    const result = sortedResults.shift()!;
    numberSoFar += result.entry.tokens.length;
    takenNotes.push(result);
  }

  return {
    notes: takenNotes,
    skippedNotesCount: totalNumberOfNotes - takenNotes.length,
  };
};

/**
 * Notes are sorted by their position field in an increasing lexicographic order
 *
 * So we first sort all the notes that have a position field and then insert the
 */
function sortByPosition(result: Hit<Note>[]) {
  return result.sort((a: Hit<Note>, b: Hit<Note>) => {
    const positionA = a.entry.position;
    const positionB = b.entry.position;
    if (positionA < positionB) return -1;
    if (positionA > positionB) return 1;
    // when positions are equal, we make the order stable by ordering by createdAt
    if (a.entry.createdAt < b.entry.createdAt) return -1;
    if (a.entry.createdAt > b.entry.createdAt) return 1;
    return 0;
  });
}

function sortByLastUpdate(result: Hit<Note>[]) {
  return result.sort((a: Hit<Note>, b: Hit<Note>) => {
    if (a.entry.updatedAt > b.entry.updatedAt) return -1;
    if (a.entry.updatedAt < b.entry.updatedAt) return 1;
    return 0;
  });
}

export function sortByRelevance(result: Hit<Note>[], queryString: string) {
  const queryMatchers = createQueryMatchers(queryString);

  result.forEach((hit) => {
    const text = (hit.asText ?? []).map((h) => h.text);
    hit.relevance = relevance(text, queryMatchers);
  });
  return result.sort((a: Hit<Note>, b: Hit<Note>) => {
    const relA = a.relevance!;
    const relB = b.relevance!;
    // sort by relevance
    if (relA < relB) return 1;
    if (relA > relB) return -1;
    // then by updatedAt
    if (a.entry.updatedAt < b.entry.updatedAt) return 1;
    if (a.entry.updatedAt > b.entry.updatedAt) return -1;
    return 0;
  });
}
