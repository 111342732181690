import { Plugin, PluginKey } from "prosemirror-state";
import { schema } from "../../schema";
import { Step } from "prosemirror-transform";
import { Node } from "prosemirror-model";

function isStepCrossingExpansionNode(doc: Node, step: Step) {
  const map = step.getMap();
  let isCrossing = false;
  map.forEach((start, end, newStart, newEnd) => {
    if (isCrossing) return;

    // if the user tries to delete all the content of an expanded note
    // don't allow them to since prosemirror will delete the note itself
    // and we don't handle that case yet TODO: ENT-983
    if (
      doc.nodeAt(start)?.type === schema.nodes.note &&
      doc.resolve(start).parent.type === schema.nodes.expandedReference &&
      map.mapResult(start).deleted &&
      map.mapResult(end - 1).deleted
    ) {
      console.warn("Preventing deletion of the entire content of the expanded");
      isCrossing = true;
    }
    doc.nodesBetween(start, end, (node, pos) => {
      // if the user tries to delete across expanded note boundaries
      // don't allow them since this is undefined behavior with no "good"
      // solution

      // Don't process nodes that contain the entire start to end range within themselves
      // Edits made within those nodes cannot be considered as "crossing the boundary"
      // This fixes the bug: https://linear.app/ideaflow/issue/ENT-1063
      // since we no longer cancel the transaction for the parent expanded notes
      // when a child expanded note is edited
      const isParentExpandedNote = pos < start && pos + node.nodeSize - 1 > end;
      if (isParentExpandedNote) return;

      if (node.type === schema.nodes.expandedReference) {
        // ReplaceAroundStep can keep some content from inside of the node
        // which we must NOT allow for the expandedNotes (see: https://linear.app/ideaflow/issue/ENT-1425)
        // So we cannot check `deletedEnd` via `map.mapResult` since a ReplaceAroundStep
        // may delete only the start and end of the node (ReplaceAroundStep has two mapping steps)
        const deletedStart = map.mapResult(pos).deleted;
        const deletedEnd = pos + node.nodeSize - 1 < end;

        if (
          // XOR here since we don't want the user being able to delete
          // on the boundary of the note. We do want to allow another plugin
          // to deletes the full expanded note
          (deletedStart || deletedEnd) &&
          !(deletedStart && deletedEnd)
        ) {
          console.warn("Preventing deletion across note expansion boundary");
          isCrossing = true;
        }
      }
    });
  });

  return isCrossing;
}

/** Plugin that prevents user selections from deleting across expanded notes */
export const preventDeletionAcrossExpansionPlugin = new Plugin({
  key: new PluginKey("preventDeletionAcrossExpansion"),
  filterTransaction(tr) {
    if (tr.getMeta("noChangeToModel")) return true;

    let filterTransaction = false;

    tr.steps.forEach((step, i) => {
      const docBefore = tr.docs[i];
      if (filterTransaction) return;
      filterTransaction = isStepCrossingExpansionNode(docBefore, step);
    });

    return !filterTransaction;
  },
});
