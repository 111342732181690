import React from "react";
import * as ReactDOM from "react-dom/client";

import { EditorView, NodeView } from "prosemirror-view";
import { Node } from "prosemirror-model";
import { Spinner } from "../../../utils/Spinner";
import { schema } from "../../schema";
import { AuthenticatedServerFetch } from "../../../auth/AccessTokenManager";

export class LinkLoaderView implements NodeView {
  dom: HTMLSpanElement;

  constructor(
    private node: Node,
    private view: EditorView,
    private getPos: () => number,
    private authenticatedServerFetch: AuthenticatedServerFetch,
  ) {
    this.dom = document.createElement("span");
    this.dom.classList.add("linkLoader");

    this.updateContent();

    if (node.attrs.title === null) {
      this.unfurlTheLink();
    }
  }

  destroy() {}

  private updateContent() {
    ReactDOM.createRoot(this.dom).render(
      <Spinner
        style={{
          display: "inline-flex",
          height: "1.0em",
          width: "1.0em",
          margin: 0,
          verticalAlign: "middle",
        }}
        svgStyle={{ width: "100%", height: "100%", transform: "scale(1.5)" }}
        distanceFromCenter={35}
        numberOfPoints={6}
      />,
    );
  }

  private async unfurlTheLink() {
    // Get the metadata
    let response: Response;
    try {
      response = await this.authenticatedServerFetch(
        `query Unfurl($url: String!) { unfurlLink(url: $url) { title } }`,
        { url: this.node.attrs.url },
      );
    } catch (err) {
      this.replaceSelfWith([]);
      console.error(err);
      return;
    }
    if (!response.ok) {
      this.replaceSelfWith([]);
      console.error(await response.text());
      return;
    }
    const json = await response.json();
    if ("errors" in json) {
      this.replaceSelfWith([]);
      return;
    }
    const { title } = json.data.unfurlLink;

    // If the title is not available in the OpenGraph data, remove the loader without adding anything
    if (typeof title !== "string") {
      this.replaceSelfWith([]);
    } else {
      this.replaceSelfWith([
        schema.text(title, [schema.marks.italic.create()]),
        schema.text(" — ", []),
      ]);
    }
  }

  private replaceSelfWith(nodes: Node[]) {
    const pos = this.getPos();
    if (!pos) return;
    const transaction = this.view.state.tr.replaceWith(pos, pos + 1, nodes);
    this.view.dispatch(transaction);
  }

  update(newNode: Node) {
    if (newNode.type !== this.node.type) return false;
    this.node = newNode;

    this.updateContent();
    return true;
  }

  selectNode() {
    this.dom.classList.add("ProseMirror-selectednode");
  }

  deselectNode() {
    this.dom.classList.remove("ProseMirror-selectednode");
  }
}
