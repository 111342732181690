import { EditorState, Transaction, Command } from "prosemirror-state";
import { InputRule, inputRules, undoInputRule } from "prosemirror-inputrules";
import { Fragment, Node, NodeRange, NodeType, Slice } from "prosemirror-model";
import {
  findWrapping,
  ReplaceAroundStep,
  canSplit,
  canJoin,
} from "prosemirror-transform";
import { schema } from "../../schema";
import { preserveExpandedSpaceshipsAround } from "../reference/referenceExpansionUtils";

const listCreateOrWrap = (state: EditorState, createdFromLineStart = true) => {
  const { $from, $to } = state.selection;
  let range = $from.blockRange($to),
    doJoin = false,
    outerRange = range;
  if (!range) return null;
  if (range.depth >= 2 && range.startIndex === 0) {
    // Don't do anything if this is the top of the list
    if ($from.index(range.depth - 1) === 0) return null;
    const $insert = state.doc.resolve(range.start - 2);
    outerRange = new NodeRange($insert, $insert, range.depth);
    if (range.endIndex < range.parent.childCount)
      range = new NodeRange(
        $from,
        state.doc.resolve($to.end(range.depth)),
        range.depth,
      );
    doJoin = true;
  }
  const wrap = findWrapping(
    outerRange as NodeRange,
    schema.nodes.bulletList,
    undefined,
    range,
  );
  if (!wrap) return null;

  const tr = state.tr;

  preserveExpandedSpaceshipsAround(state, tr, () => {
    // wrap the content
    doWrapInList(tr, range!, wrap, doJoin, schema.nodes.bulletList);

    // remove the bullet/spaces at the start of the line
    if (createdFromLineStart) {
      tr.delete(tr.selection.$from.start(), tr.selection.$from.pos);
    }

    // if the new bulleted list directly borders another bulleted list at the same depth, merge them
    joinAdjacentLists(tr);
  });

  return tr;
};

function joinAdjacentLists(tr: Transaction): Transaction {
  if (!tr.isGeneric) return tr;

  const isJoinable = (before: Node, after: Node) => {
    return (
      before.type === schema.nodes.bulletList &&
      after.type === schema.nodes.bulletList
    );
  };

  const ranges: any = [];
  for (let i = 0; i < tr.mapping.maps.length; i++) {
    const map = tr.mapping.maps[i];
    for (let j = 0; j < ranges.length; j++) ranges[j] = map.map(ranges[j]);
    map.forEach((_s, _e, from, to) => ranges.push(from, to));
  }

  // Figure out which joinable points exist inside those ranges,
  // by checking all node boundaries in their parent nodes.
  const joinable = [];
  for (let i = 0; i < ranges.length; i += 2) {
    const from = ranges[i],
      to = ranges[i + 1];
    const $from = tr.doc.resolve(from),
      depth = $from.sharedDepth(to),
      parent = $from.node(depth);
    for (
      let index = $from.indexAfter(depth), pos = $from.after(depth + 1);
      pos <= to;
      ++index
    ) {
      const after = parent.maybeChild(index);
      if (!after) break;
      if (index && joinable.indexOf(pos) === -1) {
        const before = parent.child(index - 1);
        if (before.type === after.type && isJoinable(before, after))
          joinable.push(pos);
      }
      pos += after.nodeSize;
    }
  }
  // Join the joinable points
  joinable.sort((a, b) => a - b);
  for (let i = joinable.length - 1; i >= 0; i--) {
    if (canJoin(tr.doc, joinable[i])) tr.join(joinable[i]);
  }
  return tr;
}

function doWrapInList(
  tr: Transaction,
  range: NodeRange,
  wrappers: {
    type: NodeType;
    attrs?:
      | {
          [key: string]: any;
        }
      | null
      | undefined;
  }[],
  joinBefore: boolean,
  listType: NodeType,
) {
  let content = Fragment.empty;
  for (let i = wrappers.length - 1; i >= 0; i--)
    content = Fragment.from(
      wrappers[i].type.create(wrappers[i].attrs, content),
    );

  tr.step(
    new ReplaceAroundStep(
      range.start - (joinBefore ? 2 : 0),
      range.end,
      range.start,
      range.end,
      new Slice(content, 0, 0),
      wrappers.length,
      true,
    ),
  );

  let found = 0;
  for (let i = 0; i < wrappers.length; i++)
    if (wrappers[i].type === listType) found = i + 1;
  const splitDepth = wrappers.length - found;

  let splitPos = range.start + wrappers.length - (joinBefore ? 2 : 0);
  const parent = range.parent;
  for (
    let i = range.startIndex, e = range.endIndex, first = true;
    i < e;
    i++, first = false
  ) {
    if (!first && canSplit(tr.doc, splitPos, splitDepth)) {
      tr.split(splitPos, splitDepth);
      splitPos += 2 * splitDepth;
    }
    splitPos += parent.child(i).nodeSize;
  }
}

const createNestedListPlugin = inputRules({
  rules: [
    new InputRule(/^ ?-([\w\s])$/, (state, match) => {
      const tr = listCreateOrWrap(state, true);
      if (tr && match[1] !== " ") {
        tr.insertText(match[1]);
      }
      return tr;
    }),
  ],
});

/**
 * Undo the input rule that created a nested list.
 *
 * How it works: When an input rules dispatches a transaction, it saves some
 * metadata on the transaction. Then the plugin's apply method sets it's own
 * state to the metadata on the transaction or null if there is none. So if the
 * state of the {@link createNestedListPlugin} is truthy, we know that the last
 * transaction was dispatched by the input rule, and only in that case do we
 * want to undo.
 *
 * References:
 * - input plugin saves metadata on the transaction
 *   [here](https://github.com/ProseMirror/prosemirror-inputrules/blob/master/src/inputrules.ts#L99).
 * - input plugin sets it's state to the metadata
 *   [here](https://github.com/ProseMirror/prosemirror-inputrules/blob/master/src/inputrules.ts#L64-L65).
 */
export const undoCreateNestedListCommand: Command = (state, dispatch, view) => {
  if (createNestedListPlugin.getState(state)) {
    return undoInputRule(state, dispatch, view);
  }
  return false;
};

export { createNestedListPlugin, listCreateOrWrap };
