import { Plugin } from "prosemirror-state";
import { SearchQuery } from "../../../search/SearchQuery";
import { condenseAndHighlight } from "./applySearchMatchMarks";

/** Apply search highlights */
export const highlightPlugin = (searchQuery: SearchQuery) =>
  new Plugin({
    appendTransaction: (_trs, _oldState, newState) => {
      const tr = newState.tr;
      tr.setMeta("type", "highlight");
      tr.setMeta("addToHistory", false);
      tr.setMeta("noChangeToModel", true);
      condenseAndHighlight(tr, searchQuery);

      if (tr.docChanged) return tr;

      return null;
    },
  });
