// for user facing messages, check the errorToUser function.
export enum SyncErrors {
  None = "EE000: no error", // nothing
  ApiReadNoteBadFormat = "EE100: The notes from the server aren't properly formatted.", // wait for next sync
  ApiReadServerSideFatal = "EE101: An server side error occured while retrieving the delta: ", // wait for next sync
  ApiReadEmpty = "EE102: Delta response isn't properly formatted.", // wait for next sync
  ApiReadUnhandled = "EE103: An unexpected error occured while deta syncing: ", // wait for next sync
  CliObsoleteRev = "EE104: The cli revision number is obsolete.", // the cli has a conflict
  CliEqualRev = "EE105: The cli must send a new revision number at each upsert.", // fatal
  CliMissingField = "EE106: all cli updates must contain id, masterRev and localRev.", // fatal
  ApiMissingRev = "EE107: server response didn't include the rev",
  SwInvalidUpsert = "EE112: cannot upsert element because it doesnt follow the model.", // bust cache, refresh code app
  ApiWebhookUnresponsive = "EE107: Update API is unresponsive, try <code>docker compose up -d custom-api</code> or \n <code>cd server && yarn start</code>.", // wait for next sync
  ApiSaveRunningOnWrongAddress = "EE108: Api returned non-valid JSON. If you run the custom api in docker, try setting <code>HASURA_ACTION_BASE_URL=http://host.docker.internal:8081</code>.", // wait for next sync
  ApiJWTIssuedAtFuture = "EE109: Hasura thinks the JWT has been issued at a future date, it is usually caused by a drifting clock in Docker, try <code>docker compose up -d graphql-engine</code>.", // wait for next sync
  ApiReadServerUnreachable = "EE110: Couldn't fetch the delta. Server unreachable.",
  ApiOffline = "EE111: Api Offline.", // wait for next sync
  ApiSaveUnhandled = "EE113: An unhandled error occured while pushing updates to the api: ", // wait for next sync
  ApiJWTExpired = "EE114: JWT Expired.", // reset auth
  ApiNoJWT = "EE115: No JWT.", // reset auth
  CacheNotSaved = "EE116: Couldn't write in cache.", // data loss fatal
  ApiDataInvalid = "EE117: Couldn't load data from api, format invalid", // fatal
  CacheDataInvalid = "EE118: Couldn't load data from cache, format invalid", // bust cache (potential dataloss) redownload notes
  SwRamDataInvalid = "EE119: Couldn't load data from cli update, format invalid", // fatal
  CliCreateDataInvalid = "EE119: Couldn't load data at acocunt creation, format invalid", // fatal
  ApiNotMigrated = "EE120: Api is missing important fields", // refresh code app
  ApiObsoleteRev = "EE121: The old rev provided isnt the revision currently in db",
  ApiCannotSaveSomeNotes = "EE122: cannot save some notes on the api: ",
  CliRewindHistory = "EE123: cannot set back to historical value ",
}

// all the errors that do not self correct, or needs an app refresh will be considered fatal
export const needsAuthReset = (errorCode: SyncErrors) => {
  return [
    SyncErrors.ApiJWTExpired,
    SyncErrors.ApiNoJWT,
    SyncErrors.ApiJWTIssuedAtFuture,
  ].includes(errorCode);
};

export const needsBustCache = (errorCode: SyncErrors) => {
  return [
    SyncErrors.CacheDataInvalid,
    SyncErrors.SwInvalidUpsert,
    SyncErrors.CacheNotSaved,
    SyncErrors.SwRamDataInvalid,
  ].includes(errorCode);
};

export const willSelfCorrect = (errorCode: SyncErrors) => {
  return [
    SyncErrors.CliObsoleteRev,
    SyncErrors.ApiOffline,
    SyncErrors.ApiWebhookUnresponsive,
    SyncErrors.ApiSaveRunningOnWrongAddress,
  ].includes(errorCode);
};
