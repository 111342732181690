import dynamic from "next/dynamic";
import "../wdyr";
import "normalize.css";
import "./global.css";
import "./microtip.css";
import "../model/sync/syncStatus/sync.css";
import "../modal/modal.css";
import "../sidebar/Elements/selectedLink.css";
import "../sidebar/Elements/Folders/Folders.css";
// we need to include css in the main file in nextjs
import "../editor/baseProsemirror.css";
import "../editor/note.css";
import "../editorPage/noteMenu/menu.css";
import "../editor/tokens.css";
import type { AppProps } from "next/app";
import useAnalytics from "../analytics/useAnalytics";
import {
  auth0ClientId,
  auth0Domain,
  isAuthEnabled,
  isClientSide,
  isIOs,
  isPersistenceEnabled,
  isProd,
  isServerSide,
  isTouchDevice,
} from "../utils/environment";
import React, {
  Suspense,
  FC,
  ReactElement,
  useCallback,
  useEffect,
} from "react";
import { ErrorBoundary } from "@sentry/nextjs";

import { useKeyboardStatusWatcher } from "../shortcuts/useKeyboardStatusWatcher";
import { AppState, Auth0Provider } from "@auth0/auth0-react";
import { Provider } from "jotai";
import Head from "next/head";
import { useRouter } from "next/router";
import { MainLayout } from "../layout";
import { useNotesFromFile } from "../model/useNotesFromFile";

import SharedPage from "./shared/[noteId]";
import { AccessTokenManager } from "../auth/AccessTokenManager";

if (isClientSide) {
  if (isProd) {
    const stylesArray = [
      `background-image: url('/ideaflow-bg.png')`,
      "background-size: cover",
      "color: #0000ff",
      "padding: 10px 20px",
      "font-size: 18px",
      "line-height: 35px",
      "width : 70px",
      "height : 126px",
      "border : 5px solid black",
    ].join(";");
    console.log("%c Ideaflow ❤ developers", stylesArray);
    console.log("Apply at jobs@ideaflow.io");
  }
}

function _App({ Component }: AppProps) {
  // Allow the SharedPage component to be used without login
  const availableWithoutLogin = Component === SharedPage;

  useKeyboardStatusWatcher();
  useEffect(() => {
    if (isServerSide) return;
    // https://stackoverflow.com/q/47802530/
    if (!isIOs) {
      document.body.classList.add("not-ios");
    }
    if (isTouchDevice) {
      document.body.classList.add("touch-device");
    }
  }, []);
  return (
    <>
      <Head>
        <title>Ideaflow</title>
        <link href="/favicon.ico" rel="shortcut icon" type="image/x-icon" />
        <meta charSet="utf-8" />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, maximum-scale=1"
        />
        <meta name="theme-color" content="#313131" />
        <meta
          name="description"
          content="Intelligence Amplification Ecosystem."
        />
        <meta
          httpEquiv="Content-Security-Policy"
          content="script-src 'self' 'unsafe-inline' 'unsafe-eval'"
        />
        <link rel="apple-touch-icon" href="/apple-touch-icon.png" />
        <link rel="manifest" href="/manifest.json" />
      </Head>
      <Provider>
        <ErrorBoundary fallback={<p>An error has occurred</p>}>
          <Suspense fallback={null}>
            <AuthWrapper>
              <MainLayout requireLogin={!availableWithoutLogin}>
                {isPersistenceEnabled ? (
                  <AccessTokenManager>
                    <Component />
                  </AccessTokenManager>
                ) : (
                  <StaticNoteLoader>
                    <Component />
                  </StaticNoteLoader>
                )}
              </MainLayout>
            </AuthWrapper>
          </Suspense>
        </ErrorBoundary>
      </Provider>
    </>
  );
}

export default dynamic(() => Promise.resolve(_App), {
  ssr: false,
});

const AuthWrapper: FC<{ children: ReactElement }> = ({ children }) => {
  const router = useRouter();
  useAnalytics();
  const onRedirectCallback = useCallback((appState: AppState | undefined) => {
    router.replace(appState?.returnTo || "/");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!isAuthEnabled) {
    return children;
  }

  return (
    <Auth0Provider
      domain={auth0Domain || ""}
      clientId={auth0ClientId || ""}
      redirectUri={window.location.origin}
      useRefreshTokens
      scope="openid profile email offline_access"
      onRedirectCallback={onRedirectCallback}
      cacheLocation="localstorage"
      audience="https://ideaflow.auth0.com/api/v2/"
    >
      {children}
    </Auth0Provider>
  );
};

const StaticNoteLoader = ({ children }: { children: React.ReactElement }) => {
  useNotesFromFile();
  return children;
};
