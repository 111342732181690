import { InputRule, inputRules } from "prosemirror-inputrules";
import { TextSelection } from "prosemirror-state";
import { schema } from "../../schema";
import { serializeNode } from "../../utils/serializeNode";

const rule = new InputRule(/`{3}/g, (state, match, start, end) => {
  const $end = state.doc.resolve(end);
  if ($end.parent.type === schema.nodes.codeblock) return null;

  const startOfPara = $end.start();
  const endOfPara = $end.end();
  const $endOfParagraph = state.doc.resolve(endOfPara);

  // if the paragraph is empty, just replace it with the codeblock
  // if it not, append the codeblock
  const atStartOfPara = start === startOfPara;
  const targetStart = atStartOfPara ? start - 1 : start;
  const sec = new TextSelection($end, $endOfParagraph);
  const existingText = serializeNode(sec.content());

  const tr = state.tr;
  tr.replaceRangeWith(
    targetStart,
    endOfPara,
    schema.nodes.codeblock.create(
      null,
      existingText !== "" ? [schema.text(existingText)] : [],
    ),
  ).setSelection(
    // +1 to place the cursor inside the code block, rather than at the start of
    // the code block (which is an invalid place to insert text).
    new TextSelection(tr.doc.resolve(tr.mapping.map(targetStart, -1) + 1)),
  );

  return tr;
});

export const codeblockInputPlugin = inputRules({ rules: [rule] });
