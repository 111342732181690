import { Node } from "prosemirror-model";
import { Plugin, PluginKey, Transaction } from "prosemirror-state";
import { schema } from "../../schema";
import {
  getLinkedNotePosFromSpaceshipPos,
  getSpaceshipPosFromExpandedNotePos,
} from "./referenceExpansionUtils";

function createReverseTransaction(tr: Transaction) {
  // The Prosemirror typings are wrong here, Transaction constructor doesn't take a document Node, but an object with doc property
  const reverseTr = new Transaction({ doc: tr.doc } as any);
  // https://discuss.prosemirror.net/t/whats-an-example-of-the-minimal-code-required-to-undo-a-transformation/1536/4
  for (let i = tr.steps.length - 1; i >= 0; i--) {
    reverseTr.step(tr.steps[i].invert(tr.docs[i]));
  }
  return reverseTr;
}

/**
 * Finds the prosemirror nodes that have been modified by the provided transaction
 */
function findModifiedNodes(tr: Transaction) {
  const nodes: { node: Node; originalPosition: number }[] = [];
  const stepMaps = tr.mapping.maps;
  stepMaps.forEach((map, i) => {
    const doc = tr.docs[i];
    const previousMaps = stepMaps.slice(0, i).reverse();
    function mapToInitialDoc(pos: number) {
      return previousMaps.reduce(
        (pos, stepMap) => stepMap.invert().map(pos),
        pos,
      );
    }
    map.forEach((start, end) => {
      doc.nodesBetween(start, end, (node, pos) => {
        if (pos < start || pos > end) {
          return;
        }

        if (tr.mapping.mapResult(pos).deleted) {
          const originalPosition = mapToInitialDoc(pos);
          nodes.push({ node, originalPosition });
        }
      });
    });
  });

  return nodes;
}

/**
 * This utility looks at `tr` to see if any spaceships or expandedNotes were
 * deleted. If so, it adds steps to `newTr` that will collapse the corresponding
 * spaceship or delete the corresponding expandedNote so that spaceship
 * expansion state is still self-consistent, i.e. so that there aren't any
 * expandedNotes without a spaceship, for example.
 */
function fixSpaceshipExpansionInTransaction(
  tr: Transaction,
  newTr: Transaction,
): void {
  if (tr.getMeta("type") === "expansionEditMirror") return;
  if (tr.getMeta("type") === "appendExpansion") return;
  if (tr.getMeta("type") === "autocomplete") return;
  if (tr.getMeta("type") === "toggleBacklink") return;

  const initialDoc = tr.docs[0];

  // Expanded notes in the initial document that have been modified in the transaction
  const modifiedExpandedNotes: Set<number> = new Set();
  const modifiedSpaceships: Set<number> = new Set();
  // Positions of spaceships that need to be verified if they are actually expanded or not
  const spaceshipsToVerify: {
    position: number;
    spaceship: Node;
    noteId: string;
  }[] = [];

  for (const { node, originalPosition } of findModifiedNodes(tr)) {
    if (node.type === schema.nodes.reference && node.attrs.isExpanded) {
      modifiedSpaceships.add(originalPosition);
    } else if (node.type === schema.nodes.expandedReference) {
      const originalNode = initialDoc.nodeAt(originalPosition);
      if (
        originalNode &&
        originalNode.type === schema.nodes.expandedReference
      ) {
        modifiedExpandedNotes.add(originalPosition);
      } else {
        console.warn("Original expanded note can't be found!");
      }
    }
  }
  for (const { node, originalPosition } of findModifiedNodes(
    createReverseTransaction(tr),
  )) {
    if (node.type === schema.nodes.reference && node.attrs.isExpanded) {
      spaceshipsToVerify.push({
        position: originalPosition,
        spaceship: node,
        noteId: node.attrs.linkedNoteId,
      });
    }
  }

  for (const pos of modifiedSpaceships.values()) {
    const originalNode = initialDoc.nodeAt(pos);

    // Search for the new spaceship at the same position
    const superseedingSpaceship = tr.doc.nodeAt(tr.mapping.map(pos, -1));
    if (
      superseedingSpaceship &&
      originalNode &&
      superseedingSpaceship.type === schema.nodes.reference &&
      superseedingSpaceship.attrs.tokenId === originalNode.attrs.tokenId
    ) {
      return;
    }

    const deleteStart = getLinkedNotePosFromSpaceshipPos(
      initialDoc,
      pos,
      "start",
    );

    const originalExpandedNote = initialDoc.nodeAt(deleteStart);

    // If corresponding expandedNote was not deleted, delete it
    if (
      originalExpandedNote &&
      originalExpandedNote.type === schema.nodes.expandedReference &&
      !tr.mapping.mapResult(deleteStart).deleted
    ) {
      const deleteEnd = getLinkedNotePosFromSpaceshipPos(
        initialDoc,
        pos,
        "end",
      );
      newTr.deleteRange(
        newTr.mapping.map(tr.mapping.map(deleteStart)),
        newTr.mapping.map(tr.mapping.map(deleteEnd)),
      );
    }
  }

  for (const pos of modifiedExpandedNotes.values()) {
    const originalNode = initialDoc.nodeAt(pos)!;
    // if it is a backlink reference, it has no corresponding spaceship
    if (originalNode.attrs.isBacklink) continue;
    const spaceshipPos = getSpaceshipPosFromExpandedNotePos(initialDoc, pos);
    const spaceship = initialDoc.nodeAt(spaceshipPos);
    const newSpaceshipPos = tr.mapping.mapResult(spaceshipPos);

    // Ignore spaceships that have been deleted
    if (newSpaceshipPos.deleted) {
      continue;
    }

    if (!spaceship || spaceship.type !== schema.nodes.reference) {
      console.warn("No spaceship node found at position", spaceshipPos);
      continue;
    }

    spaceshipsToVerify.push({
      position: newSpaceshipPos.pos,
      noteId: originalNode.attrs.containedNoteId,
      spaceship,
    });
  }

  for (const { position, spaceship, noteId } of spaceshipsToVerify) {
    const positionInNewDoc = newTr.mapping.map(position);
    // We search for the position of the expanded note in the newTr.doc instead of tr.doc
    // since multiple expanded notes might have been deleted at the same time
    // so `fixSpaceshipExpansionInTransaction` might have already updated some spaceships
    // to not be expanded
    const newExpandedPos = getLinkedNotePosFromSpaceshipPos(
      newTr.doc,
      positionInNewDoc,
      "start",
    );
    const superseedingExpandedNote = newTr.doc.nodeAt(newExpandedPos);
    // If the expanded note still exists after the entire transform is applied
    // don't change the spaceship node attribute isExpanded to false
    // `preserveExpandedSpaceshipsAround` can delete and reinstate
    // the expanded note in the same transaction, so expanded note
    // being deleted in one step of the transaction is not enough to know
    // if it's been truly deleted
    if (
      superseedingExpandedNote &&
      superseedingExpandedNote.type === schema.nodes.expandedReference &&
      superseedingExpandedNote.attrs.containedNoteId === noteId
    ) {
      return;
    }
    newTr.setNodeMarkup(positionInNewDoc, undefined, {
      ...spaceship.attrs,
      isExpanded: false,
    });
  }
}

/**
 * This plugin is responsible for ensuring that when user deletes expanded notes
 * or expanded spaceships, the corresponding other thing in the pair also gets
 * collapsed or deleted.
 */
export const fixDeletedExpandedNote = new Plugin({
  key: new PluginKey("expandedNoteEdits"),
  appendTransaction(trs, _, newState) {
    const newTr = newState.tr;
    for (const tr of trs) {
      fixSpaceshipExpansionInTransaction(tr, newTr);
    }

    if (newTr.docChanged) return newTr;
  },
});
