import { Positions, Position } from "./position/Positions";
import { ElementList } from "./ram/ElementList";
import { Persistable } from "./types";

/**
 * PositionGenerator derives new fractional positions from a list of nullable fractional positions.
 *
 * It is a stateless class.
 */
export class PositionGenerator<Element extends Persistable> {
  START_POS = Positions.integers.INTEGER_ZERO; // "a0"

  constructor(
    private list: ElementList<string, Element>,
    private getPos: (element: Element) => string | null,
  ) {}

  /**
   * Does not insert a new position into the array, it needs to be done manually
   */
  generateAfter(position: Position, number = 1): Position[] {
    const positionsAfter = this.getAllPositions().filter(
      (pos) => pos > position,
    );
    const next =
      positionsAfter.length === 0
        ? null
        : positionsAfter.reduce((current, pos) => {
            return pos < current ? pos : current;
          });
    return Positions.generateMultipleBetween(position, next, number);
  }

  generateFirst(number = 1) {
    const positions = this.getAllPositions();
    if (positions.length === 0) {
      return [
        this.START_POS,
        ...Positions.generateMultipleBetween(this.START_POS, null, number - 1),
      ];
    }
    // find first
    const first = positions.reduce((current, pos) => {
      return pos < current ? pos : current;
    });
    return Positions.generateMultipleBetween(null, first, number);
  }

  generateLast() {
    const positions = this.getAllPositions();
    if (positions.length === 0) {
      return this.START_POS;
    }
    const last = positions.reduce((current, pos) => {
      return pos > current ? pos : current;
    });
    return Positions.generateMultipleBetween(last, null, 1)[0];
  }

  generateBetween(
    first_position: Position,
    second_position: Position,
  ): Position {
    return Positions.generateBetween(first_position, second_position);
  }

  private getAllPositions() {
    const positions: string[] = [];
    this.list.getAll(true).forEach((element) => {
      const pos = this.getPos(element);
      if (pos) positions.push(pos);
    });
    return positions;
  }

  getAll() {
    const elements: Element[] = [];
    this.list.getAll(true).forEach((element) => {
      const pos = this.getPos(element);
      if (pos) elements.push(element);
    });
    return elements;
  }
}
