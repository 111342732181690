import { useEffect } from "react";
import { loadModel } from "./sync/loadModel";
import { getDefaultNotes } from "../service-worker/create-account/defaultNotes";
import { useUpdateEditor } from "../editorPage/atoms/editorUpdate";
import { useNotifySidebarUpdate } from "../sidebar/atoms/sidebarUpdate";
import { isLoadedAtom } from "../editorPage/atoms/isLoadedAtom";
import { useSetAtom } from "jotai";

const load = async (done: any) => {
  const d = getDefaultNotes("google-oauth2|112556675592475315585");
  loadModel(d.notes, d.folders);
  done();
};

export const useNotesFromFile = () => {
  const notifyEditorUpdate = useUpdateEditor();
  const notifySidebarUpdate = useNotifySidebarUpdate();

  const setIsLoaded = useSetAtom(isLoadedAtom);
  useEffect(() => {
    load(() => {
      notifyEditorUpdate();
      notifySidebarUpdate();
      setIsLoaded(true);
    });
  }, [notifyEditorUpdate, notifySidebarUpdate, setIsLoaded]);
};
