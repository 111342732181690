import { NodeView } from "prosemirror-view";
import { SearchQuery } from "../../../search/SearchQuery";
import { isMac } from "../../../utils/environment";

export class HashtagView implements NodeView {
  dom: HTMLSpanElement;
  contentDOM: HTMLSpanElement;
  constructor(
    setSearchQuery: (q: Partial<SearchQuery>) => void,
    isReadOnly: boolean,
  ) {
    this.dom = document.createElement("span");
    this.dom.className = `hashtag ${!isReadOnly && "clickable"}`;
    this.contentDOM = this.dom;

    if (isReadOnly) return;

    const onClick = (e: MouseEvent | TouchEvent) => {
      const escapeKeyPressed = isMac ? e.metaKey : e.ctrlKey;
      if (escapeKeyPressed) return;

      setSearchQuery({
        type: "hashtag",
        q: this.contentDOM.textContent || "",
      });
    };

    this.dom.onclick = onClick;
    this.dom.onmousedown = (e) => e.preventDefault();
  }
}
