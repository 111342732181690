import { Command } from "prosemirror-state";
import { SearchQuerySetter } from "../../../search/SearchQuery";
import { getParentNote } from "../../utils/find";

export function getOpenNoteAsPage(setSearchQuery: SearchQuerySetter): Command {
  return (state) => {
    const { $from } = state.selection;
    const [note] = getParentNote(state.doc, $from.pos);
    if (!note) return false;
    setSearchQuery({
      type: "note",
      q: note.attrs.noteId,
      asPage: true,
    });
    return true;
  };
}
