import { ResolvedPos } from "prosemirror-model";
import { Command, Selection } from "prosemirror-state";
import { schema } from "../../schema";

export const collapsePrevExpandedNote: Command = (state, dispatch, view) => {
  if (!dispatch || !view) return false;

  const { $anchor } = state.selection;
  if (
    !$anchor ||
    (view ? !view.endOfTextblock("backward", state) : $anchor.parentOffset > 0)
  ) {
    return false;
  }

  const $cut = findCutBefore($anchor);
  if (!$cut) return false;

  let before = $cut.nodeBefore;
  if (!before) return false;

  let deleteSize = before.nodeSize;

  if (before.type === schema.nodes.bulletList) {
    before = before.lastChild!.lastChild!;
    deleteSize = before.nodeSize + 2;
  }

  if (before.type !== schema.nodes.expandedReference) return false;

  const tr = state.tr.deleteRange($cut.pos - deleteSize, $cut.pos);

  const selection = Selection.findFrom(
    tr.doc.resolve(tr.mapping.map($cut.pos, 1)),
    1,
  );
  if (!selection) return false;
  tr.setSelection(selection);

  // also move cursor to start of former expanded note
  dispatch(tr);
  return true;
};

/** from https://github.com/ProseMirror/prosemirror-commands/blob/master/src/commands.js#L92-L98 */
function findCutBefore($cut: ResolvedPos) {
  if (!$cut.parent.type.spec.isolating)
    for (let i = $cut.depth - 1; i >= 0; i--) {
      if ($cut.index(i) > 0) return $cut.doc.resolve($cut.before(i + 1));
      if ($cut.node(i).type.spec.isolating) break;
    }
  return null;
}
