import { ChangeEvent, useCallback, useState } from "react";
import { useSetAtom } from "jotai";
import { useAuth } from "../auth/useAuth";
import { Modal } from "../modal/Modal";
import { modalAtom } from "../modal/modalAtom";
import { Cta } from "../share/Cta";
import { Secondary } from "../share/Secondary";
import { importFromLegacy, importFromPlainText } from "./procedures/importers";

/* eslint-disable */
export enum ImportStatus {
  NotStarted,
  Importing,
  UnsupportedVersionNumber,
  NoNewNote,
  Imported,
}

enum ImportFormat {
  IdeaflowLegacy = "legacy",
  Ideaflow = "ideaflow",
  AppleNotes = "apple",
  Roam = "roam",
  PlainText = "plainText",
}

/* eslint-enable */
export function ImportModal() {
  const setModal = useSetAtom(modalAtom);
  const { user } = useAuth();

  const [importStatus, setImportStatus] = useState(ImportStatus.NotStarted);
  const [selectedFormat, setSelectedFormat] = useState(ImportFormat.PlainText);
  const [file, setFile] = useState<any>(null);
  const [message, setMessage] = useState("");
  const [plainTextImportData, setPlainTextImportData] = useState("");

  const importFile = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    const input = event.target;
    if (!input.files) return;
    for (const file of input.files) {
      const reader = new FileReader();
      reader.onload = () => {
        // this 'text' is the content of the file
        const importData = reader.result;
        if (!importData) console.error("file cannot be read or is empty");
        setFile({ data: importData, name: file.name });
      };
      reader.readAsText(file);
    }
  }, []);
  const onDone = useCallback(
    (status: ImportStatus) => {
      if (status === ImportStatus.NoNewNote) {
        setMessage(
          `We didn't find any new note to import. You will be redirected in a few seconds.`,
        );
        setImportStatus(ImportStatus.Imported);
      } else if (status === ImportStatus.UnsupportedVersionNumber) {
        setMessage(
          "Unsupported version number. You will be redirected in a few seconds.",
        );
        setImportStatus(ImportStatus.Imported);
      } else if (status === ImportStatus.Imported) {
        setImportStatus(ImportStatus.Imported);
        setTimeout(() => setModal(""), 2000);
      } else {
        setMessage(
          "An error occured. You will be redirected in a few seconds.",
        );
        setTimeout(() => setModal(""), 2000);
      }
    },
    [setMessage, setImportStatus, setModal],
  );

  const doImport = useCallback(() => {
    if (!user) return;
    setImportStatus(ImportStatus.Importing);
    setMessage("Setting up import...");
    if (selectedFormat === ImportFormat.Ideaflow) {
      throw new Error("not supported for now");
    } else if (selectedFormat === ImportFormat.IdeaflowLegacy) {
      setTimeout(
        () =>
          importFromLegacy(
            file.name,
            JSON.parse(file.data! as string),
            user.sub!,
            setMessage,
            onDone,
          ),
        500,
      );
    } else if (selectedFormat === ImportFormat.PlainText) {
      setTimeout(
        () =>
          importFromPlainText(
            plainTextImportData,
            user.sub!,
            setMessage,
            onDone,
          ),
        500,
      );
    }
  }, [
    file,
    user,
    setMessage,
    setImportStatus,
    selectedFormat,
    onDone,
    plainTextImportData,
  ]);

  if (importStatus === ImportStatus.Importing) {
    return (
      <Modal>
        <h1>Importing...</h1>
        <span style={{ margin: "24px 0px" }}>
          We are now importing your notes. This action cannot be cancelled.
          Notes already present are not imported.
          <br />
          <br />
          <i>{message}</i>
        </span>
      </Modal>
    );
  }

  if (importStatus === ImportStatus.Imported) {
    return (
      <Modal>
        <h1>Importing...</h1>
        <span style={{ margin: "24px 0px" }}>
          All done!
          <br />
          <br />
          <i>{message}</i>
        </span>
      </Modal>
    );
  } else
    return (
      <Modal>
        <h1>Import Notes</h1>
        <span style={{ margin: "12px 0px" }}>
          Where are the notes coming from?{" "}
        </span>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <div>
            <input
              style={{ marginRight: 8 }}
              type="radio"
              id="plaintext"
              onChange={() => setSelectedFormat(ImportFormat.PlainText)}
              checked={selectedFormat === ImportFormat.PlainText}
            />
            <label htmlFor="plaintext">From plain text</label>
            {selectedFormat === ImportFormat.PlainText && (
              <>
                <br />
                <textarea
                  style={{ width: "100%" }}
                  placeholder={"Note 1\n--\nNote 2"}
                  rows={8}
                  onChange={(e) => setPlainTextImportData(e.target.value)}
                />
              </>
            )}
          </div>
          <div>
            <input
              disabled
              style={{ marginRight: 8 }}
              type="radio"
              id="modern"
              onChange={() => setSelectedFormat(ImportFormat.Ideaflow)}
              checked={selectedFormat === ImportFormat.Ideaflow}
            />
            <label style={{ color: "gray" }} htmlFor="modern">
              From an Ideaflow account (.if JSON file)
            </label>
          </div>
          <div>
            <input
              disabled
              style={{ marginRight: 8 }}
              type="radio"
              id="legacy"
              onChange={() => setSelectedFormat(ImportFormat.IdeaflowLegacy)}
              checked={selectedFormat === ImportFormat.IdeaflowLegacy}
            />
            <label style={{ color: "gray" }} htmlFor="legacy">
              From legacy Thoughtstream app (JSON, Settings &gt; Export)
            </label>
          </div>
          <div>
            <input
              disabled
              style={{ marginRight: 8 }}
              type="radio"
              id="legacy"
              onChange={() => setSelectedFormat(ImportFormat.AppleNotes)}
              checked={selectedFormat === ImportFormat.AppleNotes}
            />
            <label style={{ color: "gray" }} htmlFor="legacy">
              From Apple Notes - coming soon
            </label>
          </div>
        </div>
        <span style={{ margin: "12px 0px" }}>
          To ask for more formats or assistance, please{" "}
          <a href="mailto:contact@ideaflow.io">contact us</a>
        </span>
        <div>
          <input
            id="file"
            style={{ margin: "24px auto" }}
            type="file"
            accept={selectedFormat === ImportFormat.Ideaflow ? ".if" : ""}
            onChange={importFile}
          />
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-end",
            position: "absolute",
            right: "32px",
            bottom: "32px",
          }}
        >
          <Secondary onClick={() => setModal("")}>Cancel</Secondary>
          <Cta
            onClick={doImport}
            disabled={
              !(
                ((selectedFormat === ImportFormat.IdeaflowLegacy ||
                  selectedFormat === ImportFormat.Ideaflow) &&
                  !file) ||
                (selectedFormat === ImportFormat.PlainText &&
                  plainTextImportData.length > 0)
              )
            }
          >
            Import
          </Cta>
        </div>
      </Modal>
    );
}
