import { Command } from "prosemirror-state";
import { SearchQuerySetter } from "../../../search/SearchQuery";
import { schema } from "../../schema";
import { serializeNode } from "../../utils/serializeNode";

export function getSearchForSelection(
  setSearchQuery: SearchQuerySetter,
): Command {
  return (state) => {
    const { $from } = state.selection;
    const slice = state.selection.content();
    const isSelection = slice.size > 0;
    const onReference =
      slice.size === 1 &&
      slice.content.firstChild?.type === schema.nodes.reference;
    if (!isSelection || onReference) {
      // if there's no selection or the caret is sitting on top of a reference,
      // then search by hashtag or reference at or just before the caret
      for (const i of [0, -1, -2]) {
        const node = state.doc.nodeAt($from.pos + i);
        if (node?.marks[0]?.type === schema.marks.hashtag) {
          // search by hashtag
          setSearchQuery({
            type: "hashtag",
            q: node.textContent,
          });
          return true;
        } else if (node?.type === schema.nodes.reference) {
          // open reference as page
          setSearchQuery({
            type: "note",
            q: node.attrs.linkedNoteId,
            asPage: true,
          });
          return true;
        }
      }
      return false;
    } else if (isSelection) {
      // search by text
      const selectedText = serializeNode(slice);
      setSearchQuery({
        type: "text",
        q: selectedText,
      });
      return true;
    }
    return false;
  };
}
