import { MarkType } from "prosemirror-model";
import { TextSelection, Transaction } from "prosemirror-state";

/**
 * This function removes the selection, performs the mark removal in the defined range and
 * resets the selection to the original one. It's needed to fix https://linear.app/ideaflow/issue/ENT-1738
 * For some unknown reason Prosemirror is not correctly preserving a selection when marks are removed
 */
export function fixedRemoveMark(
  tr: Transaction,
  from: number,
  to: number,
  markType: MarkType,
) {
  const sel = tr.selection;
  tr.setSelection(new TextSelection(tr.doc.resolve(0), tr.doc.resolve(0)));

  // Remove the mark
  tr.removeMark(from, to, markType);

  // Reset to the original selection (no need to map since removeMark shouldn't change node sizes)
  const newSel = new TextSelection(
    tr.doc.resolve(sel.anchor),
    tr.doc.resolve(sel.head),
  );
  tr.setSelection(newSel);
}
