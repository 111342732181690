import { FlatIndex } from "../model/ram/FlatIndex";
import { TokenId } from "../model/types";
import { parse, Token } from "./nlp";
import { BlockText } from "../model/noteFormatter";

export type Hit<Entry> = {
  entry: Entry;
  asText?: BlockText[];
  matches?: TokenId[];
  relevance?: number;
};
export type HitWithMatch<Entry> = Hit<Entry> & {
  matches: TokenId[];
  asText: BlockText[];
};

/**
 * Fast unicode multiword searcher based on regexp.
 * It is designed with mobile in mind, garbage-collector friendly and low CPU-upfront / fast indexing.
 * We wanted fast load times and low mem footprint.
 * It has been heavily benchmarked up to 30k notes and is optimized for being excellent between 10k to 30k.
 * Benchmarks are available for documentation
 *
 * - the searcher will find all matches to a multiword query in a provided datastructure
 * - the datastructure must have 1 field with a preprocessed sequence of words. Either an array or a map. So it is the responsibility of the user to preprocess its own data.
 *
 * Features:
 * - multiword search
 * - exact match search
 * - limit number of results
 * - find positions of matches for single word and exact match (multiword non exact positions is WIP)
 *
 * Code is sometimes verbose, but it is only meant to be a fast lib beyond the simple complexity logic, not a delicacy to read.
 */

export function removeDiacritics(s: string) {
  return s.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
}

export function queryStringToTokens(query: string): Token[] {
  let { tokens } = parse(query);
  tokens = tokens.filter((t) => t.text !== "");
  if (tokens.length === 0) {
    return [];
  } else if (!tokens.every((t) => t.stopword)) {
    // Filter out stopwords, but only if there are other tokens
    tokens = tokens.filter((t) => !t.stopword);
  }
  return tokens;
}

/**
 * Given a map of entries and a query, return an array of matching entries as hits.
 *
 * Arrays are used internally past the first word for speed purposes.
 *
 * @param map Map<T> where T is of shape {...any, [field]: string}
 * @param field the attribute containing the text to find
 * @param queryString multiword query string.
 * @param options
 */
export function findInMap<T extends { id: string }>(
  map: Map<any, T>,
  index: FlatIndex<string, BlockText[]>,
  queryString: string,
): HitWithMatch<T>[] {
  return findInArray(Array.from(map.values()), index, queryString);
}

/**
 * Given an array of entries and a query, return an array of matching entries as hits.
 *
 * @param array entries to filter
 * @param index a map of entry id to text blocks
 * @param queryString a query string.
 */
export function findInArray<T extends { id: string }>(
  array: T[],
  index: FlatIndex<string, BlockText[]>,
  queryString: string,
): HitWithMatch<T>[] {
  if (queryString === "")
    throw new Error("need to search with a non empty query string");
  const tokens = queryStringToTokens(queryString);
  if (tokens.length === 0) return [];

  let n = array.map((entry) => ({
    entry,
    asText: index.get(entry.id) ?? [],
    matches: [] as TokenId[],
  }));

  tokens.forEach(({ text, stem }) => {
    n = findSubstringInEntries(n, stem || text);
  });

  return n;
}

/**
 * Filters for entries that match the substring.
 *
 * @param hits entries to filter
 * @param substring a string to be matched
 * @returns entries that match the substring
 */
function findSubstringInEntries<T>(
  hits: HitWithMatch<T>[],
  substring: string,
): HitWithMatch<T>[] {
  const r = new RegExp(substringMatchPattern([substring]), "imu");
  const out = [];

  for (const hit of hits) {
    let found = false;
    for (const { tokenId, text } of hit.asText) {
      if (r.test(removeDiacritics(text))) {
        hit.matches.push(tokenId);
        found = true;
      }
    }
    if (found)
      out.push({
        entry: hit.entry,
        asText: hit.asText,
        matches: hit.matches,
      });
  }
  return out;
}

export function findInCollection(arr: string[], queryString: string): string[] {
  if (queryString === "")
    throw new Error("need to search with a non empty query string");

  const tokens = queryStringToTokens(queryString);
  if (tokens.length === 0) return [];
  let n = arr;
  tokens.forEach(({ text, stem }) => {
    n = findSubstringInStrings(n, stem || text);
  });
  // we find positions at the end, as finding positions in match at the same time is significantly lower (check benchmarks).
  // @todo check time saved passing the regexp directly precompiled
  // @todo fix findPositions for multiword+non exact queries
  return n;
}

function findSubstringInStrings(arr: string[], substring: string): string[] {
  const r = new RegExp(substringMatchPattern([substring]), "imu");
  const out = [];

  for (let i = 0; i < arr.length; i++) {
    if (r.test(removeDiacritics(arr[i] as any))) {
      out.push(arr[i]);
    }
  }
  return out;
}

export function substringMatchPattern(substrings: string[]): string {
  if (substrings.length === 0) throw new Error("need at least one substring");
  return substrings.map(removeDiacritics).map(escapeRegExp).join("|");
}

// https://developer.mozilla.org/en-US/docs/Web/JavaScript/Guide/Regular_Expressions#escaping
export function escapeRegExp(s: string) {
  return s.replace(/[.*+?^${}()|[\]\\]/g, "\\$&"); // $& means the whole matched string
}

export function createSearchMatchRegex(query: string) {
  const tokens = queryStringToTokens(query);
  if (!tokens) {
    return new RegExp("a^", "g");
  }
  const strings: string[] = [query];
  tokens.forEach((token) => {
    strings.push(token.text);
    if (token.stem) {
      strings.push(token.stem);
    }
  });
  return new RegExp(substringMatchPattern(strings), "gi");
}
