import debug from "debug";
import { isAnalyticsEnabled } from "../utils/environment";
import { NoteId } from "../model/types";
import { Shortcut } from "../shortcuts/shortcuts";

type FolderId = string;

type MatomoEventType =
  | ["NOTE", "CREATED", NoteId]
  | ["NOTE", "CREATED_FROM_FOLDER", NoteId]
  | ["NOTE", "CREATED_FROM_RELATION", NoteId]
  | ["NOTE", "CREATED_AS_PAGE", NoteId]
  | ["NOTE", "UPDATED", NoteId]
  | ["NOTE", "UPDATED_FROM_EXPANSION", NoteId]
  | ["NOTE", "DELETED", NoteId]
  | ["NOTE", "DELETED_FROM_FOLDER", NoteId]
  | ["NOTE", "SPLIT"]
  | ["NOTE", "SPLIT_FROM_FOLDER"]
  | ["NOTE", "SPLIT_WITH_DASHES"]
  | ["NOTE", "SPLIT_WITH_DASHES_FROM_FOLDER"]
  | ["NOTE", "MERGE"]
  | ["NOTE", "OPENED_AS_PAGE"]
  | ["NOTE", "TOGGLED_PINNED"]
  | ["NOTE", "COPY_TEXT"]
  | ["NOTE", "COPY_URL"]
  | ["NOTE", "SHARE_ALL"]
  | ["KEYBOARD", "SHORTCUT", Shortcut["id"]]
  | ["SEARCH", "SEARCH"]
  | ["SEARCH", "FROM_SIDEBAR"]
  | ["SEARCH", "FOCUS_FROM_FOOTER"]
  | ["RELATIONS", "CREATED"]
  | ["RELATIONS", "CREATED_FROM_MOBILE_BAR"]
  | ["RELATIONS", "TOGGLED"]
  | ["SESSION", "STARTED"] // doesnt work. Only tracks when a user logs in.
  | ["WINDOW", "FOCUSED"]
  | ["WINDOW", "BLURRED"]
  | ["FOLDER", "CREATED", FolderId] // doesnt work from the note contextual menu.
  | ["FOLDER", "UPDATED", FolderId]
  | ["FOLDER", "DELETED", FolderId]
  | ["FOLDER", "NOTE_ADDED"]
  | ["FOLDER", "NOTE_REMOVED"]
  | ["FOLDER", "NOTE_UPDATED"]
  | ["HASHTAG", "CREATED"]
  | ["HASHTAG", "CREATED_FROM_MOBILE_BAR"]
  | ["HASHTAG", "ADDED"]
  | ["LIST", "CREATED"]
  | ["LIST", "CREATED_FROM_MOBILE_BAR"]
  | ["LIST", "CHANGE_DEPTH"]
  | ["LIST", "CHANGE_DEPTH_FROM_MOBILE_BAR"]
  | ["CHECKBOX", "CREATED"]
  | ["CHECKBOX", "CREATED_FROM_MOBILE_BAR"]
  | ["FILTER", "COMPACT_VIEW_ON"]
  | ["FILTER", "COMPACT_VIEW_OFF"]
  | ["FILTER", "WITHSUBFOLDERS_VIEW_ON"]
  | ["FILTER", "WITHSUBFOLDERS_VIEW_OFF"]
  | ["FILTER", "SORT_BY_POSITION"]
  | ["FILTER", "SORT_BY_LAST_UPDATED"]
  | ["FILTER", "SORT_BY_RELEVANCE"]
  | ["GO", "HOME_FROM_SHORTCUT"]
  | ["GO", "HOME_FROM_SIDEBAR"]
  | ["GO", "HOME_FROM_HEADER"]
  | ["GO", "HOME_FROM_FOOTER"]
  | ["GO", "BACK_FROM_HEADER"]
  | ["GO", "HISTORY_FROM_BROWSER"]
  | ["SIDEBAR", "TOGGLE_FROM_HEADER"]
  | ["SIDEBAR", "TOGGLE_FROM_FOOTER"]
  | ["UNDO", "FROM_MOBILE_BAR"]
  | ["REDO", "FROM_MOBILE_BAR"]
  | ["EXPANSION", "APPEND"]
  | ["IMPORT", "PLAIN_TEXT"]
  | ["EXPORT", "JSON"];

/**
 * A group of events that should be tracked at most once per interval.
 */
type EventGroup = string;
class EventTracker {
  private timestamps: Map<EventGroup, number> = new Map();

  track(event: MatomoEventType) {
    if (!isAnalyticsEnabled) return;
    if (!this.hasMinIntervalPassed(event)) return;
    debug("analytics")("trackEvent:", event);
    window._paq.push(["trackEvent"].concat(event));
    this.setEventGroupTimestamp(event, Date.now());
  }

  private hasMinIntervalPassed(event: MatomoEventType) {
    const timestamp = this.getEventGroupTimestamp(event);
    return !timestamp || Date.now() - timestamp >= this.minInterval(event);
  }

  private getEventGroupTimestamp(event: MatomoEventType) {
    return this.timestamps.get(this.eventToEventGroup(event));
  }

  private setEventGroupTimestamp(event: MatomoEventType, timestamp: number) {
    this.timestamps.set(this.eventToEventGroup(event), timestamp);
  }

  private minInterval(event: MatomoEventType) {
    const eventGroup = this.eventToEventGroup(event);
    if (eventGroup.startsWith("NOTE.UPSERT")) {
      return 30 * 60 * 1000; // 30 minutes
    } else if (eventGroup.startsWith("FOLDER.UPSERT")) {
      return 1 * 60 * 1000; // 1 minutes
    } else {
      return 0;
    }
  }

  private eventToEventGroup(event: MatomoEventType): EventGroup {
    const [category, action, name] = event;
    // use same timer for note/folder created and updated events
    const actionStart = action.split("_")[0];
    if (
      ["NOTE", "FOLDER"].includes(category) &&
      ["UPDATED", "CREATED"].includes(actionStart)
    ) {
      return [category, "UPSERT", name].join(".");
    }
    return event.join(".");
  }
}

const eventTracker = new EventTracker();

/**
 * Tracks an event in Matomo.
 * Skips tracking if the minimum interval between events has not passed (see {@link EventTracker}).
 * */
export const trackEvent = (event: MatomoEventType) => {
  eventTracker.track(event);
};

/**
 * Sets the current session's user id in Matomo.
 */
export const setUserId = (userId: string) => {
  if (isAnalyticsEnabled) window._paq.push(["setUserId", userId]);
};
