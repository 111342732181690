import { captureMessage } from "@sentry/browser";
import { useAtomValue } from "jotai";
import { useEffect, useRef } from "react";
import { messageAtom } from "./messageAtom";

export const useCaptureError = () => {
  const lastErrorRef = useRef("");
  const message = useAtomValue(messageAtom);
  useEffect(() => {
    if (lastErrorRef.current !== message.content) {
      captureMessage(message.content);
    }
    lastErrorRef.current = message.content;
  }, [message]);
};
