// Definitions by: Baptiste Manson <bat@ideaflow.io>
export type NoteId = string;
export type FolderId = string;
export type UserId = string;
export type HashtagId = string;
type ImageSrc = string;
export type IdeaDate = Date;
export type TokenId = string;

/**
 * Index of block token (not counting bullet list tokens) inside a {@link Note}.
 */
export type BlockNumber = number;

export type SecondaryMark = "bold" | "italic" | "underline" | "strikethrough";

export const masterRevInitial = "historical";

export interface TextToken {
  type: "text";
  marks: SecondaryMark[];
  content: string;
}

interface LinkToken {
  type: "link";
  content: string;
  slug: string;
}

interface HashtagToken {
  type: "hashtag";
  content: string;
}

interface SpaceshipToken {
  type: "spaceship";
  linkedNoteId: NoteId;
  tokenId: TokenId;
}

// The image tokens inherited from the older versions of the app
// might not have some properties present
interface ImageToken {
  type: "image";
  src: ImageSrc;
  width: number | null;
  naturalWidth: number | null;
  naturalHeight: number | null;
  smallPreviewDataURL: string | null;
}

interface CheckboxToken {
  type: "checkbox";
  isChecked: boolean;
}

export type IdeaPosition = string;

export interface ParagraphToken {
  tokenId: TokenId;
  type: "paragraph";
  content: InlineToken[];
}

export interface CodeblockToken {
  tokenId: TokenId;
  type: "codeblock";
  content: TextToken[];
}

export interface LinkLoaderToken {
  type: "linkloader";
  url: string;
  // Before the link is processed the following properties can be missing
  title: string | null;
  description: string | null;
  image: string | null;
}

export interface ListToken {
  type: "list";
  content: ListItemToken[];
}

export interface ListItemToken {
  type: "listItem";
  content: BlockToken[];
  depth: number;
}

/** Top level tokens can exist one level of depth below Note type */
export type BlockToken = ParagraphToken | CodeblockToken | ListToken;

export type InlineToken =
  | LinkToken
  | ImageToken
  | HashtagToken
  | TextToken
  | SpaceshipToken
  | CheckboxToken
  | LinkLoaderToken;

// soft deletable elements with revision management
export interface Persistable {
  id: string;
  localRev: string;
  masterRev: string;
  updatedAt: IdeaDate;
  deletedAt: IdeaDate | null;
}
// who updates the rev?
// cli 1, sw 1, server 1
// cli 2, sw 1, server 1
// cli 2, sw 2, server 1
// cli 3, sw 2, server 1
// cli 3, sw 3, server 1
// ...
// cli 4, sw 4, server 1
// cli 4, sw 4, server ?
// how do you craft the delta?

// old_rev => master_rev: the rev of the master it replicates to?

// you are on a bad network, which takes time to answer
// cli 1, sw 1/1, server 1
// cli 2, sw 2/1, server 1
// sends update to server: 2
// cli 3, sw 3/1, server 1
// cli 4, sw 4/1, server ?
// receives answer from server: 2/1 => safely ignore it because it is in your past (old rev are matching)

// you are on a bad network, which takes time to answer
// cli 1, sw 1/1, server 1
// cli 2, sw 2/1, server 1
// sends update to server: 2/1
// cli 3, sw 3/1, server 1
// cli 4, sw 4/1, server ?
// timeout from a server response
// sends update to server: 4/1 <-
// within a reasonable amount of time, we assume it is lost
// implies that we send updates less fast than we can receive server response

// old_rev: [1, 2, 3]
// push shift + pop (3/1) (leab it in array)

// when receive update from server,
//find position in old_revs, truncate all old_revs prior to the rev of the server.
// ifs its not in it, conflict!!

// cli 1, sw 1/1, server 1
// cli 2, sw 2/1, server 1
// sends update to server: 2/1
// cli 3, sw 3/1, server 1
// (server is 2 already but sw doesnt know it yet)
// sends update to server: 3/1
// rejected! conflicts, im version 2!!
// trivial if we have the list of revs. push a 3/2
//
// pull everything from the server first, then we push updates exclusively with 5s timeout. <- current approach, not sure its the right one.

// transaction id (autoincrement global set by the server)
// receives updates from the server, transaction id.

export interface Note extends Persistable {
  id: NoteId;
  tokens: BlockToken[];
  createdAt: IdeaDate;
  updatedAt: IdeaDate;
  deletedAt: IdeaDate | null;
  localRev: string;
  masterRev: string;
  insertedAt: string;
  position: IdeaPosition;
  /** true if the note is publicly accessible. Named after the read all Unix flag.*/
  readAll: boolean;
  folderId: string | null;
  positionInPinned?: string | null;
  importSource?: string | null;
  importBatch?: string | null;
  importForeignId?: string | null;
}

export interface SerializedNote {
  id: NoteId;
  tokens: BlockToken[];
  created_at: string;
  updated_at: string; //"2021-03-24T00:07:56.075283+00:00"
  deleted_at: string | null;
  rev: string;
  inserted_at: string; // "20210323"
  position: string;
  /** true if the note is publicly accessible. Named after the read all Unix flag.  */
  read_all: boolean;
  position_in_pinned: string | null;
  folder_id: string | null;
  import_source: string | null;
  import_batch: string | null;
  import_foreign_id: string | null;
  __typename?: string;
}

export interface ThoughtstreamFromServer {
  id: string;
  notes: SerializedNote[];
  folders: SerializedFolder[];
  graphId?: string;
}

export interface Folder extends Persistable {
  id: FolderId;
  name: string;
  parentId: FolderId | null; // null is root
  position: IdeaPosition;
  updatedAt: IdeaDate;
  deletedAt: IdeaDate | null;
}

export interface SerializedFolder {
  id: FolderId;
  name: string;
  rev: string;
  parent_id: string | null;
  position: IdeaPosition;
  updated_at: string;
  deleted_at: string | null;
  __typename?: string;
}

export interface Hashtag {
  content: string;
}
