import { Plugin, PluginKey, Transaction } from "prosemirror-state";
import { Node } from "prosemirror-model";
import { Decoration, DecorationSet, EditorView } from "prosemirror-view";
import {
  noteBlockMatches,
  expansionSet,
  noteList,
} from "../../../model/services";
import { schema } from "../../schema";
import { assert } from "../../../utils/assert";
import {
  getPathToBacklinkNote,
  getPathToBacklinkSection,
} from "../../utils/path";
import { insertExpandedReference } from "../reference/referenceExpansionUtils";
import { TokenId, Note, NoteId } from "../../../model/types";
import { subTransaction } from "../../utils/subTransaction";
import { descendNotes } from "../../utils/descendNotes";
import getNode from "../../utils/getNode";
import path from "path";
import { findDescendantNote } from "../../utils/find";
import { trackEvent } from "../../../analytics/analyticsHandlers";
import { mapBlockTokens } from "../../../utils/tokenIterators/mapBlockTokens";
import { noteToProsemirrorNode } from "../../bridge";

/**
 * Get offset position of the backlink section in note.
 *
 * It may be the real position, or the wished position if it's missing.
 *
 */
export function getBacklinkOffsetInNote(node: Node): number {
  let out = node.nodeSize - 1; // end of note by default
  node.forEach((sub, offset) => {
    if (sub.type === schema.nodes.backlinks) {
      out = offset + 1;
    }
    return false; // no depth
  });
  return out;
}

/**
 * Creates an interactive toggle to expand and collapses backlinks.
 *
 */
function createToggle(node: Node, count: number, expanded: boolean) {
  return function toDOM(view: EditorView): HTMLElement {
    const button = document.createElement("button");
    button.classList.add("backlink-button");
    button.textContent = getToggleLabel(count, node.attrs.depth);
    if (expanded) {
      button.classList.add("expanded");
    }
    const notePath = node.attrs.path;
    button.addEventListener("click", () => {
      const pos = findDescendantNote(
        view.state.doc,
        (n) => n.type === schema.nodes.note && n.attrs.path === notePath,
      )[1];
      const tr = view.state.tr;
      tr.setMeta("type", "toggleBacklink");
      tr.setMeta("noChangeToModel", true);
      toggleSection(tr, pos!);
      trackEvent(["RELATIONS", "TOGGLED"]);
      view.dispatch(tr);
    });
    // prevent touch on mobile from opening the keyboard
    button.addEventListener("mousedown", (e) => e.preventDefault());

    const container = document.createElement("div");
    container.classList.add("backlinks");
    container.appendChild(button);
    return container;
  };
}

/**
 * Returns the toggle label
 * @param noteId
 * @returns
 */
function getToggleLabel(count: number, depth: number) {
  const label = [count.toString()];
  if (depth > 0) label.push("additional");
  label.push(count === 1 ? "reference" : "references");
  return label.join(" ");
}

function isSetEqual(a: Set<string>, b: Set<string>) {
  if (a.size !== b.size) return false;
  for (const item of a) {
    if (!b.has(item)) return false;
  }
  return true;
}

type BacklinkTogglePluginState = Map<string, Set<string>>;
/**
 * The backlink plugin.
 *
 * Decorates the notes with toggles.
 */
export const backlinkTogglePlugin = new Plugin<BacklinkTogglePluginState>({
  key: new PluginKey("backlinkTogglePlugin"),
  state: {
    init() {
      return new Map<string, Set<string>>();
    },
    apply(tr, oldPluginState) {
      if (tr.getMeta("noChangeToModel") || !tr.docChanged) {
        return oldPluginState;
      }
      const newState = new Map();
      tr.doc.forEach((node, pos) => {
        const noteId = node.attrs.noteId;
        const backlinkNoteIds = new Set(
          noteList.backlinks.getNoteIdsForItem(noteId),
        );
        newState.set(noteId, backlinkNoteIds);
      });
      return newState;
    },
  },
  appendTransaction(trs, oldState, newState) {
    if (trs.every((tr) => tr.getMeta("noChangeToModel") || !tr.docChanged)) {
      return null;
    }

    // Find notes whose backlinks have changed
    const oldBacklinks = backlinkTogglePlugin.getState(oldState)!;
    const newBacklinks = backlinkTogglePlugin.getState(newState)!;
    const noteIdsToUpdate: Set<string> = new Set();
    for (const [noteId, backlinkIds] of oldBacklinks.entries()) {
      if (!isSetEqual(backlinkIds, newBacklinks.get(noteId) ?? new Set())) {
        noteIdsToUpdate.add(noteId);
      }
    }
    // Update the backlinks section of the notes
    const tr = newState.tr;
    descendNotes(tr.doc, (note, oldPos) => {
      const pos = tr.mapping.map(oldPos);
      if (tr.doc.nodeAt(pos)?.type !== schema.nodes.note) return; // skip if note has been deleted
      fixSectionPosition(tr, pos);
      if (noteIdsToUpdate.has(note.attrs.noteId)) {
        updateSection(tr, pos);
      }
    });
    tr.setMeta("type", "updateBacklinks");
    tr.setMeta("noChangeToModel", true);
    return tr.docChanged ? tr : null;
  },
  props: {
    decorations(state) {
      const toggles: Decoration[] = [];
      // find notes needing toggles
      descendNotes(state.doc, (node, pos) => {
        // Get backlink count
        const posBacklinks = pos + getBacklinkOffsetInNote(node);
        const backlinks = state.doc.nodeAt(posBacklinks);
        let count: number, expanded: boolean;
        if (backlinks?.type === schema.nodes.backlinks) {
          count = backlinks.childCount;
          expanded = true;
        } else {
          const note = getNode(state.doc, pos, schema.nodes.note);
          count = getBacklinkIds(note).length;
          expanded = false;
        }
        if (count > 0) {
          const key = path.join(
            getPathToBacklinkSection(node.attrs.path),
            count.toString(),
            expanded.toString(),
          );
          toggles.push(
            Decoration.widget(
              posBacklinks,
              createToggle(node, count, expanded),
              {
                side: 1,
                key,
              },
            ),
          );
        }
      });
      return DecorationSet.create(state.doc, toggles);
    },
  },
});

/**
 * Fix the position of the backlinks section in a note.
 *
 * After a join or split, the backlinks section may be in the wrong place.
 * This function moves it to the end of the note.
 *
 * @param tr
 * @param notePos
 */
function fixSectionPosition(tr: Transaction, notePos: number) {
  const note = getNode(tr.doc, notePos, schema.nodes.note);
  let movedToBottom = false;
  return subTransaction(tr, (tr) => {
    note.forEach((child, childOffset, index) => {
      if (child.type === schema.nodes.backlinks) {
        const curPos = tr.mapping.map(notePos + childOffset + 1);
        if (child.attrs.noteId !== note.attrs.noteId) {
          // remove backlink sections that are not for this note
          tr.delete(curPos, curPos + child.nodeSize);
        } else if (index !== note.childCount - 1) {
          // move backlink section to the bottom
          tr.delete(curPos, curPos + child.nodeSize);
          if (!movedToBottom) {
            const newPos = tr.mapping.map(notePos + note.nodeSize - 1);
            tr.insert(newPos, child);
            movedToBottom = true;
          }
        }
      }
    });
  });
}

/**
 * Returns the backlinks for a note, excluding circular references, and sorted by position.
 * @param note Note to get backlinks for
 * @returns NoteIds of backlinks
 */
function getBacklinkIds(note: Node): NoteId[] {
  if (note.type !== schema.nodes.note) {
    throw new Error("node is not a note");
  }
  const backlinksNote = noteList.backlinks
    .getNoteIdsForItem(note.attrs.noteId)
    .filter(
      (noteId) => !note.attrs.path.split("/").includes(noteId), // exclude circular references
    )
    .map((noteId) => noteList.get(noteId))
    .filter((note) => !!note) as Note[];
  return backlinksNote
    .sort((a, b) => (a.position < b.position ? -1 : 1))
    .map((note) => note.id);
}

function getNotePosition(noteId: string): string {
  const note = noteList.get(noteId);
  assert(!!note, `note ${noteId} does not exist`);
  return note!.position;
}

/**
 * Collapses the backlink section starting at pos.
 */
export function collapseSection(tr: Transaction, notePos: number) {
  const note = getNode(tr.doc, notePos, schema.nodes.note);
  const startPos = notePos + getBacklinkOffsetInNote(note);
  const backlink = tr.doc.nodeAt(startPos);
  if (backlink?.type !== schema.nodes.backlinks) {
    return tr; // backlink section is already collapsed
  }
  tr.delete(startPos, startPos + backlink.nodeSize);
  expansionSet.delete(getPathToBacklinkSection(note.attrs.path));
  return tr;
}

/**
 * Expand the backlink section of a note.
 *
 * @param tr Transaction
 * @param notePos Position of the note to toggle
 * @param nodes Optional map of nodes to populate the backlink section with
 * @returns
 */
export function expandSection(tr: Transaction, notePos: number) {
  const note = getNode(tr.doc, notePos, schema.nodes.note);
  if (areBacklinksExpanded(note)) {
    return tr; // backlinks are already expanded
  }

  // Insert empty backlinks section
  const noteId = note.attrs.noteId;
  const startPos = notePos + getBacklinkOffsetInNote(note);
  tr.insert(
    startPos,
    schema.nodes.backlinks.create({
      path: note.attrs.path,
      noteId,
    }),
  );

  // Populate backlinks
  // Sort backlinks by position, but in reverse order because we insert them in reverse order
  const backlinksId = getBacklinkIds(note).reverse();
  backlinksId.forEach((noteId) => {
    insertExpandedReference(tr, startPos + 1, noteId, { parent: "backlinks" });
  });

  expansionSet.add(getPathToBacklinkSection(note.attrs.path));
  return tr;
}

/**
 * Updates the backlink section of a note.
 *
 * - If the note has no backlink section, it returns the transaction unchanged
 * - Removes backlinks that no longer exist
 * - Adds new backlinks, inserting them in the correct position
 *
 * @param tr
 * @param noteStartPos
 * @returns
 */
function updateSection(tr: Transaction, noteStartPos: number) {
  // Get note and it's backlinks
  const note = getNode(tr.doc, noteStartPos, schema.nodes.note);
  const newBacklinkIds = getBacklinkIds(note);

  // Get prosemirror backlinks node
  const backlinksPos = noteStartPos + getBacklinkOffsetInNote(note);
  const backlinks = tr.doc.nodeAt(backlinksPos);
  if (backlinks?.type !== schema.nodes.backlinks) {
    return tr;
  }

  if (newBacklinkIds.length === 0) {
    return collapseSection(tr, noteStartPos);
  }

  // Figure out which backlinks to add and remove
  const oldBacklinkIds: string[] = [];
  backlinks.forEach((child) => {
    oldBacklinkIds.push(child.attrs.containedNoteId);
  });
  const addBacklinkIds = newBacklinkIds.filter(
    (noteId) => !oldBacklinkIds.includes(noteId),
  );
  const removeBacklinkIds = oldBacklinkIds.filter(
    (noteId) => !newBacklinkIds.includes(noteId),
  );

  // Update transaction with changes
  return subTransaction(tr, (tr) => {
    let i = 0;
    let posExpRef = backlinksPos + 1;
    while (i < backlinks.childCount) {
      const expRef = backlinks.child(i);
      const noteId = expRef.attrs.containedNoteId;
      if (removeBacklinkIds.includes(noteId)) {
        tr.delete(posExpRef, posExpRef + expRef.nodeSize);
      } else if (
        addBacklinkIds.length > 0 &&
        getNotePosition(addBacklinkIds[0]) < getNotePosition(noteId)
      ) {
        // Insert new backlink in the correct position
        const backlinkId = addBacklinkIds.shift()!;
        insertExpandedReference(tr, tr.mapping.map(posExpRef), backlinkId, {
          parent: "backlinks",
        });
        continue;
      }
      i++;
      posExpRef += expRef.nodeSize;
    }
    // Add remaining backlinks at the end
    addBacklinkIds.forEach((noteId) => {
      const backlinksEndPos = tr.mapping.map(
        backlinksPos + backlinks.nodeSize - 1,
      );
      insertExpandedReference(tr, backlinksEndPos, noteId, {
        parent: "backlinks",
      });
    });
  });
}

/**
 * Toggle the backlink section of a note.
 *
 * @param tr Transaction
 * @param notePos Position of the note to toggle
 * @param nodes Optional map of nodes to populate the backlink section with
 * @returns
 */
export function toggleSection(tr: Transaction, notePos: number) {
  const note = getNode(tr.doc, notePos, schema.nodes.note);
  if (areBacklinksExpanded(note)) {
    collapseSection(tr, notePos);
  } else {
    expandSection(tr, notePos);
  }
  return tr;
}

export function areBacklinksExpanded(note: Node) {
  if (note.type !== schema.nodes.note) {
    throw new Error("node is not a note");
  }
  let res = false;
  note.forEach((child) => {
    if (child.type === schema.nodes.backlinks) {
      res = true;
    }
  });
  return res;
}

/**
 *
 * @param note Note to convert to a backlink node
 * @param parentNoteNode Parent note node
 * @param matches Tokens to show (i.e. not to condense)
 * @param minToCondense Minimum number of lines and characters to condense
 * @returns
 */
export function noteToBacklinkProsemirrorNode(
  note: Note,
  parentNoteNode: Node,
  matches?: TokenId[],
  minToCondense = { chars: 500, lines: 5 },
) {
  const notePath = getPathToBacklinkNote(parentNoteNode.attrs.path, note.id);
  const noteTexts = noteList.getNoteAsBlockTexts(note.id) || [];
  let newMatches: TokenId[] = [];
  if (
    noteTexts.length > minToCondense.lines ||
    noteTexts.map((b) => b.text).join("").length > minToCondense.chars
  ) {
    if (matches !== undefined) {
      newMatches = matches;
    } else {
      note.tokens.forEach(
        mapBlockTokens((blockToken) => {
          if (
            blockToken.content.some(
              (t) =>
                t.type === "spaceship" &&
                t.linkedNoteId === parentNoteNode.attrs.noteId,
            )
          ) {
            newMatches.push(blockToken.tokenId);
          }
        }),
      );
    }
  }
  noteBlockMatches.set(notePath, newMatches);
  const dir = getPathToBacklinkSection(parentNoteNode.attrs.path);
  return noteToProsemirrorNode(note, dir, () => ({}), newMatches);
}
