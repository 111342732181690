import { atom } from "jotai";

export type MessageType = "ok" | "warning" | "error";
export interface Message {
  content: string;
  type: MessageType;
}

export const NO_MESSAGE = { content: "", type: "ok" as const };
export const messageAtom = atom<Message>(NO_MESSAGE);
