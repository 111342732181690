import path from "path";
import { Node } from "prosemirror-model";
import { schema } from "../schema";
import getNode from "./getNode";
import { getParentNote } from "./find";

export type NodeId = string;

/**
 * A unique identifier for a node in the document.
 *
 * Examples:
 * - "/note1"                        -> note1 at the root
 * - "/note1/ref1/note2"             -> note2 inside expanded ref1 in note1
 * - "/note1/ref1/note2/ref2/note3"  -> note3 inside expanded ref2 ...
 * - "/note1/backlinks1/note2"       -> note2 inside backlinks section of note1
 *
 * Previously note ids were used for the directory portion of the path, but that
 * can result in duplicate note ids. For example, if you have a note with 2 references
 * to the same note like this:
 *
 * ```
 * note1
 * - expanded ref1
 *   - note2
 * - expanded ref2
 *   - note2
 * ```
 *
 * Both note2 nodes would have the "/note1/note2" id.
 *
 * Reference ids are unique within a note, so including them avoids this problem.
 */
export type Path = string;

export const generatePathFromNoteId = (noteId: string) => `/${noteId}`;

/**
 * Get path to a node.
 *
 * Why not just do something like `path.join(parent.attrs.path, node.attrs.id)`?
 * In some cases, a node is deeply nested in the document, and it's direct parent
 * doesn't have a path attribute. For example, if you have an expansion inside a
 * list item, the list item doesn't have a path attribute. So we need to traverse
 * up the tree to find a parent that has a path attribute.
 *
 * @param doc Prosemirror document
 * @param pos Position of the node
 */
export const getPathToNode = (doc: Node, pos: number): Path => {
  const node = getNode(doc, pos);
  const parent = doc.resolve(pos).parent;
  if (doc.resolve(pos).parent.type === schema.nodes.doc) {
    // top level note
    return generatePathFromNoteId(node.attrs.id);
  }
  const [note] = getParentNote(doc, pos);
  if (!note) throw new Error("Could not find parent note");
  switch (node.type) {
    case schema.nodes.note:
      if (parent.type === schema.nodes.expandedReference) {
        const [parentnote] = getParentNote(doc, pos - 1);
        if (!parentnote) {
          throw new Error("Could not find parent note of expanded reference");
        }
        if (parent.attrs.isBacklink) {
          return getPathToBacklinkNote(
            parentnote.attrs.path,
            node.attrs.noteId,
          );
        } else {
          return getPathToReferencedNote(
            parentnote.attrs.path,
            parent.attrs.referenceTokenId,
            node.attrs.noteId,
          );
        }
      } else {
        throw new Error("Unexpected parent type");
      }
    case schema.nodes.backlinks:
      return getPathToBacklinkSection(note.attrs.path);
    case schema.nodes.reference:
      return getPathToReference(note.attrs.path, node.attrs.tokenId);
    default:
      throw new Error("Unexpected node type");
  }
};

export const getPathToBacklinkSection = (notePath: Path): Path => {
  return path.join(notePath, "backlinks");
};

export const getPathToReference = (notePath: Path, tokenId: string): Path => {
  return path.join(notePath, tokenId);
};

export const getPathToBacklinkNote = (
  containerNotePath: Path,
  linkedNoteId: string,
): Path => {
  return path.join(getPathToBacklinkSection(containerNotePath), linkedNoteId);
};

/**
 * @param containerNotePath path to the note containing the reference
 * @param referenceId id of the reference node (Note: *Not* the expanded reference node.
 *  This is because reference tokens are persisted so their ids are stable, while expansion
 *  tokens aren't persisted so their ids are ephemeral.)
 * @param linkedNoteId id of the note being referenced
 * @returns
 */
export const getPathToReferencedNote = (
  containerNotePath: Path,
  referenceId: string,
  linkedNoteId: string,
): Path => {
  return path.join(
    getPathToReference(containerNotePath, referenceId),
    linkedNoteId,
  );
};

/**
 * Get note depth from it's path.
 *
 * Examples:
 * - "/note0"                       -> depth 0
 * - "/note0/ref1/note1"            -> depth 1
 * - "/note0/backlinks/note1"       -> depth 1
 */
export const getDepthFromPath = (path: Path): number => {
  return (path.split("/").length - 2) / 2;
};

export const getNoteIdFromTokenId = (path: Path): NodeId => {
  return path.split("/").pop()!;
};
