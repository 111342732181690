import { useAuth0 } from "@auth0/auth0-react";
import { authMockSub, isAuthEnabled } from "../utils/environment";

type Auth0Interface = Pick<
  ReturnType<typeof useAuth0>,
  | "error"
  | "isAuthenticated"
  | "isLoading"
  | "user"
  | "getAccessTokenSilently"
  | "getAccessTokenWithPopup"
  | "loginWithRedirect"
  | "loginWithPopup"
  | "logout"
>;

const mockAuth = {
  // Auth state:
  error: undefined,
  isAuthenticated: true,
  isLoading: false,
  user: {
    given_name: "Tyler",
    family_name: "Durden",
    nickname: "tyler",
    name: "Tyler Durden",
    picture: "/profile-default.jpg",
    locale: "en",
    updated_at: "2020-03-24T17:49:22.464Z",
    email: "bat@ideapad.io",
    email_verified: true,
    sub: authMockSub,
  },
  // Auth methods:
  getAccessTokenSilently: () => Promise.resolve("INVALID_JWT_MOCK_AUTH" as any),
  getAccessTokenWithPopup: () => Promise.resolve("INVALID_JWT"),
  loginWithRedirect: () => Promise.resolve(),
  loginWithPopup: () => Promise.resolve(),
  logout: () => {},
};

const useMockAuth = (): Auth0Interface => mockAuth;

export const useAuth: () => Auth0Interface = !isAuthEnabled
  ? useMockAuth
  : // we have to type cast due to the user object not being defined
    (useAuth0 as () => Auth0Interface);
