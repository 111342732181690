export type IntegerKey = string;

export class IntegerKeyEncoding {
  digits: string;
  INTEGER_ZERO: string;
  SMALLEST_INTEGER: string;

  constructor(digits: string) {
    this.digits = digits;
    this.INTEGER_ZERO = "a" + this.digits.charAt(0);
    this.SMALLEST_INTEGER = "A;;;;;;;;;;;;;;;;;;;;;;;;;;".replace(
      /;/g,
      this.digits.charAt(0),
    );
  }

  /**
   * Throws if int is not a validate IntegerKey
   */
  validate(int: IntegerKey) {
    if (
      !int ||
      int.length !== getIntegerLength(int.charAt(0)) ||
      !int
        .slice(1)
        .split("")
        .every((c) => this.digits.includes(c))
    ) {
      throw new Error("invalid integer key: " + int);
    }
  }

  getPrefix(str: string): IntegerKey {
    const result = str.slice(0, getIntegerLength(str.charAt(0)));
    this.validate(result);
    return result;
  }

  /**
   * Returns the IntegerKey immediately after x.
   *
   * Note that this may return null, as there is a largest integer.
   */
  increment(x: IntegerKey): IntegerKey | null {
    const { digits } = this;
    this.validate(x);
    const [head, ...digs] = x.split("");
    let carry = true;
    for (let i = digs.length - 1; carry && i >= 0; i--) {
      const d = digits.indexOf(digs[i]) + 1;
      if (d === digits.length) {
        digs[i] = "0";
      } else {
        digs[i] = digits.charAt(d);
        carry = false;
      }
    }
    if (carry) {
      if (head === "Z") {
        return "a0";
      }
      if (head === "z") {
        return null;
      }
      const h = String.fromCharCode(head.charCodeAt(0) + 1);
      if (h > "a") {
        digs.push("0");
      } else {
        digs.pop();
      }
      return h + digs.join("");
    } else {
      return head + digs.join("");
    }
  }

  /**
   * Returns the IntegerKey immediately before x.
   *
   * Note that this may return null, as there is a smallest integer.
   */
  decrement(x: IntegerKey): IntegerKey | null {
    const { digits } = this;
    this.validate(x);
    const [head, ...digs] = x!.split("");
    let borrow = true;
    for (let i = digs.length - 1; borrow && i >= 0; i--) {
      const d = digits.indexOf(digs[i]) - 1;
      if (d === -1) {
        digs[i] = digits.slice(-1);
      } else {
        digs[i] = digits.charAt(d);
        borrow = false;
      }
    }
    if (borrow) {
      if (head === "a") {
        return "Z" + digits.slice(-1);
      }
      if (head === "A") {
        return null;
      }
      const h = String.fromCharCode(head.charCodeAt(0) - 1);
      if (h < "Z") {
        digs.push(digits.slice(-1));
      } else {
        digs.pop();
      }
      return h + digs.join("");
    } else {
      return head + digs.join("");
    }
  }
}

function getIntegerLength(head: string) {
  if (head >= "a" && head <= "z") {
    return head.charCodeAt(0) - "a".charCodeAt(0) + 2;
  } else if (head >= "A" && head <= "Z") {
    return "Z".charCodeAt(0) - head.charCodeAt(0) + 2;
  } else {
    throw new Error("Invalid first character of integer key: " + head);
  }
}
