type Attribute = string | { key: string; type: "int" | "string" };
/**
 * Returns a valid getAttrs function based on a list of attributes.
 * An attribute can be either just a string or an object { key: string, type: "int" | "string" }
 *
 * It mutualizes all of our code behind the prefix "data-" in a HTML5 spirit.
 */

export const getDefaultAttrs = (
  attributes: Attribute[],
): ((dom: any) => { [key: string]: any }) => {
  return (dom) => {
    const out: { [key: string]: any } = {};
    for (const attr of attributes) {
      const key = typeof attr === "string" ? attr : attr.key;
      // Raw HTML value
      const htmlValue = dom.getAttribute(`data-${key.toLowerCase()}`);
      const parsedValue =
        typeof attr === "string" || attr.type === "string"
          ? htmlValue
          : htmlValue !== null
          ? parseInt(htmlValue)
          : null;
      out[key] = parsedValue;
    }
    return out;
  };
};
/**
 * Returns a valid getToDomDefault partial object.
 *
 * You will probably use it as such:
 * ```
 * toDOM(node) => ([
 *      "div",
 *      domAttrs(node, ["id", "noteId", { key: "depth", type: "int" }, "isExpanded"]),
 *      0,
 *    ]);
 * ```
 *
 * @param node the node to get attributes from
 * @param keys the list of attributes to get or an object with keys mapping
 *  to a default value in case the attribute is not present on the node.
 */

export const domAttrs = (
  node: any,
  keys: string[] | { [key: string]: any },
) => {
  const defaults = Array.isArray(keys) ? {} : keys;
  const keysArray = Array.isArray(keys) ? keys : Object.keys(keys);
  const out: { [key: string]: any } = {};
  keysArray.forEach((key) => {
    out[`data-${key.toLowerCase()}`] = node.attrs[key] ?? defaults[key];
  });
  return out;
};

export const entityInnerDOM = (char: string, content: string) => {
  const nbsp = "\u00a0";
  const entityContent: (string | Node)[] = [content + nbsp];

  const frag = document.createDocumentFragment();

  // The idea here is to use non-breaking spaces on either side of the content
  // instead of left and right padding, so that the text cursor shows up at the
  // edge of the pill and not inside it, especially on the left side. This works
  // really well. The only wrinkle is that Safari (desktop and mobile) is willing
  // to break a line between a non-breaking space and punctuation, so we need the
  // extra nowrap span.
  const startSpan = document.createElement("span");
  startSpan.style.whiteSpace = "nowrap";
  startSpan.textContent = nbsp + char;

  frag.appendChild(startSpan);
  frag.append(...entityContent);

  return frag;
};
