import * as ProsemirrorView from "prosemirror-view";

// The __serializeForClipboard and __parseFromClipboard fns are not publically exposed from
// the prosemirror-view module
const {
  __parseFromClipboard: parseFromClipboard,
  __serializeForClipboard: serializeForClipboard,
} = ProsemirrorView as any;

export function copyCurrentSelectionAsHTML(view: ProsemirrorView.EditorView) {
  const { dom } = serializeForClipboard(view, view.state.selection.content());
  return new XMLSerializer().serializeToString(dom);
}

const pressShiftKey = (target: Element) => {
  const event = new KeyboardEvent("keydown", { keyCode: 16 } as any);
  target.dispatchEvent(event);
};
const liftShiftKey = (target: Element) => {
  const event = new KeyboardEvent("keyup", { keyCode: 16 } as any);
  target.dispatchEvent(event);
};

const createPasteResults = (
  inputContent: Record<string, unknown>[],
  otherNotes?: Record<string, unknown>[],
) => ({
  type: "doc",
  content: [
    {
      type: "note",
      content: inputContent,
    },
    ...(otherNotes ? otherNotes : []),
  ],
});

const mockRange = () => {
  document.createRange = () => {
    const range = new Range();
    range.getBoundingClientRect = () => ({
      x: 0,
      y: 0,
      width: 0,
      height: 0,
      top: 0,
      right: 0,
      left: 0,
      bottom: 0,
      toJSON: () =>
        JSON.stringify({
          x: 0,
          y: 0,
          width: 0,
          height: 0,
          top: 0,
          right: 0,
          left: 0,
          bottom: 0,
        }),
    });
    range.getClientRects = () => {
      return {
        item: () => null,
        length: 0,
        [Symbol.iterator]: jest.fn(),
      };
    };
    return range;
  };
};
interface ClipboardEventMock extends Event {
  clipboardData: DataTransfer | null;
}

class DataTransfer {
  dropEffect: "none" | "copy" | "link" | "move";
  effectAllowed:
    | "none"
    | "copy"
    | "copyLink"
    | "copyMove"
    | "link"
    | "linkMove"
    | "move"
    | "all"
    | "uninitialized";
  readonly files: FileList = [] as any as FileList;
  readonly items: DataTransferItemList = [] as any as DataTransferItemList;
  readonly types: ReadonlyArray<string> = [];
  data: { [x: string]: string } = { dragX: "", dragY: "" };
  xOffset = 0;
  yOffset = 0;
  img: Element | null = null;
  constructor() {
    this.dropEffect = "none";
    this.effectAllowed = "all";
  }
  clearData() {
    this.data = {};
  }
  getData(format: string): string {
    return this.data[format];
  }
  setData(format: string, data: string) {
    this.data[format] = data;
  }
  setDragImage(image: Element, x: number, y: number) {
    this.img = image;
    this.xOffset = x;
    this.yOffset = y;
  }
}

const createClipboardEvent = (props: {
  dataType: "text/plain" | "text/html";
  data: string;
}): ClipboardEventMock => {
  const event = new Event("paste", {
    bubbles: true,
    cancelable: true,
  }) as ClipboardEventMock;
  event.clipboardData = new DataTransfer();
  event.clipboardData.setData(props.dataType, props.data);
  (event.clipboardData.items as any).push({
    data: props.data,
    type: props.dataType,
  } as any);
  return event;
};

const newPasteEvent = (
  view: ProsemirrorView.EditorView,
  props: { dataType: "text/plain" | "text/html"; data: string },
) => {
  const pasteEvent = createClipboardEvent({
    dataType: props.dataType,
    data: props.data,
  });

  const $p = view.state.doc.resolve(0);
  const slice = parseFromClipboard(
    view,
    props.dataType === "text/plain" ? props.data : "",
    props.dataType === "text/html" ? props.data : "",
    props.dataType === "text/plain",
    $p,
  );

  return { pasteEvent, slice };
};

/** namespace so we don't accidentally use it in production */
export const __clipboardTestingUtils = {
  pressShiftKey,
  liftShiftKey,
  createPasteResults,
  mockRange,
  newPasteEvent,
};
