import { noteFormatter, noteList } from "../model/services";
import { HashtagId, Note, NoteId, BlockToken } from "../model/types";
import { findInCollection, findInMap } from "./find";
import { NoteList } from "../model/noteList";
import { mapBlockTokens } from "../utils/tokenIterators/mapBlockTokens";
import { sortByRelevance } from "./Searcher";
import { FlatIndex } from "../model/ram/FlatIndex";
import { createQueryMatchers, relevance } from "./nlp";
import { BlockText } from "../model/noteFormatter";

// An "empty" note is defined as a note that does not contain text that can be
// displayed, including notes containing whitespace. Notes with empty bullet
// points and code blocks are empty.
function isNoteEmpty(note: Note): boolean {
  let isEmpty = true;
  const inlineTokenProcessor = (blockToken: BlockToken): void => {
    if (!isEmpty) return;
    for (const inlineToken of blockToken.content) {
      if (inlineToken.type === "text") {
        if (inlineToken.content.trim() !== "") {
          isEmpty = false;
        }
        continue;
      }
      // Non-"text" token types are considered non-empty always.
      isEmpty = false;
    }
    return;
  };

  note.tokens.forEach(mapBlockTokens(inlineTokenProcessor));
  return isEmpty;
}

function isNoteNotEmpty(note: Note): boolean {
  return !isNoteEmpty(note);
}
/**
 * Autocomplete queries return Suggestions instead of Notes.
 */
type SuggestionType = "at" | "spaceship" | "tilde" | "hashtag";

export interface Suggestion {
  id: HashtagId;
  content: string; // will be the line displayed, unless display is set.
  display?: React.ReactNode; // used for magic suggestions such as "create XXX", which have a display string different from the content they embed
  action?: "insert" | "create" | "create-and-expand";
}
const MAX_SUGGESTIONS = 20;

export class SuggestionSearcher {
  constructor(
    private noteService: NoteList,
    private textIndex: FlatIndex<string, BlockText[]>,
  ) {}
  suggests(
    text: string,
    type: SuggestionType,
    context: { noteId: NoteId },
  ): Suggestion[] {
    switch (type) {
      case "spaceship": {
        if (text === "") {
          // last N most recently updated notes that are not empty
          return (
            this.noteService
              .getAll()
              .sort(sortMostRecentNotes)
              .slice(0, MAX_SUGGESTIONS)
              // filter after slice because the filter is costly, and most of the
              // time, users do not keep many empty notes around
              .filter(isNoteNotEmpty)
              .map((note) => ({
                id: note.id,
                content: noteFormatter.getNotePreview(note.id),
              }))
          );
        }
        return this.notebyText(text, this.noteService.getAllAsMap());
      }
      case "hashtag": {
        // when the suggestion is only 1 char (#), we suggest the 10 most recent matches
        if (text === "") {
          return this.noteService
            .getLastUpdatedHashtags(context.noteId)
            .slice(0, MAX_SUGGESTIONS)
            .map((h) => ({
              id: h,
              content: h,
            }));
        }
        return this.hashtagByText(
          text,
          noteList.hashtags.getAllItems().map((h) => h[0].trim()),
        );
      }
      default:
        return [];
    }
  }

  private notebyText(
    text: string,
    repository: Map<string, Note>,
  ): Suggestion[] {
    return sortByRelevance(findInMap(repository, this.textIndex, text), text)
      .map((e) => e.entry)
      .slice(0, MAX_SUGGESTIONS)
      .map((note) => ({
        id: note.id,
        content: noteFormatter.getNotePreview(note.id),
      }));
  }

  /**
   * @param text the text to search for (no # prefix)
   * @param hashtags list of hashtags to search and sort (including # prefix)
   */
  private hashtagByText(text: string, hashtags: string[]): Suggestion[] {
    const queryMatchers = createQueryMatchers(text);
    return findInCollection(hashtags, text)
      .sort((a, b) => {
        // the slice removes the # prefix, which is not part of the query
        const relA = relevance([a.slice(1)], queryMatchers);
        const relB = relevance([b.slice(1)], queryMatchers);
        if (relA < relB) return 1;
        if (relA > relB) return -1;
        return 0;
      })
      .map((h) => ({
        id: h,
        content: h,
      })) // sort to be done
      .slice(0, MAX_SUGGESTIONS);
  }
}

function sortMostRecentNotes(a: Note, b: Note) {
  if (a.updatedAt < b.updatedAt) return 1;
  if (a.updatedAt > b.updatedAt) return -1;
  return 0;
}
