import { Selection } from "prosemirror-state";
import { schema } from "../../schema";
import { Command } from "prosemirror-state";
import {
  expandOrCollapseSpaceship,
  getSpaceshipPosFromExpandedNotePos,
} from "./referenceExpansionUtils";
import { noteList } from "../../../model/services";
import { findParent, findSelectionAtStartOfNote } from "../../utils/find";

/**
 * Delete the empty expansion of a reference.
 *
 * Only executes if the user is at the start of an empty expanded reference.
 * If the referenced note isn't referenced by any other notes, it deletes the note too.
 *
 * @param state
 * @param dispatch
 * @param view
 * @returns
 */
export const deleteExpandedReferenceCommand: Command = (
  state,
  dispatch,
  view,
) => {
  if (!dispatch || !view) return false;

  const { $from, empty } = state.selection;

  // must be at start of expansion
  const posExpansion = findParent(
    state.doc,
    $from.pos,
    (node) => node.type === schema.nodes.expandedReference,
  )[1];
  if (!posExpansion) return false;
  const sel = findSelectionAtStartOfNote(state.doc, $from.pos);
  if (!empty || $from.pos !== sel?.$from.pos) return false;

  // expansion must be empty (or all whitespace)
  const posNote = posExpansion + 1;
  const note = state.doc.nodeAt(posNote);
  if (note?.type !== schema.nodes.note) throw new Error("Expected note node");
  if (!note || note.childCount > 1) return false;
  const paragraph = note.firstChild;
  if (
    !paragraph ||
    paragraph.childCount > 1 ||
    !paragraph.textContent.match(/^\s*$/)
  ) {
    return false;
  }

  // expansion can't have other backlinks to it
  const noteId = note.attrs.noteId;
  const numRefs = noteList.backlinks.getNoteCountForItem(noteId);
  if (numRefs > 1) {
    // if there are back references, we can't delete the expansion
    alert("can't delete this note, it has existing backlinks");
    return false;
  }

  // Everything looks good, time to delete the expansion
  const tr = state.tr;

  // Get position to place selection after delete
  const posSpaceship = getSpaceshipPosFromExpandedNotePos(
    state.doc,
    posExpansion,
  );
  const $posExpansion = state.doc.resolve(posExpansion);
  const nodeBefore = $posExpansion.nodeBefore;
  let posSelection;
  if (nodeBefore && nodeBefore.type === schema.nodes.expandedReference) {
    // -1 is end of prev expansion, -2 is end of note, -3 is end of paragraph
    posSelection = $posExpansion.pos - 3;
  } else {
    posSelection = posSpaceship + 1;
  }

  // delete expansion and spaceship
  expandOrCollapseSpaceship(tr, tr.doc.resolve(posSpaceship));
  tr.delete(posSpaceship, posSpaceship + 1);
  tr.setSelection(Selection.near(tr.doc.resolve(tr.mapping.map(posSelection))));

  // delete note
  let deleted = false;
  tr.doc.forEach((node, pos) => {
    if (deleted) return false;
    if (node.attrs.noteId === noteId && node.attrs.depth === 0) {
      tr.delete(pos, pos + node.nodeSize);
      deleted = true;
    }
  });

  dispatch(tr);
  return true;
};
