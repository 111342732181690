import { useEffect } from "react";
import { useAuth } from "../auth/useAuth";
import { trackEvent } from "./analyticsHandlers";

export default function useAnalytics() {
  const { user } = useAuth();

  useEffect(() => {
    if (!user) return;
    if (user.email === null) return;
    trackEvent(["SESSION", "STARTED"]);
  }, [user]);

  useEffect(() => {
    if (typeof window === "undefined") return;
    const trackFocus = () => trackEvent(["WINDOW", "FOCUSED"]);
    const trackBlur = () => trackEvent(["WINDOW", "BLURRED"]);
    window.addEventListener("focus", trackFocus);
    window.addEventListener("blur", trackBlur);
    return () => {
      window.removeEventListener("focus", trackFocus);
      window.removeEventListener("blur", trackBlur);
    };
  }, []);
}
