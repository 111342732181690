import { assertUnreachable } from "../utils/assertUnreachable";
import { NoteMap } from "./services";
import {
  NoteId,
  InlineToken,
  BlockToken,
  ParagraphToken,
  CodeblockToken,
  ListToken,
  TokenId,
} from "./types";

const ELLIPSIZE_TRUNCATE_AT = 40;
const BLANK_LINE_SKIP_BUFFER = 5;

export type BlockText = { tokenId: TokenId; text: string };

export class NoteFormatter {
  constructor(private noteMap: NoteMap) {}

  private expandLines = (
    tokens: BlockToken[],
    lines: number,
    shouldExpandSpaceship: boolean,
  ): string => {
    // We want to skip returning blank lines, but want to avoid paying the perf
    // cost of expanding every line of every note for every spaceship. So we
    // serialize an additional "buffer" of lines, and trim down blank lines at
    // the end.
    const targetTokens = tokens.slice(0, lines + BLANK_LINE_SKIP_BUFFER);
    const result = this.getNoteAsStrings(targetTokens, shouldExpandSpaceship)
      .slice(0, lines)
      .filter((line) => line.length > 0)
      .join(" ");

    if (result.trim() === "") {
      if (lines === Infinity) return "(empty note)";
      return "...";
    }

    return result;
  };

  /**
   * Like {@link getNoteAsBlockTexts}, but returns strings instead of {@link BlockText}s.
   * To get something to display to the user, use getNotePreview instead.
   */
  getNoteAsStrings(
    targetTokens: BlockToken[],
    shouldExpandSpaceship = true,
    breakOnFirstNonEmptyLine = false,
  ): string[] {
    return this.getNoteAsBlockTexts(
      targetTokens,
      shouldExpandSpaceship,
      breakOnFirstNonEmptyLine,
    ).map((blockText) => blockText.text);
  }

  /**
   * Returns a plain text version of the note suitable for indexing for search.
   * Skips all spaceships and does not include token prefixes.
   *
   * @param targetTokens - the tokens to get the string for
   * @param shouldExpandSpaceship - whether to expand out the first level of spaceships
   * @param lines - the number of lines to return. Defaults to Infinity.
   */
  getNoteAsBlockTexts(
    targetTokens: BlockToken[],
    shouldExpandSpaceship = true,
    breakOnFirstNonEmptyLine = false,
  ): BlockText[] {
    let output: BlockText[] = [];
    const process = (
      blockToken: ParagraphToken | CodeblockToken | ListToken,
    ): boolean => {
      if (blockToken.type === "list") {
        output = [
          ...output,
          ...this.getNoteAsBlockTexts(
            blockToken.content.map((li) => li.content).flat(),
            shouldExpandSpaceship,
            breakOnFirstNonEmptyLine,
          ),
        ];
      } else {
        const p = blockToken.content.map((token: InlineToken) => {
          switch (token.type) {
            case "text":
            case "link":
            case "hashtag": {
              return token.content.replace(/\n/g, " ");
            }
            case "checkbox":
              return token.isChecked ? "[x] " : "[ ] ";
            case "image":
              return "(image)";
            case "spaceship": {
              if (!shouldExpandSpaceship) return "[...]";
              else {
                return this.getNoteEllipsis(token.linkedNoteId, false);
              }
            }
            case "linkloader":
              return "";
            default:
              assertUnreachable(token);
              throw new Error("Could not index token " + JSON.stringify(token));
          }
        });
        const line = p.join("");
        output.push({ tokenId: blockToken.tokenId, text: line });
      }
      const lastLine = output[output.length - 1].text || "";
      return breakOnFirstNonEmptyLine && lastLine.trim().length > 0;
    };

    targetTokens.find(process);
    return output;
  }

  /**
   * Returns a plain text representation of the start of the note suitable for
   * displaying in places where we need a short, one-line summary of the note,
   * like inside a spaceship or in the document.title.
   */
  getNoteEllipsis(
    noteId: NoteId,
    shouldExpandSpaceship = true,
    maxLengthOverride?: number,
  ): string {
    const note = this.noteMap.get(noteId);
    if (note && !note.deletedAt) {
      const summary = this.getNoteAsStrings(
        note.tokens,
        shouldExpandSpaceship,
        true,
      );
      const firstNonEmptyLine = summary[summary.length - 1] || "";
      return truncate(
        firstNonEmptyLine.length > 0 ? firstNonEmptyLine : "(empty note)",
        maxLengthOverride ?? ELLIPSIZE_TRUNCATE_AT,
      );
    } else {
      return "(missing note)";
    }
  }

  /**
   * Returns a plain text, human-readable version of the note for when the note
   * needs to be displayed in plain text, but remain human readable. Expands out
   * 1 level of spaceships and keeps token prefixes.  For indexing and search,
   * use getNoteIndexString instead.
   */
  getNotePreview(noteId: NoteId): string {
    const note = this.noteMap.get(noteId);
    if (note && !note.deletedAt) {
      return this.expandLines(note.tokens, Infinity, true);
    } else {
      return "deleted note";
    }
  }
}

function truncate(str: string, maxSize: number): string {
  return str.length > maxSize ? str.substr(0, maxSize - 1) + "..." : str;
}
