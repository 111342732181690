import { EditorView } from "prosemirror-view";
import { EditorState, Selection } from "prosemirror-state";
import { deleteSelection } from "prosemirror-commands";

export function backspaceNoteCommand(
  state: EditorState,
  dispatch?: EditorView["dispatch"],
): boolean {
  if (dispatch == null) return false;

  // If the selection ends at the start of a note, only delete up to but not
  // including the last position. Without this tweak, selecting a paragraph and
  // deleting results in the merge of two notes, which users find frustrating.
  const { $from, $to } = state.selection;
  const isRangeSelection = $from.pos !== $to.pos;
  const endsAtStartOfNote = $to.pos === $to.start(1) + 1;
  if (isRangeSelection && endsAtStartOfNote) {
    const tr = state.tr;

    // Selection.$to that corrects for selection end extending into the next note
    const intendedSelectionEnd = Selection.findFrom(
      tr.doc.resolve($to.pos - 1),
      -1,
    );
    if (!intendedSelectionEnd) return false;

    tr.delete($from.pos, intendedSelectionEnd.to);

    // attempt to find a suitable cursor position after the deletion
    const afterDeleteSelection = Selection.findFrom(
      tr.doc.resolve(tr.mapping.map($from.pos, -1)),
      -1,
    );
    if (!afterDeleteSelection) return false;

    dispatch(tr.setSelection(afterDeleteSelection));
    return true;
  }

  return deleteSelection(state, dispatch);
}
