import { Searcher } from "../search/Searcher";
import { SearchQuery } from "../search/SearchQuery";
import { noteFormatter, NoteMap } from "../model/services";
import { currentVersion } from "./currentVersion";

function toObj(map: Map<any, any>) {
  const out: any = {};
  map.forEach((value: any, key) => {
    out[key] = value;
  });
  return out;
}

export class ObjectExporter {
  constructor(private searchService: Searcher, private noteMap: NoteMap) {}

  export() {
    return {
      version: currentVersion,
      notes: toObj(this.noteMap),
    };
  }
  downloadObjectAsJson(exportObj: any, exportName: string) {
    const dataStr =
      "data:text/json;charset=utf-8," +
      encodeURIComponent(JSON.stringify(exportObj));
    const downloadAnchorNode = document.createElement("a");
    downloadAnchorNode.setAttribute("href", dataStr);
    downloadAnchorNode.setAttribute("download", exportName + ".if");
    document.body.appendChild(downloadAnchorNode); // required for firefox
    downloadAnchorNode.click();
    downloadAnchorNode.remove();
  }

  async exportSearch(search: SearchQuery) {
    const { notes } = this.searchService.searchNotes(search);
    const allNotes = notes.map((hit) =>
      noteFormatter.getNotePreview(hit.entry.id),
    );
    return allNotes.join("\n--\n");
  }
}
