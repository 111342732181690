import { Command } from "prosemirror-state";

export const removeEmptyCodeBlock: Command = (state, dispatch, view) => {
  if (!dispatch || !view) return false;

  const { $from, $to } = state.selection;
  if (
    $from.parent.type === state.schema.nodes.codeblock &&
    $from.pos === $from.start($from.depth) &&
    $to.pos === $to.end($to.depth)
  ) {
    dispatch(
      state.tr.setNodeMarkup($from.pos - 1, state.schema.nodes.paragraph),
    );
    return true;
  }

  return false;
};
