import React from "react";
import { useAtomValue } from "jotai";
import { modalAtom } from "./modalAtom";

export function ModalRoot({ children }: any) {
  const currentModal = useAtomValue(modalAtom);
  const modalJsx = children(currentModal);

  return <div id="modal">{modalJsx ? modalJsx : null}</div>;
}
