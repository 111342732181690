import { generateId } from "../../model/generateId";
import { noteList } from "../../model/services";
import { BlockToken, UserId } from "../../model/types";
import { ImportableNote, deduplicate } from "./importTracking";
import { ImportStatus } from "../ImportModal";
import { notePositions } from "../../model/services";
import { trackEvent } from "../../analytics/analyticsHandlers";

export async function importFromPlainText(
  plainText: string,
  userId: UserId,
  setImportingMessage: (m: string) => void,
  next: (n: ImportStatus) => void,
) {
  const notes: ImportableNote[] = [];
  setImportingMessage(`Trying to import plain text notes...`);

  // split on lines that are only dashes
  const lines = plainText
    .split(/\n(-|—)+\n/gm)
    .filter((l) => !l.match(/^(-|—)+$/));

  const importBatch = new Date().toLocaleString();
  const positions = notePositions.generateFirst(lines.length);
  for (let i = 0; i < lines.length; i++) {
    const line = lines[i];
    const tokens = tokenizeNote(line);
    notes.push({
      tokens,
      importSource: "plainText",
      importForeignId: fastHash(line) + "_" + String(i),
      importBatch,
      position: positions[i],
    });
  }

  noteList.insert(notes);

  setImportingMessage(`Successfully imported ${notes.length} new notes`);
  trackEvent(["IMPORT", "PLAIN_TEXT"]);

  return next(ImportStatus.Imported);
}

/**
 * Imports a legacy iOS file.
 *
 * Does support links, hashtags. Does not support @ and <>.
 *
 * The main challenges with this format:
 * - mandatory fields are often empty for no particular reason
 * - updated at often equals to created at
 * - free text, no tokenization
 * - no id
 *
 * I surrogate an id based on the updated at time and note content.
 *
 * Parsing is primitive but could be reused for a variety of imports, as it doesnt require any particular library to tokenize.
 *
 */
export async function importFromLegacy(
  filename: string,
  iosNotes: any,
  userId: UserId,
  setImportingMessage: (m: string) => void,
  next: (n: ImportStatus) => void,
) {
  const notes: ImportableNote[] = [];
  setImportingMessage(
    `Trying to import ${iosNotes.length.toLocaleString()} notes...`,
  );
  const errors = { noDate: 0, invalidDate: 0, noNote: 0 };
  for (let i = 0; i < iosNotes.length; i++) {
    const curr = iosNotes[i];

    // createdAt present?
    if (!curr.created) {
      errors.noDate++;
      continue;
    }
    // createdAt valid?
    if (!Number.isInteger(Date.parse(curr.created))) {
      errors.invalidDate++;
      continue;
    }
    const createdAt = new Date(Date.parse(curr.created));

    // updatedAt present and valid? Otherwise reuse createdAt.
    let updatedAt;
    if (!curr.updated || !Number.isInteger(Date.parse(curr.updated))) {
      updatedAt = createdAt;
    } else {
      updatedAt = new Date(Date.parse(curr.updated))!;
    }

    // if no text, consider the note as moot
    let note = curr.note;
    if (!note) {
      errors.noNote++;
      continue;
    }
    // fix some escaping errors done in the json export lib used
    note = note.replace("/", "/");
    note = note.replace("\r", "\n");
    const tokens = tokenizeNote(note);

    // build the best id we can out of this broken export.
    const importForeignId = createdAt
      ? filename + "_" + fastHash(note + createdAt)
      : filename + "_" + fastHash(note) + "_" + String(i);

    notes.push({
      tokens,
      createdAt,
      updatedAt,
      importSource: "legacy",
      importBatch: filename,
      importForeignId,
    });
  }

  const filteredNotes: ImportableNote[] = deduplicate(notes, noteList.getAll())
    .filter((n) => n !== null)
    .reverse(); // notes are provided most recent first, so we need to reverse the list to keep the order.
  if (filteredNotes.length === 0) {
    return next(ImportStatus.NoNewNote);
  }
  setImportingMessage(
    `Found ${filteredNotes.length.toLocaleString()} valid notes to import...`,
  );

  if (notes) {
    filteredNotes.forEach((n) => noteList.insert(n));
    setImportingMessage(
      `Imported ${filteredNotes.length.toLocaleString()} notes successfully. Import done!`,
    );
    return next(ImportStatus.Imported);
  }
}

// Parsing
//
// I had to use different regexp than the rest of the app is using.
const hashtag = /(#[\w\x2d+*]*[A-Za-z_\x2d+*][\w\x2d+*]*)/g;
const link = /(https?:\/\/[a-zA-Z0-9./#+]+)/g;

function tokenizeNote(note: string): BlockToken[] {
  return note.split("\n").map(tokenizeLine);
}

function tokenizeLine(line: string): BlockToken {
  const splitted = line
    .split(hashtag)
    .flatMap((s) => s.split(link))
    .filter((b) => b !== "");
  return {
    type: "paragraph",
    tokenId: generateId(),
    content: splitted.map((token: string) => {
      if (token.match(hashtag)) {
        return {
          type: "hashtag",
          content: token,
        };
      } else if (token.match(link)) {
        return {
          type: "link",
          content: token,
          slug: token,
        };
      } else {
        return { type: "text", content: token, marks: [] };
      }
    }),
  };
}

/**
 * Used to rebuild valid ids out of free text.
 *
 * https://gist.github.com/jlevy/c246006675becc446360a798e2b2d781
 * @param str
 * @returns
 */
function fastHash(str: string) {
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    const char = str.charCodeAt(i);
    hash = (hash << 5) - hash + char;
    hash &= hash; // Convert to 32bit integer
  }
  return new Uint32Array([hash])[0].toString(36);
}
