// We use the var is so we can easily switch to dark mode and
// to make the dev experience better when doing styling inside browser DevTools
export const colors = {
  /** on ios safari, we need transparent to match the main background color
   * for gradients to be the expected color */
  transparent: "var(--transparent)",

  /**Search highlight */
  highlight: "var(--color-bg-highlight)",

  text: {
    /** Bolder color for headings > 22px in size */
    strong: "var(--color-text-strong)",
    /** Main text color */
    primary: "var(--color-text-primary)",
    /** Use for secondary interactive elements */
    secondary: "var(--color-text-secondary)",
    /** For input placeholders and disabled states */
    tertiary: "var(--color-text-tertiary)",
    /** Text color using our accent color */
    accent: "var(--color-text-accent)",
    /** Text color for positive messages */
    positive: "var(--color-text-positive)",
    /** Text color for negative messages */
    negative: "var(--color-text-negative)",
    /** Text color for search result highlights */
    searchHighlight: "var(--color-text-searchHighlight)",
  },

  bg: {
    /** Main editor background color */
    primary: "var(--color-bg-primary)",
    /** For elevated UI elements */
    secondary: "var(--color-bg-secondary)",
    /** Used in buttons and othe raised UI elements */
    tertiary: "var(--color-bg-tertiary)",
    /** For backgrounds that need to appear below main BG - mostly code blocks */
    sub: "var(--color-bg-sub)",

    ok: "var(--color-bg-ok)",
    warning: "var(--color-bg-warning)",
    error: "var(--color-bg-error)",

    accent: {
      /** For fills, shapes and icons  */
      primary: "var(--color-bg-accent-primary)",
      /** For subtler fills, shapes and icons  */
      secondary: "var(--color-bg-accent-secondary)",
      /** Mostly for hover states */
      tertiary: "var(--color-bg-accent-tertiary)",
      /** For use as a background color */
      quaternary: "var(--color-bg-accent-quaternary)",
    },
    positive: {
      /** For fills, shapes and icons  */
      primary: "var(--color-bg-positive-primary)",
      /** Mostly for hover states */
      secondary: "var(--color-bg-positive-secondary)",
      /** For use as a background color */
      tertiary: "var(--color-bg-positive-tertiary)",
    },
    negative: {
      /** For fills, shapes and icons  */
      primary: "var(--color-bg-negative-primary)",
      /** Mostly for hover states */
      secondary: "var(--color-bg-negative-secondary)",
      /** For use as a background color */
      tertiary: "var(--color-bg-negative-tertiary)",
    },
  },
  border: {
    /** Note dividers only */
    primary: "var(--color-border-primary)",
    /** Lighter border for UI dividers, solid color */
    secondary: "var(--color-border-secondary)",
    /** Lighter border for UI dividers, with transparency */
    secondarySolid: "var(--color-border-secondary-solid)",
    alert: "var(--color-border-alert)",
  },
};

export const shadows = {
  light: "var(--shadow-light)",
  medium: "var(--shadow-medium)",
  heavy: "var(--shadow-heavy)",
};

export const transitions = {
  light: "var(--transition-light)",
  medium: "var(--transition-medium)",
  heavy: "var(--transition-heavy)",
};

export const radii = {
  small: "var(--radius-small)",
  medium: "var(--radius-medium)",
  large: "var(--radius-large)",
};

export const mediumWidth = 1350;
export const mobileWidth = 820;

// some media queries are used directly in the files, you will have to search and replace on the value.
export const mediaQueries = {
  mobile: `@media only screen and (max-width: ${mobileWidth}px)`,
  medium: `@media only screen and (max-width: ${mediumWidth}px)`,
};
