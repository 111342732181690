import { Persistable } from "../types";

export class ElementList<Id, Element extends Persistable> {
  constructor(private elements: Map<Id, Element>) {}

  getAll(withDeleted = false) {
    if (withDeleted) return [...this.elements.values()];
    else return [...this.elements.values()].filter((e) => !e.deletedAt);
  }

  getAllAsMap() {
    return this.elements;
  }

  has(id: Id) {
    const element = this.elements.get(id);
    return element && !element.deletedAt;
  }

  hasOrHad(id: Id) {
    return this.elements.has(id);
  }

  get(id: Id) {
    return this.elements.get(id);
  }
}
