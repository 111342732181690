import { Plugin, PluginKey } from "prosemirror-state";
import { Decoration, DecorationSet, EditorView } from "prosemirror-view";
import { notesInError } from "../../../model/sync/notesInError";
import { NoteId } from "../../../model/types";
import { descendNotes } from "../../utils/descendNotes";

const createElement = () => {
  return () => {
    const d = document.createElement("div");
    d.className = "dot";
    return d;
  };
};
// need to refresh when notes in error change.
export const syncMarkPlugin = new Plugin<NoteId[]>({
  key: new PluginKey("syncMarkPlugin"),
  view: (editorView: EditorView) => {
    view = editorView;
    return {};
  },
  props: {
    decorations(state) {
      const decorations: Decoration[] = [];
      descendNotes(state.doc, (node, pos) => {
        if (notesInError.includes(node.attrs.noteId)) {
          decorations.push(
            Decoration.widget(pos + node.nodeSize - 1, createElement()),
          );
        }
      });
      return DecorationSet.create(state.doc, decorations);
    },
  },
});

// wont work with 2 editors
let view: EditorView | null = null;

export const refreshSyncMark = () => {
  if (!view) return;
  else view.dispatch(view.state.tr);
};
