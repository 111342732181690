import { EditorView } from "prosemirror-view";
import { EditorState } from "prosemirror-state";
import { findParent } from "../../utils/find";
import { schema } from "../../schema";

// Pressing backspace should reveal some ellipsis since they are being edited
export function backspaceEllipsisReveal(
  state: EditorState,
  dispatch?: EditorView["dispatch"],
) {
  if (dispatch == null) return false;

  // If the previous node is an ellipsis
  const { nodeBefore } = state.doc.resolve(state.selection.$anchor.pos - 1);
  if (nodeBefore?.type !== schema.nodes.ellipsisContainer) return false;

  const tr = state.tr;
  const [note, pos] = findParent(
    tr.doc,
    tr.selection.$anchor.pos,
    (n) => n.type === schema.nodes.note,
  );
  if (!note || note.attrs.isExpanded) {
    return false;
  }
  // toggle it
  tr.setNodeMarkup(pos, null, {
    ...note.attrs,
    isExpanded: true,
  });
  dispatch(tr);

  return true;
}
