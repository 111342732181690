import { generateId } from "./../model/generateId";
import {
  ParagraphToken,
  InlineToken,
  SecondaryMark,
  CodeblockToken,
  ListToken,
  ListItemToken,
  BlockToken,
  Note,
} from "../model/types";
import { schema } from "./schema";
import { Node, Fragment, Mark } from "prosemirror-model";
import { noteFormatter, noteList } from "../model/services";
import { assertUnreachable } from "../utils/assertUnreachable";
import { getDepthFromPath, Path } from "./utils/path";
import { Hit } from "../search/find";
import { joinCompatibleAdjacentInlineTokens } from "./joinCompatibleAdjacentInlineTokens";
import { removeMark } from "./utils/mark/removeMark";
import { join as joinPaths } from "path";
import { TokenId } from "../model/types";
import { isProd } from "../utils/environment";
export type GetAttrs = (n: Note) => { [k: string]: any };

// ------------ Tokens -> PM Nodes ------------

export function notesToProsemirrorNodes(
  results: Hit<Note>[],
  isCondensed: boolean,
  path: Path = "/", // path is used to detect circular dependencies, and thats about it.
  getAttrs: GetAttrs = () => ({}),
): Node[] {
  return results.map((r) => {
    const matches = "matches" in r && isCondensed ? r.matches || [] : [];
    return noteToProsemirrorNode(r.entry, path, getAttrs, matches);
  });
}

export function noteToProsemirrorNode(
  note: Note,
  path: Path = "/",
  getAttrs: GetAttrs = () => ({}),
  matches: TokenId[] = [],
): Node {
  const nodes: Node[] = [];
  const shouldCondense = matches.length > 0;
  let hasFoundMatch = false;
  const firstNonEmptyLine = noteFormatter
    .getNoteAsStrings(note.tokens, false, true)
    .findIndex((line) => line.trim() !== "");
  /** Adjacent non-matching nodes*/
  let prevNonMatches: Node[] = [];
  function processPrevNonMatches(): Node[] {
    const isSingleEmptyParagraph =
      prevNonMatches.length === 1 && prevNonMatches[0].childCount === 0;
    const res =
      shouldCondense && !isSingleEmptyParagraph
        ? condenseFragment(prevNonMatches)
        : prevNonMatches;
    prevNonMatches = [];
    return res;
  }

  note.tokens.forEach((token, i) => {
    const isFirstNonEmptyLine = i === firstNonEmptyLine;
    switch (token.type) {
      case "codeblock":
      case "paragraph": {
        const { node, hasMatch } = blockTokenToProsemirrorNode(token, matches);
        hasFoundMatch = hasFoundMatch || hasMatch;
        if (hasMatch || isFirstNonEmptyLine) {
          nodes.push(...processPrevNonMatches(), node);
        } else {
          prevNonMatches.push(node);
        }
        break;
      }
      case "list": {
        const { node, hasMatch } = listToProsemirrorNode(token, matches);
        hasFoundMatch = hasFoundMatch || hasMatch;
        if (hasMatch) {
          // if the list contains a match, don't condense it's parent
          const parent = prevNonMatches.pop();
          nodes.push(...processPrevNonMatches());
          if (parent) nodes.push(parent);
          nodes.push(node);
        } else {
          prevNonMatches.push(node);
        }
        break;
      }
      default:
        assertUnreachable(token);
        throw new Error(`invalid blockToken type '${(token as any).type}'`);
    }
  });
  nodes.push(...processPrevNonMatches());

  const notePath = joinPaths(path, note.id);
  return schema.nodes.note.create(
    {
      noteId: note.id,
      path: notePath,
      depth: getDepthFromPath(notePath),
      highlighted: false,
      folderId: note.folderId,
      insertedAt: note.insertedAt,
      isCondensable: shouldCondense,
      ...getAttrs(note),
    },
    nodes,
  );
}

const listToProsemirrorNode: TokenToPMNode<ListToken> = (
  token,
  matches = [],
) => {
  const nodes: Node[] = [];
  const shouldCondense = matches.length > 0;
  let hasFoundMatch = false;
  /** Adjacent non-matching nodes*/
  let prevNonMatches: Node[] = [];
  /**
   * Condense adjacent non-matches together
   * (excluding ancestors of the match which are left as-is).
   * @param depth the depth of the current match
   */
  function processPrevNonMatches(depth = -1): Node[] {
    const result: Node[] = [];
    if (!shouldCondense) {
      result.push(...prevNonMatches);
      prevNonMatches = [];
      return result;
    }

    /** Used to collect adjacent nodes that will be condensed together*/
    let toCondense: Node[] = [];
    /** Condense nodes in {@link toCondense} into an ellipses node*/
    function processToCondense(): Node[] {
      toCondense.reverse(); // we're processing in reverse order, so reverse it back
      // The depth of the condensed ellipsis is set to the depth of the first
      // node in the series.
      const firstDepth = toCondense[0]?.attrs?.depth;
      const depth = typeof firstDepth === "number" ? firstDepth : 0;
      const result = condenseFragment(toCondense, { depth, listItem: true });
      toCondense = [];
      return result;
    }

    for (let i = prevNonMatches.length - 1; i >= 0; i--) {
      const node = prevNonMatches[i];
      if (node.attrs.depth < depth) {
        // If we hit a node at a lower depth than the last non-condensed node,
        // then we've found an ancestor of a match. Condense all the nodes up
        // to it and then add the ancestor to the list of nodes without condensing.
        result.push(...processToCondense(), node);
        depth = node.attrs.depth;
      } else {
        toCondense.push(node);
      }
    }
    result.push(...processToCondense());
    prevNonMatches = [];
    return result.reverse(); // reverse because we processed in reverse order
  }

  for (const listItemToken of token.content) {
    const { node, hasMatch } = listItemToProsemirrorNode(
      listItemToken,
      matches,
    );
    if (hasMatch) {
      nodes.push(...processPrevNonMatches(listItemToken.depth), node);
    } else {
      prevNonMatches.push(node);
    }
    hasFoundMatch = hasFoundMatch || hasMatch;
  }
  nodes.push(...processPrevNonMatches());

  return {
    node: schema.nodes.bulletList.create(undefined, nodes),
    hasMatch: hasFoundMatch,
  };
};

const listItemToProsemirrorNode: TokenToPMNode<ListItemToken> = (
  token,
  matches = [],
  firstBlockNum = 0,
) => {
  const nodes: Node[] = [];
  const shouldCondense = matches.length > 0;
  let hasFoundMatch = false;
  /** Adjacent non-matching nodes*/
  let prevNonMatches: Node[] = [];
  function processPrevNonMatches(): Node[] {
    const result: Node[] = [];
    if (
      !shouldCondense ||
      prevNonMatches.length === 0 ||
      prevNonMatches.length === token.content.length
    ) {
      result.push(...prevNonMatches);
      prevNonMatches = [];
      return result;
    }
    const ellipses = schema.nodes.ellipsisContainer.create(null, [
      schema.nodes.ellipsisDot.create(null, null),
      schema.nodes.ellipsis.create(null, prevNonMatches),
    ]);
    prevNonMatches = [];
    return [ellipses];
  }

  for (const blockToken of token.content) {
    const { node, hasMatch } = blockTokenToProsemirrorNode(blockToken, matches);
    if (hasMatch) {
      nodes.push(...processPrevNonMatches(), node);
    } else {
      prevNonMatches.push(node);
    }
    hasFoundMatch = hasFoundMatch || hasMatch;
  }
  nodes.push(...processPrevNonMatches());

  return {
    node: schema.nodes.listItem.create(
      { depth: token.depth, id: generateId() },
      nodes,
    ),
    hasMatch: hasFoundMatch,
  };
};

const blockTokenToProsemirrorNode: TokenToPMNode<BlockToken> = (
  token,
  matches = [],
) => {
  let node;
  if (token.type === "paragraph") {
    node = schema.nodes.paragraph.create(
      { tokenId: token.tokenId || generateId() },
      token.content
        .map((token) => inlineTokenToProseMirrorNode(token))
        .filter((token) => token != null) as Node[],
    );
  } else if (token.type === "codeblock") {
    node = schema.nodes.codeblock.create(
      { tokenId: token.tokenId || generateId() },
      token.content
        .map((token) => inlineTokenToProseMirrorNode(token))
        .filter((token) => token != null) as Node[],
    );
  } else {
    throw new Error("unknown block token type");
  }
  return {
    node,
    hasMatch: matches.includes(token.tokenId),
  };
};

function generateMarkArray(marks: SecondaryMark[]): Mark[] {
  if (!marks) return [];
  const marksArr: Mark[] = [];
  marks.forEach((mark) => {
    if (mark === "italic") marksArr.push(schema.marks.italic.create());
    if (mark === "bold") marksArr.push(schema.marks.bold.create());
    if (mark === "underline") marksArr.push(schema.marks.underline.create());
    if (mark === "strikethrough")
      marksArr.push(schema.marks.strikethrough.create());
  });
  return marksArr;
}

function inlineTokenToProseMirrorNode(token: InlineToken): Node | null {
  try {
    switch (token.type) {
      case "text":
        return schema.text(
          token.content === "" ? " " : token.content,
          generateMarkArray(token.marks),
        );
      case "image":
        return schema.nodes.image.create({
          ...token,
        });
      case "checkbox":
        return schema.nodes.checkbox.create({ isChecked: token.isChecked });
      case "spaceship":
        return schema.nodes.reference.create(
          {
            tokenId: token.tokenId || generateId(),
            linkedNoteId: token.linkedNoteId,
            content: noteFormatter.getNoteEllipsis(token.linkedNoteId),
            isValid: noteList.has(token.linkedNoteId),
          },
          undefined,
        );
      case "hashtag":
        return schema.text(token.content, [schema.marks.hashtag.create()]);
      case "link":
        return schema.text(token.content, [
          schema.marks.link.create({ content: token.content }),
        ]);
      case "linkloader":
        return schema.nodes.linkLoader.create({
          url: token.url,
          title: token.title,
          description: token.description,
          image: token.image,
        });
      default:
        assertUnreachable(token);
        throw new Error("Unknown node type");
    }
  } catch (e) {
    console.warn(token, "could not be processed");
    if (!isProd) throw e;
  }
  return null;
}

function condenseFragment(
  nodes: Node[],
  attrs: { depth?: number; listItem?: boolean } | null = null,
): Node[] {
  const result: Node[] = [];
  if (nodes.length > 0) {
    const node = schema.nodes.ellipsisContainer.create(null, [
      schema.nodes.ellipsisDot.create(attrs, null),
      schema.nodes.ellipsis.create(null, nodes),
    ]);
    result.push(node);
  }
  return result;
}

/**
 * Converts a prosemirror node to a list of tokens
 * @param token The prosemirror node to convert
 * @param matches The indices of the block's containing a match
 * @param firstBlockNum The index of the first block in the token
 */
type TokenToPMNode<T> = (
  token: T,
  matches: TokenId[],
) => {
  node: Node;
  hasMatch: boolean;
};

// ------------ PM Nodes -> Tokens ------------

export function getTopLevelTokensFromContent(fragment: Fragment): BlockToken[] {
  fragment = removeMark(fragment, schema.marks.highlight);

  const result: BlockToken[] = [];
  fragment.forEach((node) => {
    switch (node.type) {
      case schema.nodes.codeblock:
      case schema.nodes.paragraph: {
        const block = getBlockTokensFromContent(node);
        if (block) result.push(block);
        break;
      }
      case schema.nodes.bulletList: {
        const content: ListItemToken[] = [];
        node.forEach((child) => {
          const listItemNodes: Node[] = [];
          switch (child.type) {
            case schema.nodes.listItem:
              listItemNodes.push(child);
              break;
            case schema.nodes.ellipsisContainer:
              child.lastChild?.forEach((node) => {
                listItemNodes.push(node);
              });
              break;
            default:
              throw new Error(`Invalid list item type ${child.type.name}`);
          }
          listItemNodes.forEach((listItemNode) => {
            const blockContent: BlockToken[] = [];
            listItemNode.forEach((child) => {
              const blockTokens = [];
              if (child.type === schema.nodes.ellipsisContainer) {
                child.lastChild?.forEach((node) => {
                  blockTokens.push(node);
                });
              } else {
                blockTokens.push(child);
              }
              blockTokens.forEach((blockToken) => {
                const block = getBlockTokensFromContent(blockToken);
                if (block) blockContent.push(block);
              });
            });
            const depth = parseInt(listItemNode.attrs.depth);
            const token: ListItemToken = {
              type: "listItem",
              content: blockContent,
              depth: isNaN(depth) ? 0 : depth,
            };
            content.push(token);
          });
        });
        const token: ListToken = {
          type: "list",
          content,
        };
        result.push(token);
        break;
      }
      case schema.nodes.expandedReference:
        break;
      case schema.nodes.backlinks:
        break;
      case schema.nodes.ellipsisContainer:
        result.push(
          ...getTopLevelTokensFromContent(node.content.child(1)!.content), // container [..., ellipsis [ real content ] ]
        );
        break;
      default:
        throw new Error(`Unknown topLevelToken node type '${node.type.name}'`);
    }
  });
  return result;
}

function getBlockTokensFromContent(node: Node): BlockToken | null {
  switch (node.type) {
    case schema.nodes.paragraph: {
      const content: ParagraphToken["content"] = [];
      node.forEach((n, i) => {
        content.push(inlineNodeToInlineToken(n));
      });
      return {
        tokenId: node.attrs.tokenId,
        type: "paragraph",
        content: joinCompatibleAdjacentInlineTokens(content),
      };
    }
    case schema.nodes.codeblock: {
      const content: CodeblockToken["content"] = [];
      node.forEach((n) =>
        content.push(
          inlineNodeToInlineToken(n) as CodeblockToken["content"][0],
        ),
      );
      return {
        tokenId: node.attrs.tokenId,
        type: "codeblock",
        content,
      };
    }
    case schema.nodes.expandedReference:
      return null;
    default:
      throw new Error(`Unknown blockNode type '${node.type.name}'`);
  }
}

function getSecondaryMarks(marks: readonly Mark[]): SecondaryMark[] {
  const marksLst: SecondaryMark[] = [];
  marks.forEach((mark) => {
    switch (mark.type) {
      case schema.marks.italic:
        marksLst.push("italic");
        break;
      case schema.marks.bold:
        marksLst.push("bold");
        break;
      case schema.marks.underline:
        marksLst.push("underline");
        break;
      case schema.marks.strikethrough:
        marksLst.push("strikethrough");
        break;
    }
  });
  return marksLst;
}

function inlineNodeToInlineToken(node: Node): InlineToken {
  const type = node.type;
  switch (type) {
    case schema.nodes.text: {
      // based on the documentation, the text property will always be non-null
      // for a text node, but typescript doesn't distinguish between the two
      // https://prosemirror.net/docs/ref/#model.Node.text
      if (node.text == null) {
        throw new Error("found a text node with a null text property");
      }
      const isHashtag = !!node.marks.find((m) => m.type.name === "hashtag");
      const isLink = !!node.marks.find((m) => m.type.name === "link");
      if (isHashtag) {
        return {
          type: "hashtag",
          content: node.text,
        };
      }
      if (isLink) {
        return {
          type: "link",
          content: node.text,
          slug: node.text,
        };
      }
      return {
        type: "text",
        marks: getSecondaryMarks(node.marks),
        content: node.text,
      };
    }
    case schema.nodes.reference:
      return {
        type: "spaceship",
        linkedNoteId: node.attrs.linkedNoteId,
        tokenId: node.attrs.tokenId,
      };
    case schema.nodes.checkbox:
      return {
        type: "checkbox",
        isChecked: node.attrs.isChecked,
      };
    case schema.nodes.linkLoader: {
      return {
        type: "linkloader",
        url: node.attrs.url,
        title: node.attrs.title,
        description: node.attrs.description,
        image: node.attrs.image,
      };
    }
    case schema.nodes.image:
      return {
        type: "image",
        src: node.attrs.src,
        width: node.attrs.width,
        naturalWidth: node.attrs.naturalWidth,
        naturalHeight: node.attrs.naturalHeight,
        smallPreviewDataURL: node.attrs.smallPreviewDataURL,
      };
    default:
      throw new Error(
        "Don't know how to format this node type into an inline token: " +
          node.type.name,
      );
  }
}
