import { debug } from "debug";
import localforage from "localforage";
import { isClientSide, isWorker } from "./environment";

let local: Pick<typeof localforage, "setItem" | "getItem" | "removeItem">;
if (!isClientSide && !isWorker) {
  debug("localforage")("using dummy localforage");
  local = { setItem: () => {}, getItem: () => {}, removeItem: () => {} } as any;
} else {
  debug("localforage")("using real localforage");
  localforage.setDriver(localforage.INDEXEDDB);
  local = localforage;
}
export default local;
