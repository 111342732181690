import React, { useCallback, useEffect, useMemo, useState } from "react";

import { useSyncNotes } from "../model/sync/useSyncNotes";
import { useAuth } from "./useAuth";
import { sendAuth } from "./auth";

import { atom, useAtom, useSetAtom } from "jotai";
import { useUpdateEditor } from "../editorPage/atoms/editorUpdate";
import { handleSwMessage } from "../model/sync/handleSwMessage";
import { nanoid } from "nanoid";
import { useNotifySidebarUpdate } from "../sidebar/atoms/sidebarUpdate";
import debug from "debug";
import debounce from "lodash.debounce";
import { messageAtom } from "../modal/messageAtom";
import { isLoadedAtom } from "../editorPage/atoms/isLoadedAtom";
import { otherSyncStatusAtom } from "../model/sync/syncStatus/SyncStatus";

import { apiUrl, isPersistenceEnabled, urlUpload } from "../utils/environment";
import { useAtomCallback } from "jotai/utils";
import ExclusiveRunner from "../utils/exclusiveRunner";

const accessTokenAtom = atom<string | null>(null);

const extractOperationName = /(query|mutation) ?([\w\d-_]+)? ?\(.*?\)? \{/;
function gql(str: string) {
  const name = extractOperationName.exec(str);
  return function (variables: { [key: string]: any }) {
    const data: { [key: string]: any } = { query: str };
    if (variables) data.variables = variables;
    if (name && name.length) {
      const operationName = name[2];
      if (operationName) data.operationName = name[2];
    }
    return JSON.stringify(data);
  };
}

export function useAuthenticatedServerFetch() {
  const getAccessToken = useAtomCallback(accessTokenAtom.read);
  return useMemo(() => {
    return async function authenticatedServerFetch(query: string, params: any) {
      if (!urlUpload) {
        throw new Error("Server is disabled, cannot perform a server request");
      }
      const accessToken = await getAccessToken();
      if (!accessToken) {
        throw new Error(
          "Auth token is not available, cannot perform a server request",
        );
      }

      return await fetch(apiUrl! + "/v1/graphql", {
        body: gql(query)(params),
        method: "POST",
        headers: { Authorization: `Bearer ${accessToken}` },
      });
    };
  }, [getAccessToken]);
}

export type AuthenticatedServerFetch = ReturnType<
  typeof useAuthenticatedServerFetch
>;

// needs to be unique for the whole cli
const exclusiveRunner = new ExclusiveRunner();

export const AccessTokenManager = ({
  children,
}: {
  children: React.ReactElement;
}) => {
  const { getAccessTokenSilently, isAuthenticated, user } = useAuth();
  const [accessToken, setAccessToken] = useAtom(accessTokenAtom);

  const relog = useCallback(async () => {
    try {
      const accessToken = await getAccessTokenSilently();
      setAccessToken(accessToken);
    } catch {
      // noop
    }
  }, [getAccessTokenSilently]);

  // Set access token on mount
  useEffect(() => {
    relog();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Send access token to SW on change
  useEffect(() => {
    if (isAuthenticated && accessToken && user) {
      sendAuth({ type: "logged-in", accessToken, user });
    }
  }, [isAuthenticated, user, accessToken, setAccessToken]);

  const isSyncWorkerReady = usePersistenceWorker(relog);
  useSyncNotes(isSyncWorkerReady);

  return children;
};

function usePersistenceWorker(relog: () => Promise<void>) {
  // The function used to post message to the worker
  const notifyEditorUpdate = useUpdateEditor();
  const notifySidebarUpdate = useNotifySidebarUpdate();
  const setErrorMessage = useSetAtom(messageAtom);
  const [isLoaded, setIsLoaded] = useAtom(isLoadedAtom);
  const [isSWReady, setIsSWReady] = useState(false);

  const debouncedUpdate = useCallback(
    debounce(
      () => {
        notifyEditorUpdate();
        notifySidebarUpdate();
      },
      500,
      { trailing: true },
    ),
    // I had to deactivate because eslint doesnt handle a debounce function with this error
    // React Hook useCallback received a function whose dependencies are unknown. Pass an inline function instead
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [notifyEditorUpdate, notifySidebarUpdate],
  );
  // The id of the currently active and controlling service worker
  const [, setSWId] = useState(nanoid());
  const setOtherSyncStatus = useSetAtom(otherSyncStatusAtom);

  // Set up service worker
  useEffect(() => {
    if (!navigator.serviceWorker && isPersistenceEnabled)
      throw new Error("We only support browsers with service workers enabled");
    if (!navigator.serviceWorker && !isPersistenceEnabled) return;
    // The trickery below appears to be necessary to register a compiled ServiceWorker with nextjs
    // Webpack only compiles workers imported via the pattern `new Worker(new URL("script_to_be_compiled"))`
    // So we need to use that exact expression and have it register a service worker instead
    const RealWorker = window.Worker;

    (
      window as any
    ).Worker = class FakeWorkerRegisteringServiceWorkerToTrickNextjs {
      constructor(url: URL) {
        // We need to change the scope to / since the script compiled by nextjs will be placed in the _next/static/chunks directory
        // By default the service worker takes the scope of its source files and only handles requests from pages under that scope
        // To change the scope we must allow it by attaching the Service-Worker-Allowed header (see next.config.js)
        navigator.serviceWorker.register(url, { scope: "/" }).then(
          async (_registration) => {
            await navigator.serviceWorker.ready;
            debug("sync-main")("service worker is ready");
            setIsSWReady(true);
          },
          (err) => console.error(err),
        );
      }
    };
    new Worker(new URL("./../service-worker/index.ts", import.meta.url));
    window.Worker = RealWorker;

    // The ServiceWorker that was just registered still needs to "claim" this page
    // and the `navigator.serviceWorker.controller` will not be available until this happes
    // Waiting on the navigator.serviceWorker.register(...) promise or navigator.serviceWorker.ready
    // is not enough, since a service worker may be activated without claiming pages at all
    navigator.serviceWorker.addEventListener("controllerchange", (e) => {
      debug("sync-main")("change of controller");
      setSWId(nanoid());
    });
    navigator.serviceWorker.addEventListener("statechange", (e: any) => {
      console.log("STATE CHANGE ", e.target.state);
    });
    const handler = handleSwMessage(
      debouncedUpdate,
      setErrorMessage,
      () => {
        if (!isLoaded) {
          setIsLoaded(true);
          debouncedUpdate();
          debouncedUpdate.flush();
        }
      },
      relog,
      setOtherSyncStatus,
    );
    const f = (e: any) => exclusiveRunner.runExclusive(() => handler(e));
    navigator.serviceWorker.addEventListener("message", f);
    return () => navigator.serviceWorker.removeEventListener("message", f);
  }, [
    setSWId,
    debouncedUpdate,
    relog,
    setErrorMessage,
    setOtherSyncStatus,
    setIsLoaded,
    isLoaded,
  ]);

  return isSWReady;
}
