// if you edit the regexp to support a new case, please add a new test(s)
// in ./__tests__/regexpMatchers.test.ts
export const regexpMatchers = {
  spaceship:
    /(^|\s|[^\p{Alpha}\w])\+([\p{Alpha}_0-9\-—'.,@#/][\s\p{Alpha}_0-9\-—'.,@#/]*) ?$/u,
  hashtag:
    /(^|\s|[^\p{Alpha}\w])#([\p{Alpha}_0-9\x2d+*]*[\p{Alpha}_—\x2d+*][\p{Alpha}_0-9\x2d—+*]*)/gu,
  // adopted from https://gist.github.com/gruber/8891611#gistcomment-1310352
  // \x2d is unicode for -
  // \x2f is unicode for /
  // \x5d is unicode for ]
  // \p{L} is unicode for all letters
  // \p{N} is unicode for all numbers
  // \p{Emoji} is unicode for all emojis
  // High confidence links start with the http:// or https:// schemas. We allow any TLD for the high confidence links
  highConfidenceLink:
    /(https?:\/\/)([a-zA-Z0-9\x2d\p{Emoji}]+\.)+[a-zA-Z-]{2,24}[.?=&%\x2f\p{L}\p{N}\x2d_:+#@~,'"[\x5d()\p{Emoji}]*([^@\s]*)/gu,
  // Low confidence links (without the schema) are likely typed by the user directly. We only allow the few most popular TLDs in that case
  // (:[\p{N}]+)? matches the optional port number (should be very rare)
  // [?\x2f#] matches either the "?", "/" or "#" that separates the TLD from the rest of the URL
  // Thus the strings like "google.co.uk/something" or "google.co.uk?something" will be treated as links
  // Top 50 most frequent TLDs are taken from https://w3techs.com/technologies/overview/top_level_domain
  // .app is also added to make sure ideapad.app is matched
  lowConfidenceLink:
    /(^|\s)([a-zA-Z0-9\x2d\p{Emoji}]+\.)+(com|ru|org|net|ir|in|au|uk|ua|de|ca|tr|vn|jp|co|fr|gr|info|id|io|br|it|il|tw|za|pl|nl|mx|eu|ch|cn|by|es|kr|nz|рф|xyz|us|ro|me|ar|cl|online|cz|my|pk|sg|th|se|biz|app)(:[\p{N}]+)?([?\x2f#][.?=&%\x2f\p{L}\p{N}\x2d_:+#@~,'"[\x5d()\p{Emoji}]*([^@\s]*))?/gu,
};
