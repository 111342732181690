import { TextSelection, Transaction } from "prosemirror-state";
import {
  ByHashtagQuery,
  ByPlainTextQuery,
  SearchQuery,
} from "../../../search/SearchQuery";
import {
  getMatchesForEachRegex,
  getTextRunsForNode,
  TextRun,
} from "../../utils/mark/regexMarkUtils";
import { schema } from "../../schema";
import { createSearchMatchRegex, removeDiacritics } from "../../../search/find";

/**
 * For each node,
 * remove all search match marks
 */
export function condenseAndHighlight(
  tr: Transaction,
  searchQuery: SearchQuery,
) {
  // Regex based highlighting of text runs
  if (["text", "hashtag"].includes(searchQuery.type)) {
    const regex = createSearchMatchRegex(
      (searchQuery as ByHashtagQuery | ByPlainTextQuery).q,
    );
    let { anchor: prevAnchor, head: prevHead } = tr.selection;
    tr.doc.forEach((node, pos) => {
      const textRuns = getTextRunsForNode(node, pos);
      textRuns.forEach((textRun) =>
        applySearchMatchMarksToTextRuns(tr, textRun, regex),
      );
    });
    // In some cases, the selection collapses when we remove marks.
    // So we save the selection and restore it after we're done.
    // See:
    // - https://linear.app/ideaflow/issue/ENT-1426
    // - https://linear.app/ideaflow/issue/ENT-1817
    prevAnchor = tr.mapping.map(prevAnchor);
    prevHead = tr.mapping.map(prevHead);
    const { anchor, head } = tr.selection;
    if (anchor !== prevAnchor || head !== prevHead) {
      tr.setSelection(
        new TextSelection(tr.doc.resolve(prevAnchor), tr.doc.resolve(prevHead)),
      );
    }
  }
}

function applySearchMatchMarksToTextRuns(
  transaction: Transaction,
  textRun: TextRun,
  regex: RegExp,
) {
  const startPos = textRun.startPos;
  const text = textRun.textNodes
    .map((n) => n.text!)
    .map(removeDiacritics)
    .join("");

  const matches = getMatchesForEachRegex(
    text,
    [{ type: "highlight" as const, regex, skipFirstGroup: false }],
    false,
  );

  // Remove all marks
  transaction.removeMark(
    startPos,
    startPos + text.length,
    schema.marks.highlight,
  );

  matches.forEach(({ start, end }) => {
    if (
      !transaction.doc.rangeHasMark(
        startPos + start,
        startPos + end,
        schema.marks.autocompleteRegion,
      )
    ) {
      transaction.addMark(
        startPos + start,
        startPos + end,
        schema.marks.highlight.create(),
      );
    }
  });
}
