import { Plugin, PluginKey } from "prosemirror-state";

/**
 * fixDragDropPlugin addresses a Thoughtstream/ProseMirror interop issue that
 * causes editor state corruption in the drag-and-drop resolution algorithm:
 *
 * == PROBLEM ==
 * When an entity / InlineToken (like @mention) is dragged and dropped *inside
 * the editor* but *outside of the editable area (i.e. where note text is)*,
 * for some reason, ProseMirror tries to insert the moved entity at depth -1 in
 * the editor doc, which is an invalid depth in the PM document model.
 *
 * == SOLUTION ==
 * We fix this by intercepting a `drop` event in the editor, and if it's in the
 * problematic area (in the padding of editor but not actually in the editor),
 * we do nothing.
 *
 * See also: https://linear.app/ideaflow/issue/ENT-470/typeerror-cannot-read-property-nodesize-of-undefined
 */
export const fixDragDropPlugin = new Plugin({
  key: new PluginKey("fixDragDrop"),
  props: {
    handleDOMEvents: {
      drop: (view, e) => {
        const editorOutsideRect = view.dom.getBoundingClientRect();
        const { top, left, right, bottom } = editorOutsideRect;
        const { paddingLeft, paddingRight, paddingTop, paddingBottom } =
          getComputedStyle(view.dom);

        const editorInsideRect = {
          top: top + parseInt(paddingTop),
          left: left + parseInt(paddingLeft),
          right: right - parseInt(paddingRight),
          bottom: bottom - parseInt(paddingBottom),
        };

        const isInsideEditorRect = (x: number, y: number) => {
          const { top, left, right, bottom } = editorInsideRect;
          return x > left && x < right && y > top && y < bottom;
        };

        const didDropInProblematicArea = !isInsideEditorRect(
          (e as any).pageX,
          (e as any).pageY,
        );
        if (didDropInProblematicArea) {
          // handle it ourselves by doing nothing
          e.preventDefault();
          return true;
        }

        // let ProseMirror handle it
        return false;
      },
    },
  },
});
