import { Fragment, MarkType, Node } from "prosemirror-model";

function mapFragment(
  fragment: Fragment,
  mapper: (node: Node) => Node,
): Fragment {
  const nodeArray: Node[] = [];
  fragment.forEach((node) => {
    nodeArray.push(mapNode(node, mapper));
  });
  return Fragment.fromArray(nodeArray);
}

function mapNode(node: Node, mapper: (node: Node) => Node) {
  // Map the nodes' children
  const nodeWithMappedChildren = node.copy(mapFragment(node.content, mapper));
  // Map the node itself
  return mapper(nodeWithMappedChildren);
}

export function removeMark(fragment: Fragment, mark: MarkType) {
  return mapFragment(fragment, (node) => {
    return node.mark(node.marks.filter((m) => m.type !== mark));
  });
}
