import { isDev } from "../utils/environment";

// In development throw an error if a main-thread object is used outside of the main thread
if (
  isDev &&
  // eslint-disable-next-line no-eval
  "undefined" !== eval("typeof ServiceWorkerGlobalScope")
) {
  throw new Error("Main thread objects must not be used on the service worker");
}

export {};
