import { schema } from "../../schema";
import { Node, Fragment } from "prosemirror-model";

function isFragment(node: Node | Fragment): node is Fragment {
  return node instanceof Fragment;
}

export function removeEllipsis<T extends Node | Fragment>(node: T): T {
  if (isFragment(node)) {
    const content: Node[] = [];
    node.forEach((child) => {
      content.push(removeEllipsis(child));
    });
    return Fragment.fromArray(content) as T;
  } else {
    if (node.isText) return node;
    const content: Node[] = [];
    node.content.forEach((child) => {
      if (child.type === schema.nodes.ellipsisContainer) {
        const ellipsis = child.lastChild;
        if (ellipsis?.type !== schema.nodes.ellipsis) {
          throw new Error("Invalid ellipsis container");
        }
        ellipsis.content.forEach((n) => content.push(n));
      } else {
        content.push(child);
      }
    });
    return node.type.create(
      node.attrs,
      Fragment.fromArray(content.map((n) => removeEllipsis(n))),
    ) as T;
  }
}
