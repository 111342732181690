import { Node, ResolvedPos } from "prosemirror-model";
import {
  EditorState,
  NodeSelection,
  Transaction,
  Command,
} from "prosemirror-state";
import { noteList, expansionSet } from "../../../model/services";
import { noteToProsemirrorNode } from "../../bridge";
import { schema } from "../../schema";
import { getPathToReference } from "../../utils/path";
import { trackEvent } from "../../../analytics/analyticsHandlers";
import { findParent, getParentNote } from "../../utils/find";
import { generateId } from "../../../model/generateId";
import { NoteId } from "../../../model/types";
import { noteToBacklinkProsemirrorNode } from "../backlink/backlinkPlugin";
import getNode from "../../utils/getNode";
import debug from "debug";

/**
 * Command that takes a position (or optionally falls back to the current selection's spaceship) and toggles the spaceship expansion.
 */
export const toggleSpaceship = (
  state: EditorState,
  dispatch: any,
  pos?: number,
) => {
  if (!dispatch) return false;

  if (pos === null && state.selection.$from.pos === state.selection.$to.pos)
    return false;

  const resolvedPos =
    pos != null ? state.doc.resolve(pos) : state.selection.$from;
  const spaceship = state.doc.nodeAt(resolvedPos.pos);
  if (!spaceship || spaceship.type !== schema.nodes.reference) return false;

  // if the spaceship references a parent note, don't expand because it's a
  // circular reference
  const [node] = findParent(
    state.doc,
    resolvedPos.pos,
    (n) => n.attrs.noteId === spaceship.attrs.linkedNoteId,
  );
  if (node) {
    return false;
  }

  const tr = state.tr;
  expandOrCollapseSpaceship(tr, resolvedPos);

  // The selection should be set AFTER setNodeMarkup in
  // expandOrCollapseSpaceship. otherwise the markup destroys the previous
  // selection.
  tr.setSelection(
    NodeSelection.create(tr.doc, tr.mapping.map(resolvedPos.pos)),
  );

  tr.setMeta("type", "toggleSpaceship");
  tr.setMeta("noChangeToModel", true);
  trackEvent(["RELATIONS", "TOGGLED"]);
  dispatch(tr);
  return true;
};

/**
 * Insert an expanded reference at the given position.
 *
 * @param tr Transaction
 * @param pos Position to insert at
 * @param note Note which the reference is pointing to
 * @param context Whether the reference is in a note or backlinks section, and
 *  if in a note, the associated reference's token id
 * @returns
 */
export const insertExpandedReference = (
  tr: Transaction,
  pos: number,
  noteId: NoteId,
  context:
    | { parent: "note"; referenceTokenId: string }
    | { parent: "backlinks" },
) => {
  // Get path to parent note
  const [parentNoteNode] = findParent(
    tr.doc,
    pos,
    (n) => n.type === schema.nodes.note,
  );
  const parentPath = parentNoteNode?.attrs.path;
  if (!parentPath) {
    throw new Error(`Expansion parent missing path`);
  }

  const note = noteList.get(noteId);
  if (!note) throw new Error(`Referenced note not found: ${noteId}`);

  let noteNode: Node;
  if (context.parent === "backlinks") {
    noteNode = noteToBacklinkProsemirrorNode(note, parentNoteNode);
  } else {
    const dir = getPathToReference(parentPath, context.referenceTokenId);
    noteNode = noteToProsemirrorNode(note, dir);
  }
  const expansionNode = schema.nodes.expandedReference.create(
    {
      tokenId: generateId(),
      referenceTokenId:
        context.parent === "note" ? context.referenceTokenId : null,
      containedNoteId: noteId,
      isBacklink: context.parent === "backlinks",
      circular: parentNoteNode.attrs.path.split("/").includes(noteId),
    },
    noteNode,
  );
  tr.insert(pos, expansionNode);

  return tr;
};

/**
 * Adds necessary steps to a PM transaction in order to expand a spaceship at the given resolved position.
 *
 * @param tr The transaction to add steps to
 * @param resolvedPos The resolved position of the spaceship to expand
 * @param node The node to insert into the expansion. if not provide, the
 *    spaceship's linked note attribute will be used to find the node.
 * @returns Whether the expansion was successful
 */
export const expandOrCollapseSpaceship = (
  tr: Transaction,
  resolvedPos: ResolvedPos,
  node?: Node,
): boolean => {
  // Get spaceship and path to spaceship
  const spaceship = tr.doc.nodeAt(resolvedPos.pos);
  if (!spaceship || spaceship.type !== schema.nodes.reference) {
    throw new Error("no spaceship found at given position");
  }
  const { attrs } = spaceship;
  const [parentNoteNode] = getParentNote(tr.doc, resolvedPos.pos);
  if (!parentNoteNode) throw new Error("no parent note found for spaceship");
  const pathSpaceship = getPathToReference(
    parentNoteNode.attrs.path,
    attrs.tokenId,
  );

  if (!attrs.isExpanded) {
    if (parentNoteNode.attrs.path.includes(spaceship.attrs.linkedNoteId)) {
      debug("editor")("Prevented circular reference expansion");
      return false;
    }
    const posExpandedRef = getLinkedNotePosFromSpaceshipPos(
      tr.doc,
      resolvedPos.pos,
      "start",
    );
    insertExpandedReference(tr, posExpandedRef, node || attrs.linkedNoteId, {
      parent: "note",
      referenceTokenId: attrs.tokenId,
    });
    expansionSet.add(pathSpaceship);
  } else {
    // collapse
    const startPos = getLinkedNotePosFromSpaceshipPos(
      tr.doc,
      resolvedPos.pos,
      "start",
    );
    const endPos = getLinkedNotePosFromSpaceshipPos(
      tr.doc,
      resolvedPos.pos,
      "end",
    );
    tr.delete(startPos, endPos);
    expansionSet.delete(pathSpaceship);
  }
  // Toggle spaceship isExpanded state
  tr.setNodeMarkup(resolvedPos.pos, undefined, {
    ...attrs,
    isExpanded: !attrs.isExpanded,
  });

  return true;
};

/**
 * Maps an expanded note pos in doc to the pos of its corresponding spaceship node.
 * If the given pos does not point to an expanded note, it throws.
 */
export const getSpaceshipPosFromExpandedNotePos = (
  doc: Node,
  pos: number,
): number => {
  const resolvedPos = doc.resolve(pos);
  const expandedNote = doc.nodeAt(pos);
  if (!expandedNote || expandedNote.type !== schema.nodes.expandedReference) {
    throw new Error("Could not find an expandedNote at given position");
  }

  // First find paragraph that comes right before current set of expandedNotes.
  const expandedNoteIndex = resolvedPos.index(resolvedPos.depth);
  const parentNode = resolvedPos.parent;
  let paragraphIndex = -1;
  for (let i = expandedNoteIndex - 1; i >= 0; i--) {
    const child = parentNode.child(i);
    if (child.type === schema.nodes.paragraph) {
      paragraphIndex = i;
      break;
    }
  }
  if (paragraphIndex === -1) {
    throw new Error(
      "Invalid state: could not find a paragraph before an expandedNote",
    );
  }

  const expandedSpaceshipCount = expandedNoteIndex - paragraphIndex;

  const paragraph = parentNode.child(paragraphIndex);
  const paragraphPos = resolvedPos.posAtIndex(paragraphIndex);

  /**
   * Iterate through expanded spaceships in that paragraph, finding the one that
   * corresponds to the currently expanded expandedNote.
   */
  let expandedSpaceshipsSoFar = 0;
  let spaceshipPos = -1;
  let breakLoop = false;
  paragraph.forEach((inlineNode, offset) => {
    if (breakLoop) return;

    if (
      inlineNode.type === schema.nodes.reference &&
      inlineNode.attrs.isExpanded
    ) {
      expandedSpaceshipsSoFar++;
    }

    if (expandedSpaceshipsSoFar === expandedSpaceshipCount) {
      spaceshipPos = paragraphPos + offset + 1; // +1 for start of paragraph node
      breakLoop = true;
    }
  });

  if (spaceshipPos === -1) {
    throw new Error(
      "invalid state: could not find a spaceship matching an expandedNote",
    );
  }

  return spaceshipPos;
};

/**
 * Maps a spaceship pos in doc to the pos of its corresponding expanded note.
 *
 * If the spaceship is not yet expanded, it returns the position at which the
 * expanded note should be added.  Pass side = 'end' to get pos at end of the
 * expanded note rather than start.
 *
 * If the given pos does not point to a spaceship, it throws.
 */
export const getLinkedNotePosFromSpaceshipPos = (
  doc: Node,
  pos: number,
  side: "start" | "end" = "start",
): number => {
  const resolvedPos = doc.resolve(pos);
  const spaceship = doc.nodeAt(pos);
  if (!spaceship || spaceship.type !== schema.nodes.reference) {
    throw new Error("Could not find a spaceship at given position");
  }

  // First find the pos at which we need to insert the expanded note, by
  // checking if the paragraph already has other spaceships expanded.
  const paragraph = resolvedPos.parent;
  let expandedSpaceshipOffset = 0;
  let breakLoop = false;
  paragraph.forEach((inlineNode) => {
    if (breakLoop) return;

    if (inlineNode.eq(spaceship)) {
      breakLoop = true;
      return;
    }

    if (
      inlineNode.type === schema.nodes.reference &&
      inlineNode.attrs.isExpanded
    ) {
      expandedSpaceshipOffset++;
    }
  });

  // We insert expanded note nodes after the paragraph, then after other
  // expanded notes from that paragraph, keeping order of spaceships and their
  // expanded notes in sync.
  return resolvedPos.posAtIndex(
    resolvedPos.indexAfter(-1) +
      expandedSpaceshipOffset +
      (side === "start" ? 0 : 1), // +1 for paragraph
    -1,
  );
};

/**
 * This function wraps a transaction-modifying block of code so that the wrapped
 * block doesn't have to worry about expanded spaceships in the paragraphs it is
 * splicing. It first collapses all expanded spaceships in the paragraph
 * containing the selection and re-expands them after updating the transactions.
 * This means the wrapped block of code can work as if no spaceships are ever
 * expanded. Most of the time, this produces reasonable expected behavior on
 * edits. Sometimes this does not, e.g. backspacing into a previous paragraph.
 *
 */
export const preserveExpandedSpaceshipsAround = (
  state: EditorState,
  tr: Transaction,
  performTrs: () => void,
): void => {
  // const { $from } = state.selection;
  const { $from } = tr.selection;

  // If any spaceships are expanded in the current paragraph being split:
  // 1. Collapse all those spaceships, and remember their positions
  // 2. Split the paragraph
  // 3. Best-effort: re-expand any of those spaceships given the new positions
  const paragraph = $from.parent;
  const paragraphPos = $from.posAtIndex(
    $from.index($from.depth - 1),
    $from.depth - 1,
  );
  const spaceshipPositions: number[] = [];
  paragraph.forEach((inlineNode, offset) => {
    if (
      inlineNode.type === schema.nodes.reference &&
      inlineNode.attrs.isExpanded
    ) {
      spaceshipPositions.push(paragraphPos + offset + 1); // +1 for paragraph boundary
    }
  });

  for (const pos of spaceshipPositions) {
    const resolvedPos = tr.doc.resolve(tr.mapping.map(pos));
    expandOrCollapseSpaceship(tr, resolvedPos);
  }
  performTrs();

  for (const pos of spaceshipPositions) {
    const resolvedPos = tr.doc.resolve(tr.mapping.map(pos));
    if (
      tr.doc.nodeAt(tr.mapping.map(pos))?.type === state.schema.nodes.reference
    ) {
      expandOrCollapseSpaceship(tr, resolvedPos);
    }
  }
};

export const toggleIsExpandedAttributeOnSpaceship = (
  pos: number,
  spaceship: Node,
): Command => {
  return (state, dispatch) => {
    if (!dispatch) return false;
    const tr = state.tr;
    tr.setNodeMarkup(pos, undefined, {
      ...spaceship.attrs,
      isExpanded: false,
    });
    dispatch(tr);
    return true;
  };
};

/**
 * Returns true if the given reference is circular.
 * @param rpos resolved position of a reference node
 * @throws if the given position is not a reference node
 */
export function isCircularReference(rpos: ResolvedPos): boolean {
  const { pos, doc } = rpos;
  const reference = getNode(doc, pos, schema.nodes.reference);
  const [node] = findParent(
    doc,
    pos,
    (node) =>
      node.type === schema.nodes.note &&
      node.attrs.noteId === reference.attrs.linkedNoteId,
  );
  return node !== null;
}
