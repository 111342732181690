async function wait(ms: number) {
  await new Promise((res) => setTimeout(res, ms));
}

export default class ExclusiveRunner {
  // This promise resolves once the previous message received by the ServiceWorker has been fully handled
  // We don't want the ServiceWorker to ever interleave operations from two different messages
  private previousMessageFinishPromise = Promise.resolve();

  private iteration = Math.random();

  async runExclusive(callback: () => Promise<void>) {
    const it = this.iteration;
    const nextTaskPromise = this.previousMessageFinishPromise.then(() => {
      if (it !== this.iteration) return;
      return Promise.race([callback(), wait(5_000)]);
    });

    // Ignore the error to make sure the exclusive runner does not stop processing next tasks when one promise rejects
    this.previousMessageFinishPromise = nextTaskPromise.catch((err) => {
      console.error(err);
    });

    return nextTaskPromise;
  }

  flush() {
    this.iteration = Math.random();
  }
}
