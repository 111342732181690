import localforage from "../utils/localforage";
import { User } from "@auth0/auth0-react";
import { sendMessageToSWWhenReady } from "../model/sync/handleSwMessage";

export type Auth = AuthLoggedIn | AuthLoggedOut;

export type AuthLoggedIn = {
  type: "logged-in";
  accessToken: string;
  user: User;
};

export type AuthLoggedOut = {
  type: "logged-out";
};

export async function sendAuth(auth: Auth) {
  await localforage.setItem("auth-state", auth);
  await sendMessageToSWWhenReady({ type: "reload-auth" });
}
